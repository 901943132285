import { useStaticQuery, graphql } from 'gatsby';

import { useKisSectionData } from './helper';

export const useKisMenuEntries = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisMenu {
        nodes {
          entry {
            name
            image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            dishes {
              data {
                name
                prices {
                  price
                  title
                }
                description {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);
  return useKisSectionData(data);
};
