export const navigationShrinkAnimation = () => {
  const onScrollAnimation = () => {
    const navigationBar = document.querySelector('.navigation-bar');
    const navigationLine = document.querySelector('.navigation-line');
    const navigationLogo = document.querySelector('.navigation-logo');

    const defaultTransition = 'all 0.5s ease';
    navigationBar.style.transition = defaultTransition;
    navigationLine.style.transition = defaultTransition;
    navigationLogo.style.transition = defaultTransition;
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      // Shrinking start from tablet landscape
      if (window !== undefined && window.innerWidth > 1000) {
        navigationBar.style.paddingTop = '1rem';
        navigationBar.style.paddingBottom = '1rem';
        navigationLogo.style.width = '83%';
      }
      navigationLine.style.opacity = 1;
    } else {
      navigationBar.style.paddingTop = null;
      navigationBar.style.paddingBottom = null;
      navigationLine.style.opacity = null;
      navigationLogo.style.width = null;
    }
  };
  if (window) {
    window.addEventListener('scroll', onScrollAnimation);
    return () => {
      window.removeEventListener('scroll', onScrollAnimation);
    };
  }
};

export const pageTransitionVariant = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
      delay: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  }
};
