import React from 'react';
import { navigate } from 'gatsby';

// External Components
import {
  GridWrapper,
  Overlay,
  Box,
  Heading,
  Paragraph,
  fullWidthMinusMargins,
  FlexWrapper,
  Button
} from '@thepuzzlers/pieces';
import { useShippingFee, useShoppingCart } from 'gatsby-theme-thepuzzlers-cart';

// Local Components
import {
  WideCloseButton,
  HorizontalLine,
  PlusButton,
  MinusButton,
  SecondaryButton,
  GridSpacer
} from 'components';

// Data
import { useCartData } from '../useCartData';

// Animation
import { actionBarAnimation } from 'sections/shopPage/productCategory/components/animation';
import { slideLeftAnimation } from './animations';

// Utils
import { useFormatPrice } from 'hooks';

export const CartOverlay = ({ handleClose }) => {
  return (
    // Markup
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress
      containerSx={{
        bg: 'rgba(0,0,0, 0.5)',
        overflow: 'hidden'
      }}>
      <OverlayContents handleClose={handleClose} />
    </Overlay>
  );
};

// Elements
const OverlayContents = ({ handleClose }) => {
  const {
    overlay: { headlines, labels }
  } = useCartData();

  return (
    // AdditionalBox for animation purposes
    <Box
      variants={slideLeftAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        height: '100%'
      }}>
      <GridWrapper
        className="cart-overlay"
        sx={{
          height: '100%',
          bg: [
            'background',
            'background',
            'background',
            'background',
            'background',
            'transparent'
          ],
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'absolute',
          height: '100%',
          bottom: 0
        }}>
        {/* The contents */}
        <Box
          sx={{
            gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '7/25'],
            bg: [null, null, null, null, null, 'background'],
            px: [null, null, null, null, null, '5.4rem'],
            mr: [null, null, null, null, null, fullWidthMinusMargins[5]]
          }}>
          <Navbar handleClose={handleClose} />
          <ProductsListWrapper
            headlines={headlines}
            labels={labels}
            handleClose={handleClose}
          />
          <BottomSpacer />
        </Box>
      </GridWrapper>
      <ActionBar labels={labels} handleClose={handleClose} />
    </Box>
  );
};

const Navbar = ({ handleClose }) => (
  <FlexWrapper
    className="cart-overlay__navbar"
    sx={{
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      py: ['1.2rem', '2rem', '2.4rem', '1.2rem', '1.6rem', '3.2rem']
    }}>
    <WideCloseButton
      onClick={handleClose}
      sx={{
        width: ['7.6rem', '8.9rem', '10.6rem', '7.6rem', '9rem', '9rem']
      }}
    />
  </FlexWrapper>
);

const ProductsListWrapper = ({ headlines, labels, handleClose }) => {
  const { totalItems, pickupItems, shippingItems } = useShoppingCart();

  React.useEffect(() => {
    // Close the overlay if total items in the cart is 0
    if (totalItems === 0) {
      handleClose();
    }
  }, [totalItems]);

  return (
    <Box
      className="cart-overlay__products-list-wrapper"
      sx={{
        '& > div:first-of-type': {
          mt: ['2.4rem', '2.4rem', '4rem', '1.2rem', '2.4rem', '1.2rem']
        },
        '& > div:nth-of-type(n+2)': {
          mt: ['8rem', '10rem', '12rem', '8rem', '10rem', '10rem']
        }
      }}>
      {pickupItems.length !== 0 && (
        <ProductList
          title={headlines.pickup}
          items={pickupItems}
          labels={labels}
        />
      )}
      {shippingItems.length !== 0 && (
        <ProductList
          title={headlines.delivery}
          items={shippingItems}
          labels={labels}
        />
      )}
    </Box>
  );
};

const ProductList = ({ title, items, labels }) => {
  const { incrementItem, decrementItem, removeItem } = useShoppingCart();

  return (
    <Box className="products-list-wrapper__product-list">
      <Headline title={title} />
      <Box
        sx={{
          mt: ['3.2rem', '4.8rem', '4rem', '3.2rem', '4.1rem', '2.4rem']
        }}>
        {items.map((product, index) => (
          <React.Fragment key={product.id}>
            {index !== 0 && <ProductDivider />}
            <ProductCard
              cardItem={product}
              labels={labels}
              handleDecrement={() => decrementItem(product)}
              handleIncrement={() => incrementItem(product)}
              handleRemove={() => removeItem(product)}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

const ProductCard = ({
  cardItem,
  labels,
  handleRemove,
  handleDecrement,
  handleIncrement
}) => {
  const formatPrice = useFormatPrice();
  function constructName(cardItem) {
    let name = cardItem.name;

    if (cardItem.variant) {
      name = name + ', ' + cardItem.variant;
    }
    if (cardItem.size) {
      name = name + ', ' + cardItem.size;
    }
    return name;
  }

  return (
    <Box
      className="products-list__product-card"
      variant="inside.autoColumns"
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          '1fr 8.1rem',
          '1fr 9rem',
          '1fr 15rem',
          '1fr 7.9rem 13.6rem 7.9rem',
          '1fr 11.8rem 20.2rem 11.8rem',
          '1fr 11.8rem 20rem 9.2rem'
        ],
        rowGap: ['1.2rem', '1.6rem', '2rem', 0, 0, 0],
        columnGap: [null, null, null, '0.8rem', '0.8rem', '1.6rem']
      }}>
      <CardName name={constructName(cardItem)} />
      <RemoveButton text={labels.remove} handleRemove={handleRemove} />
      <QuantitySelectors
        quantity={
          cardItem.quantity >= 10 ? cardItem.quantity : '0' + cardItem.quantity
        }
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
      />
      <Price price={formatPrice(cardItem.amount)} />
    </Box>
  );
};

const CardName = ({ name }) => (
  <Heading
    as="h3"
    sx={{
      alignSelf: ['start', 'start', 'start', 'center', 'center', 'center'],
      fontFamily: 'primary.bold',
      fontSize: ['1.8rem', '2.2rem', '2.4rem', '1.8rem', '2.4rem', '2.2rem'],
      gridRow: 1,
      lineHeight: 1.25
    }}>
    {name}
  </Heading>
);

const RemoveButton = ({ text, handleRemove }) => (
  <Button
    variant="clear"
    onClick={handleRemove}
    sx={{
      alignSelf: ['start', 'center', 'center', 'center', 'center', 'center'],
      color: 'grey',
      fontFamily: 'body.normal',
      fontSize: ['1.4rem', '1.5rem', '1.6rem', '1.4rem', '1.5rem', '1.5rem'],
      gridRow: 1,
      justifySelf: 'end',
      lineHeight: 1.25,
      mt: ['0.6rem', 0, 0, 0, 0, 0],
      textTransform: 'capitalize',
      color: 'red',
      gridColumn: [null, null, null, 4, 4, 4]
    }}>
    {text}
  </Button>
);

const Price = ({ price }) => (
  <Paragraph
    sx={{
      alignSelf: 'center',
      fontFamily: 'body.medium',
      fontSize: ['1.6rem', '1.6rem', '1.7rem', '1.5rem', '1.8rem', '1.8rem'],
      justifySelf: 'end',
      lineHeight: 1.25,
      gridColumn: [null, null, null, 2, 2, 2]
    }}>
    {price}
  </Paragraph>
);

const QuantitySelectors = ({ quantity, handleDecrement, handleIncrement }) => {
  const lineStyles = {
    height: '0.1rem',
    width: ['1.6rem', '1.6rem', '1.8rem', '1.2rem', '1.8rem', '1.6rem']
  };

  const btnStyles = {
    aspectRatio: '1/1',
    p: 0,
    width: ['3.2rem', '3.2rem', '3.6rem', '2.4rem', '3.6rem', '3.2rem']
  };

  return (
    <FlexWrapper
      sx={{
        borderColor: 'primary',
        borderStyle: 'solid',
        borderWidth: '0.1rem',
        gridRow: [2, 2, 2, 1, 1, 1],
        width: ['12rem', '12rem', '15rem', '10.8rem', '16rem', '14.6rem'],
        gridColumn: [null, null, null, 3, 3, 3],
        justifySelf: [null, null, null, 'end', 'end', 'end'],
        alignSelf: 'center'
      }}>
      <MinusButton
        sx={btnStyles}
        lineSx={lineStyles}
        onClick={handleDecrement}
      />
      <QuantityNumber quantity={quantity} />
      <PlusButton
        sx={btnStyles}
        lineSx={lineStyles}
        onClick={handleIncrement}
      />
    </FlexWrapper>
  );
};

const QuantityNumber = ({ quantity }) => (
  <Paragraph
    sx={{
      alignItems: 'center',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderWidth: '0 0.1rem 0 0.1rem',
      display: 'flex',
      fontFamily: 'primary.bold',
      fontSize: ['1.8rem', '2rem', '2.2rem', '1.8rem', '2.2rem', '2.2rem'],
      flex: 1,
      justifyContent: 'center',
      lineHeight: 1.25,
      textAlign: 'center'
    }}>
    {quantity}
  </Paragraph>
);

const ProductDivider = () => (
  <HorizontalLine
    sx={{ my: ['3.2rem', '4rem', '4rem', '3.2rem', '3.2rem', '3.2rem'] }}
  />
);

const ActionBar = ({ labels, price, handleClose }) => {
  const { data, loading } = useShippingFee();
  const { shippingItems } = useShoppingCart();
  const shippingFee = !loading ? data.business.settings.shippingFee : undefined;
  return (
    <FlexWrapper
      // Animation value
      variants={actionBarAnimation}
      sx={{
        bg: 'background',
        right: [
          null,
          null,
          null,
          'calc(28px + ((100vw - (700px + (100vw - 700px) / 2.5)) / 2))',
          0,
          0
        ],
        left: [
          null,
          null,
          null,
          'calc(28px + ((100vw - (700px + (100vw - 700px) / 2.5)) / 2))',
          'unset',
          'unset'
        ],
        bottom: 0,
        borderColor: 'primary',
        borderStyle: 'solid',
        borderWidth: [0, 0, 0, '0 0.1rem', '0 0 0 0.1rem', '0 0 0 0.1rem'],
        flexDirection: [
          'column',
          'column',
          'column',
          'row',
          'column',
          'column'
        ],
        flexWrap: 'wrap',
        position: 'fixed',
        width: [null, null, null, 'auto', '46.7rem', '55rem']
      }}>
      <HorizontalLine
        sx={{
          width: ['100vw', '100vw', '100vw', '100%', '100%', '100%']
        }}
      />
      {shippingItems.length !== 0 && (
        <ShippingFeeWrapper
          label={labels.shippingFee}
          shippingFee={shippingFee}
        />
      )}
      <SubtotalWrapper label={labels.total} shippingFee={shippingFee} />
      <CheckoutButton text={labels.add.regular} handleClose={handleClose} />
    </FlexWrapper>
  );
};

const SubtotalWrapper = ({ label, shippingFee }) => {
  const { totalAmount } = useShoppingCart();

  return (
    <CheckoutSummaryWrapper label={label} price={totalAmount + shippingFee} />
  );
};
const ShippingFeeWrapper = ({ label, shippingFee }) => {
  return <CheckoutSummaryWrapper label={label} price={shippingFee} />;
};

const CheckoutSummaryWrapper = ({ label, price }) => {
  const formatPrice = useFormatPrice();

  return (
    <FlexWrapper
      className="cart-overlay__subtotal-wrapper"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        px: [
          'calc(11.5px + (100vw - (320px + (100vw - 320px) / 2.5)) / 2)',
          'calc(20px + (100vw - (500px + (100vw - 500px) / 2.5)) / 2)',
          'calc(38px + (100vw - (750px + (100vw - 750px) / 2.5)) / 2)',
          '2.9rem',
          '4rem',
          '4rem'
        ],
        py: ['1.8rem', '2.2rem', '3.3rem', '1.1rem', '2rem', '1.6rem'],
        width: [null, null, null, '50%', '46.7rem', '55rem']
      }}>
      <SubTotalText>{label}</SubTotalText>
      <SubTotalText>{price ? formatPrice(price) : '...'}</SubTotalText>
    </FlexWrapper>
  );
};

const CheckoutButton = ({ text, handleClose }) => {
  return (
    <SecondaryButton
      onClick={() => {
        navigate('/checkout');
        handleClose();
      }}
      label={text}
      sx={{
        position: 'relative',
        py: ['2.4rem', '2.8rem', '4rem', '1.6rem', '2.4rem', '2.4rem'],
        px: [
          'calc(11.5px + (100vw - (320px + (100vw - 320px) / 2.5)) / 2)',
          'calc(20px + (100vw - (500px + (100vw - 500px) / 2.5)) / 2)',
          'calc(38px + (100vw - (750px + (100vw - 750px) / 2.5)) / 2)',
          '2.9rem',
          '4rem',
          '4rem'
        ],
        width: ['100%', '100%', '100%', '100%', '46.7rem', '55rem']
      }}
    />
  );
};

const SubTotalText = ({ children }) => (
  <Heading
    as="h3"
    className="subtotal-text"
    sx={{
      fontFamily: 'primary.bold',
      fontSize: ['2rem', '2.2rem', '2.4rem', '1.8rem', '2rem', '2rem'],
      lineHeight: 1.25
    }}>
    {children}
  </Heading>
);

const Headline = ({ title }) => (
  <Heading
    as="h2"
    sx={{
      fontFamily: 'primary.bold',
      fontSize: ['2.4rem', '3rem', '3.2rem', '2.2rem', '3rem', '3.2rem']
    }}>
    {title}
  </Heading>
);

const BottomSpacer = () => (
  <GridSpacer height={['16rem', '24rem', '26rem', '9rem', '20rem', '20rem']} />
);
