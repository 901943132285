const tagTranslations = {
  'eu-organic': 'Bio',
  vegan: 'Vegan',
  vegetarian: 'Vegetarisch'
};

export const getTags = (tags) => {
  return tags?.length > 0
    ? tags.reduce((acc, cur, index) => {
        return (
          acc +
          `${index === 0 ? '/' : ' /'} ${tagTranslations[cur.name] || cur.name}`
        );
      }, '')
    : '';
};
