/**
 * This Calculation is based from this discussion https://stackoverflow.com/questions/68886003/css-aspect-ratio-not-working-in-safari-browser
 */

// Helper Function
export const getAspectRatio = (aspectRatio) =>
  aspectRatio ? `${(1 / aspectRatio) * 100}%` : null;
export const getPaddingPercentageForAspectRatio = (aspectRatio) => {
  if (Array.isArray(aspectRatio))
    return aspectRatio.map((value) => getAspectRatio(value));
  return getAspectRatio(aspectRatio);
};
