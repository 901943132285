import React from 'react';

// External Components
import { Box, Heading, Button } from '@thepuzzlers/pieces';

// Local Compoents
import { Line, CardButton, SmallCloseIcon } from 'components';

// Animation
import { toastContainer } from './animation';

// Data

export const Toast = ({
  handleCloseToast,
  text,
  textButton,
  onButtonClick,
  closeDuration
}) => {
  const handleCardClick = () => {
    onButtonClick();
    handleCloseToast();
  };

  const handleCloseButtonClick = (e) => {
    e.stopPropagation();
    handleCloseToast();
  };

  React.useEffect(() => {
    if (closeDuration) {
      const closeTimeOut = setTimeout(() => handleCloseToast(), closeDuration);
      return () => {
        clearTimeout(closeTimeOut);
      };
    }
  }, [closeDuration, handleCloseToast]);

  // Add Close button if toast has not duration
  return (
    <Box
      onClick={handleCardClick}
      variants={toastContainer}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        cursor: 'pointer',
        width: ['100%', '100%', '50.1rem', '100%', '46.7rem', '49.6rem'],
        position: 'fixed',
        left: 0,
        bottom: 0,
        bg: 'green',
        p: [
          '2rem 5.5rem',
          '2rem 15rem 2rem 10.5rem',
          '2.4rem 6rem 2.4rem 6.3rem',
          '2rem 9.2rem',
          '3rem 8.4rem 3rem 9.7rem',
          '3rem 10.8rem 3rem 8rem'
        ],
        display: 'flex',
        gap: [0, 0, 0, '2rem', 0, 0],
        flexDirection: [
          'column',
          'column',
          'column',
          'row',
          'column',
          'column'
        ],
        justifyContent: [
          'center',
          'center',
          'center',
          'space-between',
          'center',
          'center'
        ],
        alignItems: [null, null, null, 'center', 'flex-start', 'flex-start'],
        zIndex: 200
      }}>
      {closeDuration ? (
        // Timeout line
        <Line
          initial={{
            scaleX: 0
          }}
          animate={{
            scaleX: '100%',
            transformOrigin: 'left',
            transition: {
              duration: closeDuration / 1000,
              ease: 'linear'
            }
          }}
          sx={{
            borderTop: '3px solid black',
            position: 'absolute',
            top: 0,
            left: 0
          }}
        />
      ) : (
        // CLoseButton
        <Button
          variant="clear"
          onClick={handleCloseButtonClick}
          sx={{
            position: 'absolute',
            width: ['2rem', '2.3rem', '2.4rem', '2rem', '2.4rem', '2.6rem'],
            top: ['1.2rem', '1.8rem', '1.4rem', 'auto', '1.2rem', '1.2rem'],
            right: ['1.2rem', '1.8rem', '1.4rem', '2rem', '1.2rem', '1.2rem'],
            zIndex: 1
          }}>
          <SmallCloseIcon
            sx={{
              color: 'primary'
            }}
          />
        </Button>
      )}
      <Heading
        as="h3"
        sx={{
          lineHeight: 1.25,
          fontFamily: 'primary.bold',
          fontSize: [
            '2.2rem',
            '2.6rem',
            '2.8rem',
            '1.8rem',
            '2.4rem',
            '2.4rem'
          ],
          '& > span': {
            fontFamily: 'primary.italic'
          }
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <CardButton
        text={textButton}
        sx={{
          mt: ['2.9rem', '3.8rem', '5.7rem', 0, '1.6rem', '1.9rem'],
          width: ['100%', '100%', '100%', '26%', '100%', '100%']
        }}
      />
    </Box>
  );
};
