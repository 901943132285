const getItemsByPartnerId = (partnerId, items) =>
  items.filter((item) => item.producer?.id === partnerId);

export const filterItemsByPartnerIds = (items, partners) => {
  return partners.map((partner) => ({
    partner,
    items: getItemsByPartnerId(partner.partnerId, items)
  }));
};

const getPartnerNameById = (items, partnerId) => {
  return items.find((item) => item.producer?.id === partnerId).producer?.name;
};

const getPartnerIds = (items) => {
  const partnerIds = items.reduce((acc, cur) => {
    if (!acc.includes(cur.producer?.id)) return [...acc, cur.producer?.id];
    // NOTE: sometimes the producer is null .... but the items will still returned with partner undefined
    return [...acc];
  }, []);

  return partnerIds.map((partnerId) => ({
    partnerId,
    name: getPartnerNameById(items, partnerId)
  }));
};

export const regroupItemsByPartners = (items) => {
  const availablePartners = getPartnerIds(items);

  const purExits = availablePartners.find((partner) => partner.name === 'PUR');

  return {
    purExits: !!purExits,
    items: filterItemsByPartnerIds(items, availablePartners)
  };
};
