import React from 'react';

// ExternalComponents
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Animation
import { moveOnMouseAnimation, scaleUpAnimation } from './animation';

export const PointerCircle = ({ text, sx }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    return moveOnMouseAnimation(ref.current);
  }, []);

  return (
    <Box
      className="pointer-circle"
      variants={scaleUpAnimation}
      ref={ref}
      sx={{
        bg: 'primary',
        borderRadius: '50%',
        width: '10rem',
        height: '10rem',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        display: ['none', 'none', 'none', 'none', 'none', 'flex'],
        ...sx
      }}>
      <Paragraph
        sx={{
          color: 'background',
          fontSize: '2.2rem',
          fontFamily: 'body.medium',
          letterSpacing: '0.05em'
        }}>
        {text}
      </Paragraph>
    </Box>
  );
};

// How to use:
/**
 * make the parent element which is motion component and set the props {
 * initial= "initial"
 * whileHover= "animate"
 * }
 */
