// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file
const base = {
  primary: '#1E1E1E', // black
  secondary: '#D94764', // red
  yellow: '#FFF383',
  blue: '#DAF9F5',
  green: '#CCF8D1',
  background: '#FFFBF2',
  grey: '#858585',
  red: '#AA3535',
  divider: '#1E1E1E'
};

const textColors = {
  text: base.primary,
  heading: base.primary,
  background: base.background
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
