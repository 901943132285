import React from 'react';

// External Components
import { Button } from '@thepuzzlers/pieces';

// Local Components
import { LineIcon } from 'components';

export const PlusButton = ({ sx, lineSx, ...props }) => {
  return (
    <Button
      variant="clear"
      sx={{
        p: ['1.4rem', null, null, null, null, '1.4rem'],
        position: 'relative',
        ...sx
      }}
      {...props}>
      <LineIcon sx={lineSx} />
      <LineIcon
        sx={{
          position: 'absolute',
          transform: 'rotate(90deg)',
          ...lineSx
        }}
      />
    </Button>
  );
};
