import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getPaddingPercentageForAspectRatio } from 'components/helper';

export const AspectRatioImageContainer = ({
  designatedRef,
  className,
  src,
  alt,
  sx,
  imageSx,
  imgProps,
  imgStyle,
  aspectRatio, // can be accept array of aspect ratios (must be number) or single value for instance 16/9
  ...props
}) => {
  return (
    <Box
      ref={designatedRef}
      className={`image-container ${className}`}
      sx={{
        position: 'relative',
        pt: getPaddingPercentageForAspectRatio(aspectRatio),
        ...sx
      }}
      {...props}>
      <GatsbyImage
        className="gatsby-image"
        {...imgProps}
        image={getImage(src)}
        alt={alt ? alt : ''}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          ...imageSx
        }}
        imgStyle={imgStyle}
      />
    </Box>
  );
};
