import React from 'react';
import { Box } from '@thepuzzlers/pieces';

export const VerticalLine = ({ sx, isBold = false }) => {
  return (
    <Box
      as="hr"
      sx={{
        borderRight: ({ colors }) =>
          `${isBold ? '2px' : '1px'} solid ${colors.primary}`,
        borderStyle: 'inset',
        height: '100%',
        ...sx
      }}
    />
  );
};
