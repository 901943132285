import React from 'react';

// External components
import { FlexWrapper, GridWrapper, Box, Button } from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';
import { navigate } from 'gatsby';

import { NavigationLink, useLocalizePath } from 'gatsby-theme-thepuzzlers-intl';
import { useShoppingCart } from 'gatsby-theme-thepuzzlers-cart';

// Local Components
import { PurLogo } from 'components';

// Self Components
import { NavButton } from './NavButton';

// Data
import { useNavigationData } from './useNavigationData';

// Assets
import { PerfectCircle } from 'components';

// hooks
import { useLocation } from '@reach/router';

// Animations
import { circleHoverVariant } from './animation';

export const NavigationBar = ({
  pageLinks,
  handleNavButtonClick,
  isOnOverlay,
  handleOpenCart
}) => {
  const { homeLink, navButtonText, cartButtonText } = useNavigationData();
  return (
    // Markup
    <GridWrapper
      as="nav"
      className="navigation-bar"
      sx={{
        py: ['1.1rem', '1.8rem', '1.8rem', '1.1rem', '1.9rem', '2.3rem'],
        bg: 'background'
      }}>
      <Logo to={homeLink} />
      <NavigationLinks links={pageLinks} />
      <NavCartButton text={cartButtonText} handleOpenCart={handleOpenCart} />
      <NavButton
        text={navButtonText}
        onClick={handleNavButtonClick}
        isOnOverlay={isOnOverlay}
      />
    </GridWrapper>
  );
};

const Logo = ({ to }) => {
  return (
    <FlexWrapper
      sx={{
        gridColumn: [
          '1 / span 2',
          '1 / span 2',
          '1 / span 3',
          '1 / span 2',
          '1 / span 2',
          '2 / span 2'
        ],
        width: ['5.1rem', '7.2rem', '7.2rem', '5.6rem', '7.2rem', '6.5rem'],
        alignItems: 'center',
        alignSelf: 'center',
        position: 'relative',
        zIndex: 2
      }}>
      {/* extra box added for animation purpose */}
      <Box
        className="navigation-logo"
        sx={{
          width: '100%'
        }}>
        <NavigationLink
          to={to}
          sx={{
            // Navigation links is form-up by span > a so we have to give display flex to a
            width: '100%',
            '& > a': {
              display: 'flex',
              alignItems: 'center'
            }
          }}>
          <PurLogo />
        </NavigationLink>
      </Box>
    </FlexWrapper>
  );
};

const NavigationLinks = ({ links }) => {
  return (
    <FlexWrapper
      sx={{
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gridColumn: [
          null,
          null,
          null,
          '4 /  span 17',
          '4/ span 17',
          '5 / span 13'
        ],
        display: ['none', 'none', 'none', 'flex', 'flex', 'flex'],
        // adjust the navigation links to be closer with CartButton
        mr: [null, null, null, null, '-1.7rem', '1rem']
      }}>
      {links.map((link, index) => (
        <NavLinkItem key={index} link={link} />
      ))}
    </FlexWrapper>
  );
};

const NavLinkItem = ({ link: { label, to } }) => {
  const { pathname } = useLocation();
  const localizedPath = useLocalizePath(to, 'de');

  return (
    <FlexWrapper
      initial="initial"
      whileHover="hover"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        ':not(:first-of-type)': {
          // we using margin instead of gap because safari 14.1 not support gap
          ml: [null, null, null, '2.4rem', '3.2rem', '3.2rem']
        }
      }}>
      <NavigationLink
        to={to}
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          fontSize: [null, null, null, '1.3rem', '1.5rem', '1.5rem'],
          whiteSpace: 'nowrap',
          position: 'relative',
          zIndex: 1
        }}>
        {label}
      </NavigationLink>

      {/* Circle for hover effect */}
      <NavlinkCircle
        // Animation Value
        variants={circleHoverVariant}
      />

      <AnimatePresence>
        {pathname === localizedPath && (
          <NavlinkCircle
            // Animation Values
            initial={{
              scale: 0.7
            }}
            animate={{
              scale: 1
            }}
            exit={{
              scale: 0
            }}
            transition={{
              duration: 0.2
            }}
          />
        )}
      </AnimatePresence>
    </FlexWrapper>
  );
};

const NavCartButton = ({ text, handleOpenCart }) => {
  const { totalItems } = useShoppingCart();

  const handleOpenShoppingCart = () => {
    if (totalItems > 0) return handleOpenCart();
    return navigate('/shop/');
  };

  return (
    <Button
      variant="clear"
      onClick={handleOpenShoppingCart}
      sx={{
        gridColumn: [
          '4 / span 4',
          '3 / span 3',
          '4 / span 5',
          '22/25',
          '22/25',
          '22 / span 2'
        ],
        justifySelf: ['start', 'start', 'start', 'end', 'end', 'end'],
        ml: ['-0.6rem', '1.6rem', '2.2rem', 0, 0, 0],
        textTransform: 'none',
        fontFamily: 'body.bold',
        lineHeight: 1,
        fontSize: ['1.4rem', '1.7rem', '1.8rem', '1.3rem', '1.5rem', '1.5rem']
      }}>
      {text} ({totalItems})
    </Button>
  );
};

const NavlinkCircle = ({ ...props }) => (
  <PerfectCircle
    sx={{
      width: [null, null, null, '2.4rem', '3.4rem', '3.4rem'],
      position: 'absolute',
      zIndex: 0
    }}
    {...props}
  />
);
