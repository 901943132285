module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/node_modules/gatsby-theme-thepuzzlers-cart/src/intl","languages":["de"],"locales":{"default":"de","translations":[]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"},{"default":"gift","de":"praesentkoerbe"},{"default":"culinary-boxes","de":"kulinarische-boxen"}],"secondPath":"/vercel/path0/src/intl","redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-cart/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://boxenstop-production.onrender.com/public","business_id":"cl10ont3q01152arfowat59aw","stripeAccountId":"acct_1I9Rk5LVH2XT4cf2","orderTypes":["PICKUP","POSTAL"],"intlThemeOptions":{"path":"/vercel/path0/src/intl","languages":["de"],"locales":{"default":"de","translations":[]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"},{"default":"gift","de":"praesentkoerbe"},{"default":"culinary-boxes","de":"kulinarische-boxen"}],"secondPath":null,"redirect":false,"wrapProvider":true},"acceptsCashPayment":false,"cacheConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://the-puzzlers.matomo.cloud/","siteUrl":"https://www.einfachpurgeniessen.de","disableCookies":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"pur"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
