export const forms = {
  input: {
    '::placeholder': {}
  },

  // Overrides styles of checkout components
  checkout: {
    input: {
      bg: 'transparent',
      borderBottom: (t) => `0.1rem solid ${t.colors.primary}`,
      border: 'unset',
      boxShadow: 'none',
      outline: 'none',
      py: ['1.4rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem'],
      transition: 'all 0.2s ease-out',
      fontFamily: 'body.bold',
      '&::placeholder': {
        color: 'grey',
        fontFamily: 'body.bold',
        fontSize: ['1.5rem', '1.5rem', '1.7rem', '1.4rem', '1.7rem', '1.5rem'],
        opacity: 1,
        lineHeight: 1.35,
        marginTop: '0.3rem'
      },
      '&:focus': {
        borderBottom: (t) => `0.4rem solid ${t.colors.primary}`,
        marginBottom: '`0px'
      }
    },
    label: {},
    inputContainer: {}
  }
};
