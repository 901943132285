import React from 'react';

import { Box } from '@thepuzzlers/pieces';

export const PerfectCircle = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 63 63"
      fill="none"
      sx={{
        color: 'yellow',
        ...sx
      }}
      {...props}>
      <path
        d="M28.2035 62.3332C-12.4639 62.2825 -6.74816 1.47968 29.9739 0.921387C73.575 3.05304 72.4622 62.0287 28.2035 62.3332ZM10.6012 24.268C12.9279 18.7867 17.7838 15.9445 20.7681 10.7169C17.0251 11.9857 10.8035 20.5123 10.6012 24.268ZM51.218 37.6163C48.689 40.8645 46.261 44.0112 43.8331 47.158C48.5372 43.0977 50.8134 40.1032 51.218 37.6163ZM9.64021 11.9857C12.321 9.70179 15.4064 7.92536 17.7837 5.33693C14.7489 7.16406 11.8152 9.09274 9.64021 11.9857ZM44.2378 36.4997C43.1756 37.8701 41.6076 38.9359 41.4053 40.9153C42.7204 39.6464 43.8331 38.3268 44.2378 36.4997ZM18.6942 24.0651C19.8576 22.2887 21.2739 20.7153 21.9315 18.6851C20.2117 20.1062 19.5035 22.1364 18.6942 24.0651ZM52.8366 52.1318C54.0506 52.3856 55.3152 50.1524 56.0739 49.1881C54.9611 50.1017 54 51.2183 52.8366 52.1318ZM8.22391 13.356C7.66752 13.762 6.60526 14.6249 6.90875 15.3355C7.41456 15.1325 8.32507 14.3203 8.22391 13.356Z"
        fill="currentColor"
      />
    </Box>
  );
};
