import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useKisSectionData = (data, defaultLocale = 'de') => {
  // if no data in the nodes return empty array
  if (data.nodes.length === 0) return null;

  //  if there is only one data in the nodes (for non-repeatable data-type) return single object that contain the entry
  const { locale } = usePageContext();
  if (data.nodes.length === 1) {
    const sectionData = data.nodes[0];
    if (locale === defaultLocale) return sectionData.entry;
    return sectionData.translations[locale];
  }

  // if there are multiple data in the nodes (for repeatable data-type) return multiple object(entries) of entry
  return data.nodes.reduce((acc, cur) => {
    const dataToBeUsed =
      locale === defaultLocale ? cur.entry : cur.translations[locale];
    return [...acc, dataToBeUsed];
  }, []);
};
