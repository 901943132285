import { graphql, useStaticQuery } from 'gatsby';

export const useCartData = () => {
  const data = useStaticQuery(graphql`
    query {
      cartDataJson {
        overlay {
          headlines {
            delivery
            pickup
          }
          labels {
            shippingFee
            total
            remove
            add {
              regular
            }
          }
        }
      }
    }
  `);

  return data.cartDataJson;
};
