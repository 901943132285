import React from 'react';
import { Box } from '@thepuzzlers/pieces';

export const RedStripes = ({ className, sx }) => {
  return (
    <Box
      className={className}
      as="svg"
      viewBox="0 0 319 140"
      fill="none"
      sx={sx}>
      <path
        d="M2.93022 81.1791C-3.50538 73.9672 33.5407 78.0114 29.1577 82.7136C28.7885 90.1337 -7.46223 83.8018 2.93022 81.1791Z"
        fill="currentColor"
      />
      <path
        d="M317.32 78.4977C321.404 74.8318 300.045 74.5657 302.275 77.5517C302.06 81.8089 323.096 80.7151 317.32 78.4977Z"
        fill="currentColor"
      />
      <path
        d="M31.8822 13.0636C32.5674 2.98419 54.2267 36.3031 48.1241 35.7765C42.8105 40.3627 23.2388 6.19362 31.8822 13.0636Z"
        fill="currentColor"
      />
      <path
        d="M41.4481 127.018C46.0426 122.195 56.4288 115.079 61.5512 115.809C63.6476 121.432 34.7114 139.566 41.4481 127.018Z"
        fill="currentColor"
      />
      <path
        d="M300.006 34.0489C295.411 38.8709 285.025 45.9875 279.903 45.2574C277.806 39.6343 306.742 21.5004 300.006 34.0489Z"
        fill="currentColor"
      />
      <path
        d="M280.217 123.419C275.436 118.821 268.053 108.085 268.25 102.405C273.148 99.5979 292.184 129.664 280.217 123.419Z"
        fill="currentColor"
      />
      <path
        d="M104.98 3.47093C105.88 10.387 105.116 23.8242 101.601 27.9895C96.2237 26.6029 99.6067 -9.85032 104.98 3.47093Z"
        fill="currentColor"
      />
      <path
        d="M114.008 129.646C103.666 150.085 108.741 123.944 115.615 118.13C121.168 115.852 115.767 127.314 114.008 129.646Z"
        fill="currentColor"
      />
      <path
        d="M165.531 129.356C171.352 151.856 158.143 129.423 159.267 119.963C161.73 114.031 165.276 126.339 165.531 129.356Z"
        fill="currentColor"
      />
      <path
        d="M165.531 15.3856C171.352 -7.114 158.143 15.3197 159.267 24.7793C161.73 30.711 165.276 18.4035 165.531 15.3856Z"
        fill="currentColor"
      />
      <path
        d="M226.209 21.3847C238.716 2.46769 219.293 18.5229 217.388 27.83C217.845 34.3323 225.025 24.1214 226.209 21.3847Z"
        fill="currentColor"
      />
      <path
        d="M222.774 123.792C229.379 146.028 215.394 124.171 216.186 114.671C218.44 108.639 222.414 120.787 222.774 123.792Z"
        fill="currentColor"
      />
    </Box>
  );
};
