import { graphql, useStaticQuery } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisImportantNoticeData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisBanner {
        nodes {
          entry {
            description
            footer_note_headline
            headline
            footer_note {
              html
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
