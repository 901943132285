import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisBlogPostArticlesData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisBlogArticles {
        nodes {
          entry {
            author
            brief_description
            caption
            cover_image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            date
            full_description {
              html
            }
            headline
            slug
          }
        }
      }
    }
  `);
  return useKisSectionData(data);
};
