import { graphql, useStaticQuery } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisPopUpMenuData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisPopUpBanner {
        nodes {
          entry {
            active
            button_text
            description {
              html
            }
            link_location
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
