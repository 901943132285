import React from 'react';

// External Components
import { Button } from '@thepuzzlers/pieces';

// Local Components
import { LineIcon } from 'components';

export const MinusButton = ({ sx, lineSx, ...props }) => {
  return (
    <Button variant="clear" sx={sx} {...props}>
      <LineIcon sx={lineSx} />
    </Button>
  );
};
