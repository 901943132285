exports.components = {
  "component---node-modules-gatsby-theme-thepuzzlers-cart-src-pages-cart-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-cart/src/pages/cart.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-cart-src-pages-cart-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-cart-src-pages-checkout-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-cart/src/pages/checkout.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-cart-src-pages-checkout-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/legal.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/privacy-policy.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-culinary-boxes-js": () => import("./../../../src/pages/culinary-boxes.js" /* webpackChunkName: "component---src-pages-culinary-boxes-js" */),
  "component---src-pages-gift-js": () => import("./../../../src/pages/gift.js" /* webpackChunkName: "component---src-pages-gift-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-shop-box-box-id-js": () => import("./../../../src/pages/shop/box/[boxId].js" /* webpackChunkName: "component---src-pages-shop-box-box-id-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-product-product-id-js": () => import("./../../../src/pages/shop/product/[productId].js" /* webpackChunkName: "component---src-pages-shop-product-product-id-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blogPost/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */)
}

