import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// This component accept two link in array only
export const LinksWrapper = ({ children, sx }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'end',
        gap: ['5.9rem', '10.1rem', '7.2rem', '6.6rem', '9.2rem', '12.4rem'],
        ...sx
      }}>
      {children}
    </Box>
  );
};
