import { useStaticQuery, graphql } from 'gatsby';

const PRODUCERS = graphql`
  fragment producerFields on Boxenstop_Producer {
    id
    name
    description {
      text
      html
    }
    category
    city
    street
    streetNumber
    zipCode
    coverImage {
      url
    }
    websiteUrl
    facebook
    instagram
  }
  query producers {
    boxenstop {
      producers {
        ...producerFields
      }
    }
  }
`;

export function usePartners(options) {
  const data = useStaticQuery(PRODUCERS, { ...options });

  if (!data) return [];
  return data.boxenstop.producers?.filter(({ name }) => name !== 'PUR');
}
