import React from 'react';

// External Components
import { FlexWrapper, Heading, Image } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// Animation
import { scaleImageOnScroll } from './animation';

export const IllustrationHeadline = ({
  categoryName,
  image,
  bgColor,
  scroller
}) => {
  React.useEffect(() => {
    const killAnimation = scaleImageOnScroll(scroller);

    return killAnimation;
  }, []);

  const isUrl = typeof image === 'string';

  const imageSx = {
    width: ['20.9rem', '25.4rem', '31.8rem', '16.6rem', '21.9rem', '25.4rem'],
    height: ['20.9rem', '25.4rem', '31.8rem', '16.6rem', '21.9rem', '25.4rem'],
    // aspectRatio: '1/1',
    mt: ['2.7rem', '2.7rem', '2.4rem', 0, 0, 0]
  };

  return (
    <FlexWrapper
      className="category-headline-with-illustration"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: [
          '1/13',
          '1/13',
          '4 / span 18',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        overflow: 'hidden',
        p: [
          '3.2rem 2.8rem 3.2rem 2.8rem',
          '4rem 8.8rem 4rem 8.8rem',
          '4rem 9rem 4rem 9rem',
          '0.8rem 2.9rem 0.8rem 5.8rem',
          '1.1rem 4.2rem 1.1rem  8.4rem',
          '0.3rem 5.4rem 0.3rem 10.8rem'
        ],
        flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
        bg: bgColor
      }}>
      <Heading
        as="h2"
        sx={{
          lineHeight: 1.1,
          fontSize: ['3rem', '3.6rem', '3.8rem', '2.8rem', '3.6rem', '3.8rem'],
          fontFamily: 'primary.bold',
          textAlign: ['center', 'center', 'center', 'left', 'left', 'left'],
          width: [null, null, null, '28.2rem', '37rem', '41.6rem']
        }}>
        {categoryName}
      </Heading>
      {isUrl ? (
        <Image
          src={image}
          alt=""
          sx={{
            ...imageSx
          }}
        />
      ) : (
        <ImageContainer
          src={image}
          alt=""
          sx={{
            ...imageSx
          }}
        />
      )}
    </FlexWrapper>
  );
};
