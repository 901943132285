// Constants
import { itemTypes } from 'constants/itemTypes';

export const getPrice = (product, variant, itemType, selectedSize) => {
  if (!product && !variant) return 0;
  if (variant)
    return variant?.sizes?.sizes.length === 1
      ? variant?.sizes?.sizes[0].pricing.price || variant?.pricing?.price
      : selectedSize?.pricing.price;
  if (product) {
    if (itemType === itemTypes.BOX) return product.boxPricing.price;

    if (product.sizes) {
      return product.sizes?.sizes[0].pricing.price;
    }
    if (product.pricing) {
      return product.pricing.price;
    }
  }
  return itemType !== itemTypes.BOX;
};
