import React from 'react';

export const NavigationContext = React.createContext();

export const useNavigation = () => React.useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [isCartOverlayOpen, setIsCartOverlayOpen] = React.useState(false);

  const handleOpenCart = () => setIsCartOverlayOpen(!isCartOverlayOpen);

  return (
    <NavigationContext.Provider
      value={{
        isCartOverlayOpen,
        handleOpenCart
      }}>
      {children}
    </NavigationContext.Provider>
  );
};
