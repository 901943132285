import React from 'react';

// External Components
import { Box, fullWidthMinusMargins } from '@thepuzzlers/pieces';

// Animation
import { blueCoverAnimation, coverContainerAnimation } from './animation';

export const TransitionCover = () => {
  return (
    <Box
      className="cover-container"
      variants={coverContainerAnimation}
      sx={{
        position: 'absolute',
        // top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        bg: 'yellow',
        mx: fullWidthMinusMargins
      }}>
      <Cover
        variants={blueCoverAnimation}
        sx={{
          bg: 'blue',
          height: '100%',
          width: '100%',
          position: 'absolute'
        }}
      />
    </Box>
  );
};

const Cover = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        ...sx
      }}
      {...props}
    />
  );
};
