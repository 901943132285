import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisPartnersData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisPartners {
        nodes {
          entry {
            name
            website
            location
          }
        }
      }
    }
  `);
  return useKisSectionData(data);
};
