import React from 'react';
import PropTypes from 'prop-types';

// External Components
import { Helmet } from 'react-helmet';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';
import { useLocation } from '@gatsbyjs/reach-router';
import { useRevertPathToDefaultLocale } from 'gatsby-theme-thepuzzlers-intl';
import { AnimatePresence } from 'framer-motion';

// GSAP
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Context
import { NavigationProvider } from 'context/NavigationContext';
import { ToastContextProvider } from 'components/toast/toastContext';

// Sections
import { Footer, Navigation } from '../sections';

// Fonts
import 'gatsby-plugin-theme-ui/fonts/fontImports';

// Animations
import { pageTransitionVariant } from './animation';

// hooks
// import { useScrollTriggerRefresh } from 'hooks';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, pageContext }) => {
  const { pathname } = useLocation();

  // ScrollTrigger Refresh not take effect for animation inside ScrollTriggerMatch media
  // useScrollTriggerRefresh();
  let fontSizeFactor = 1;
  let lastHeight = 0;
  let lastWidth = 0;

  const setDocHeight = () => {
    if (
      Math.abs(lastWidth - window.visualViewport.width) > 100 ||
      Math.abs(lastHeight - window.visualViewport.height) > 100
    ) {
      lastHeight = window.visualViewport.height;
      lastWidth = window.visualViewport.width;

      document.documentElement.style.setProperty(
        '--vh',
        `${window.visualViewport.height / 100}px`
      );
    }
  };

  React.useEffect(() => {
    setDocHeight();
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
  }, []);

  const mediaQueries = `
  html {
    font-size: calc(${9.365 * fontSizeFactor}px + ((100vw - 320px) * ${
    9.365 * fontSizeFactor
  } / 2.5 / 320)) !important;
}

  @media screen and (min-width: 500px) {
      html {
        font-size: calc(${8.99 * fontSizeFactor}px + ((100vw - 500px) * ${
    8.99 * fontSizeFactor
  } / 2.5 / 500)) !important;
      }
    }
    
    @media screen and (min-width: 750px) and (orientation: portrait) {
      html {
      font-size: calc(${9.57 * fontSizeFactor}px + ((100vw - 750px) * ${
    9.57 * fontSizeFactor
  } / 2.5 / 750)) !important;
      }
    }        
    
    @media screen and (min-width: 700px) and (orientation: landscape) {
      html {
      font-size: calc(${9.4 * fontSizeFactor}px + ((100vw - 700px) * ${
    9.4 * fontSizeFactor
  } / 2.5 / 700)) !important;
      }
    }
     
    @media screen and (min-width: 1000px) {
      html {
      font-size: calc(${9.28 * fontSizeFactor}px + ((100vw - 1000px) * ${
    9.28 * fontSizeFactor
  } / 2.5 / 1000)) !important;
      }
    }
    
    @media screen and (min-width: 1400px) {
      html {
        font-size: calc(${9.89 * fontSizeFactor}px + ((100vw - 1400px) * ${
    9.89 * fontSizeFactor
  } / 2.5 / 1400)) !important;
}
  }
  `;

  const pageName = useRevertPathToDefaultLocale(pathname)
    // Remove all slashes from string
    .replace(/\//g, '');

  const isInProductDetail =
    pathname.includes('/product/') || pathname.includes('/box/');

  const isInMenuPage = pathname.includes('menu');

  // Check if user has been to a different path before
  // (for back button functionality of menu page)
  React.useEffect(() => {
    if (!isInMenuPage) {
      // Remove first and last slash from string
      const cleanPath = pathname.replace(/^\/?|\/?$/g, '');

      sessionStorage.setItem('current-path', JSON.stringify(cleanPath));
    }
  }, [pathname]);

  // set current path to null when user is leaving the page - in case they come back again later
  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('current-path', null);
    };
  }, []);
  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout pageContext={pageContext}>
      <ToastContextProvider>
        <NavigationProvider>
          {!isInProductDetail && <Navigation />}
          <AnimatePresence>
            <Box
              key={pageName}
              as="main"
              // Page transition animation values
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageTransitionVariant}
              sx={{
                position: 'relative',
                bg: 'background'
                // cannot set the overlow hidden here beacuse parther page header containe image that overlow the section height
                // overflow: 'hidden'
              }}>
              {children}
            </Box>
          </AnimatePresence>
          {pageName !== 'checkout' && !isInProductDetail && !isInMenuPage && (
            <AnimatePresence>
              <Footer
                key={pageName}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransitionVariant}
              />
            </AnimatePresence>
          )}
          {/* onChangeClientState triggered when DOM state is changed */}
          <Helmet onChangeClientState={() => ScrollTrigger.refresh(true)}>
            <style type="text/css">{mediaQueries}</style>
          </Helmet>
        </NavigationProvider>
      </ToastContextProvider>
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
