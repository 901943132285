import { getItems, filterByOrderType, regroupItemsByPartners } from '../helper';

const CATEGORIES_TO_FILTER_OUT = [
  'claqbf69z523892crvnex6mxjt', // Präsentkörbe
  'claqbf6ah523982crvdvzlzr48', // Die kleinen Einstiegs-Drogen
  'claqbf6ah523992crvvde99lrd', // Die schnelle Küche,
  'clb7o2qaz729772cobb7n2wcqb' // Kulinarische Boxen
];

export const prepareProductToDisplay = (productCategories) => {
  if (!productCategories) return [];

  let purCategories = [];

  const reOrganizedCategories = productCategories.reduce((acc, cur) => {
    const productCategoryWithItemsReorganized = organizeItemsInTheProduct(cur);
    // skip if the product has no productCategoryWithItemsReorganized to display after filtered by function above
    if (productCategoryWithItemsReorganized.length === 0) return acc;

    if (productCategoryWithItemsReorganized.purExits) {
      purCategories.push(productCategoryWithItemsReorganized);
      return acc;
    }
    return [...acc, productCategoryWithItemsReorganized];
  }, []);

  const categories = [...purCategories, ...reOrganizedCategories];

  return categories.filter(
    (category) => !CATEGORIES_TO_FILTER_OUT.includes(category.id)
  );
};
export const organizeItemsInTheProduct = (productCategoryItems) => {
  const { id, name, coverImage, itemType } = productCategoryItems;
  const allItems = getItems(
    productCategoryItems.items,
    productCategoryItems.subCategories
  );
  const { items: itemsToDisplay, purExits } = regroupItemsByPartners(allItems);

  return {
    id,
    purExits,
    name,
    coverImage,
    itemType: itemType ?? productCategoryItems?.subCategories[0].itemType,
    itemsToDisplay
  };
};
