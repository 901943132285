import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { BlogCardText } from 'components';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// helper
import { convertApiHtmlText } from 'boxenStopHooks';

export const CtaCards = ({
  data: {
    card_one_description,
    card_one_headline,
    card_one_link_label,
    card_one_link_to,
    card_two_description,
    card_two_headline,
    card_two_link_label,
    card_two_link_to
  }
}) => {
  const shopCard = {
    headline: card_one_headline,
    description: card_one_description,
    linkLabel: card_one_link_label,
    to: card_one_link_to
  };

  const contactCard = {
    headline: card_two_headline,
    description: card_two_description,
    linkLabel: card_two_link_label,
    to: card_two_link_to
  };
  return (
    <Section id="gift__cta-cards">
      <ShopCard data={shopCard} />
      <ContactCard data={contactCard} />
    </Section>
  );
};

// Elements

const ShopCard = ({ data }) => (
  <Card
    data={data}
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '2/span 14',
        '1/span 10',
        '2/span 9',
        '4/span 8'
      ]
    }}
  />
);

const ContactCard = ({ data }) => (
  <Card
    data={data}
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '10/span 14',
        '15/25',
        '15/span 9',
        '14/span 8'
      ],
      mt: ['14.3rem', '17.3rem', '13.8rem', 0, 0, 0]
    }}
  />
);

const Card = ({ data: { headline, description, linkLabel, to }, sx }) => (
  <NavigationLink to={to} sx={sx}>
    <BlogCardText
      headline={convertApiHtmlText(headline.html)}
      briefDescription={description}
      readLink={{ label: linkLabel }}
      initial="initial"
      whileHover="animate"
      titleWithSpans
    />
  </NavigationLink>
);
