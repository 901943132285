import React from 'react';

// External Components
import {
  Overlay,
  Box,
  Button,
  FlexWrapper,
  GridWrapper,
  Paragraph
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@reach/router';

// Local Components
import { HorizontalLine, GridSpacer } from 'components';

// Selft Components
import { NavigationBar } from './NavigationBar';

// Animation
import {
  revealAnimation,
  linksAndContactAnimation,
  slideUpAnimation
} from './animation';

export const NavigationOverlay = ({ handleClose, pageLinks, contact }) => {
  return (
    <Overlay
      handleClose={handleClose}
      containerSx={{ overflow: 'scroll' }}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress>
      <Content
        handleClose={handleClose}
        pageLinks={pageLinks}
        contact={contact}
      />
    </Overlay>
  );
};

const Content = ({ handleClose, pageLinks, contact }) => {
  return (
    <Box
      className="navigation-overlay__wrapper"
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        alignItems: 'start',
        width: '100%',
        minHeight: '100%'
      }}>
      {/* NAVIGATION BAR */}
      <NavigationBar
        pageLinks={pageLinks}
        handleNavButtonClick={handleClose}
        isOnOverlay
      />
      {/* OVERLAY CONTENT */}
      {/* Additional box needed for animation purpose */}
      <Box
        // this box contains Horizontal line at the bottom
        // which is pushed by the elemnt inside while growing
        sx={{
          height: '100%'
        }}>
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
            bg: 'background'
          }}
          // Animation Value
          initial="initial"
          animate="animate"
          exit="initial"
          variants={revealAnimation}>
          <GridWrapper>
            <Spacer />
            <PageLinksAndContact
              pageLinks={pageLinks}
              handleClose={handleClose}
              contact={contact}
            />
            <Spacer />
          </GridWrapper>
        </Box>
        <HorizontalLine isBold />
      </Box>
    </Box>
  );
};

const PageLinksAndContact = ({ pageLinks, handleClose, contact }) => {
  return (
    <Box
      sx={{
        gridColumn: ['1/13', '2 / span 10', '3 / span 20', null, null, null]
      }}
      // Animation Value
      initial="initial"
      animate="animate"
      exit="exit"
      variants={linksAndContactAnimation}>
      {pageLinks.map((link, index) => (
        <PageLinkItem key={index} link={link} handleClose={handleClose} />
      ))}
      <ContactInfo contact={contact} />
    </Box>
  );
};
const PageLinkItem = ({ link: { label, to }, handleClose }) => {
  const { pathname } = useLocation();

  // set the path ref
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);

  return (
    <Box
      sx={{
        display: 'block',
        ':not(:first-of-type)': {
          mt: ['2rem', '2.4rem', '2.4rem']
        },
        overflow: 'hidden'
      }}>
      {/* This additional box is needed for animation purpose */}
      <Box
        // Animation Value
        variants={slideUpAnimation}>
        <NavigationLink
          to={to}
          sx={{
            fontFamily: 'primary.normal',
            lineHeight: 1.1,
            fontSize: ['3rem', '4rem', '5rem', null, null, null]
          }}>
          {label}
        </NavigationLink>
      </Box>
      <HorizontalLine
        sx={{
          mt: ['2rem', '2.4rem', '2.4rem']
        }}
      />
    </Box>
  );
};

const ContactInfo = ({ contact: { title, description } }) => {
  return (
    <Box
      sx={{
        mt: ['8rem', '13rem', '10.8rem', null, null, null]
      }}
      // Animation Value
      variants={slideUpAnimation}>
      <Paragraph
        sx={{
          textTransform: 'uppercase',
          fontFamily: 'body.bold',
          lineHeight: 1,
          fontSize: ['1.3rem', '1.4rem', '1.6rem', null, null, null]
        }}>
        {title}
      </Paragraph>
      <Paragraph
        sx={{
          lineHeight: 2,
          fontSize: ['1.5rem', '1.6rem', '1.7', null, null, null],
          mt: '0.8rem'
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  );
};

const Spacer = () => (
  <GridSpacer height={['4.2rem', '7.7rem', '9.2rem', null, null, null]} />
);
