import React from 'react';

// this aspect ratio

// External Components
import { Box } from '@thepuzzlers/pieces';
import { getPaddingPercentageForAspectRatio } from 'components/helper';

export const AspectRatioBox = ({
  sx,
  containerSx,
  children,
  aspectRatio,
  ...props
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        pt: getPaddingPercentageForAspectRatio(aspectRatio),
        ...sx
      }}
      {...props}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          ...containerSx
        }}>
        {children}
      </Box>
    </Box>
  );
};
