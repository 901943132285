import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { LineWithArrow } from 'components';

export const PrimaryLink = ({ text, to, sx, ...props }) => {
  return (
    /* Extra box for animation purposes */
    <Box
      as="span"
      className="primary-link"
      // Animation values
      initial="initial"
      whileHover="animate"
      sx={{
        cursor: 'pointer',
        ...sx
      }}
      {...props}>
      {/* NavigationLink cannot contain framer motion props */}
      <NavigationLink
        to={to}
        sx={{
          variant: 'buttons.primary'
        }}>
        <Box
          as="span"
          sx={{
            pr: ['2.7rem', '6rem', '8.8rem', '4.2rem', '9.3rem', '9.1rem']
          }}>
          {text}
        </Box>
        <LineWithArrow
          sx={{
            mt: '1.2rem'
          }}
        />
      </NavigationLink>
    </Box>
  );
};
