import { useQuery, gql } from '@apollo/client';

const PRODUCT_DETAIL_FIELDS = gql`
  fragment productDetailFields on BaseProduct {
    id
    name
    description {
      text
      html
    }
    tags {
      id
      name
    }
    producer {
      id
      name
    }
    orderTypes
    pricing {
      price
      priceBeforeSale
    }
    taxGroup {
      taxRate
      label
      id
    }
    producer {
      city
      description {
        text
      }
      id
      name
      websiteUrl
      category
    }
    sizes {
      sizes {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
        inventory {
          ...inventoryDetailFields
        }
      }
    }

    ... on Product {
      inventory {
        ...inventoryDetailFields
      }
      nutritionDetails {
        ...nutritionDetailsFields
      }
    }
    ... on ProductWithVariants {
      variants {
        total
        variants {
          ...variantDetailFields
        }
      }
    }
  }

  fragment nutritionDetailsFields on NutritionDetails {
    ingredients {
      text
      html
    }
    allergens
  }

  fragment variantDetailFields on ProductVariant {
    id
    name
    tags {
      id
      name
    }
    pricing {
      price
      priceBeforeSale
    }
    sizes {
      sizes {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
        inventory {
          ...inventoryDetailFields
        }
      }
    }
    inventory {
      ...inventoryDetailFields
    }
    nutritionDetails {
      ...nutritionDetailsFields
    }
  }

  fragment inventoryDetailFields on Inventory {
    id
    status
    quantity
    ... on ProductInventory {
      product {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
      }
      size {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
        # Needed for caching
        inventory {
          id
        }
      }
      variant {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
      }
    }
    ... on BoxInventory {
      box {
        id
        name
        boxPricing: pricing {
          price
          priceBeforeSale
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  ${PRODUCT_DETAIL_FIELDS}
  query getProduct($input: ProductFilter!) {
    product(filter: $input) {
      product {
        ...productDetailFields
      }
    }
  }
`;

export function useProduct(id, options) {
  return useQuery(GET_PRODUCT, { variables: { input: { id } }, ...options });
}
