import { merge } from 'theme-ui';
import { CartTheme } from 'gatsby-theme-thepuzzlers-cart';
import { theme as piecesTheme } from '@thepuzzlers/pieces';
// client imports
import { fontFamilies } from './fonts/fontFamilies';
import { typography } from './typography';
import { colors } from './colors';
import { buttons, links, cards } from './elements';
import { shadows, radii, borders } from './styles';
import { forms } from './forms';

const theme = merge(CartTheme, {
  // pieces styles
  breakpoints: piecesTheme.breakpoints,
  measure: piecesTheme.measure,
  grids: piecesTheme.grids,
  // local styles
  fonts: fontFamilies,
  typography,
  colors,
  buttons,
  links,
  cards,
  shadows,
  radii,
  forms,
  borders,
  styles: {
    root: {
      fontFamily: 'body.normal',
      color: 'text',
      bg: 'background',

      // Scroll bar styling
      /* width */
      '*': {
        // CSS reset
        border: 0,

        // FireFox : firefox only allow styling this two properties of scrollbar
        scrollbarColor: '#1E1E1E #FFFBF2',
        scrollbarWidth: 'thin',

        // Webkit
        '::-webkit-scrollbar': {
          width: '3px'
        },

        /* Track */
        '::-webkit-scrollbar-track': {
          background: 'background'
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
          background: 'primary'
        },

        /* Handle on hover */
        '::-webkit-scrollbar-thumb:hover': {
          background: 'blue'
        }
      }
    }
  }
});

export default theme;
