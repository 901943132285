const transitionDefaults = {
  type: 'tween',
  duration: 0.8,
  // ease: 'easeOut',
  ease: [0.87, 0, 0.13, 1]
};

const staggerChildrenTransition = {
  ...transitionDefaults,
  staggerChildren: 0.1
};

// Framer motion value
export const navButtonLineAnimation = {
  initial: {
    rotate: 0
  },
  animate: (custom) => ({
    transformOrigin: 'left center',
    rotate: custom
  })
};

export const navTextButtonAnimation = {
  initial: {
    opacity: 1
  },
  animate: {
    opacity: 0
  }
};

// Content Wrapper Animation

export const revealAnimation = {
  initial: {
    height: 0,
    transition: transitionDefaults
  },
  animate: {
    height: '100%',
    transition: transitionDefaults
  }
};

// Links and Contact Aimation
export const linksAndContactAnimation = {
  initial: {
    transition: staggerChildrenTransition
  },
  animate: {
    transition: staggerChildrenTransition
  },
  exit: {
    transition: {
      staggerChildren: 0.03,
      staggerDirection: -1 // reverse the animation
    }
  }
};

export const slideUpAnimation = {
  initial: {
    opacity: 0,
    y: '7.4rem',
    transition: { ...transitionDefaults }
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { ...transitionDefaults, ease: 'easeOut', duration: 0.5 }
  },
  exit: {
    opacity: 0,
    y: '9.2rem',
    transition: transitionDefaults
  }
};

// Shrinking Navigation baR
export const navigationShrinkAnimation = () => {
  const onScrollAnimation = () => {
    const navigationBar = document.querySelector('.navigation-bar');
    const navigationLine = document.querySelector('.navigation-line');
    const navigationLogo = document.querySelector('.navigation-logo');

    const defaultTransition = 'all 0.5s ease';
    navigationBar.style.transition = defaultTransition;
    navigationLine.style.transition = defaultTransition;
    navigationLogo.style.transition = defaultTransition;
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      // Shrinking start from tablet landscape
      if (window !== undefined && window.innerWidth > 1000) {
        navigationBar.style.paddingTop = '1rem';
        navigationBar.style.paddingBottom = '1rem';
        navigationLogo.style.width = '83%';
      }
      navigationLine.style.opacity = 1;
    } else {
      navigationBar.style.paddingTop = null;
      navigationBar.style.paddingBottom = null;
      navigationLine.style.opacity = null;
      navigationLogo.style.width = null;
    }
  };
  if (window) {
    window.addEventListener('scroll', onScrollAnimation);
    return () => {
      window.removeEventListener('scroll', onScrollAnimation);
    };
  }
};

// Page load reveal animation

export const revealNavigation = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 1.2,
      duration: 1,
      ease: 'easeIn'
    }
  }
};

export const quickRevealNavigation = {
  initial: {
    opacity: 1
  },
  animate: {
    opacity: 1
  }
};

export const circleHoverVariant = {
  initial: {
    scale: 0
  },
  hover: {
    scale: 0.7,
    transition: {
      duration: 0.2
    }
  }
};
