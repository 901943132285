import { usePartners } from 'boxenStopHooks/usePartners';

const regroupPartnerBasedCategory = (apiData, categoriesGroup) => {
  return categoriesGroup.map((category) => {
    return {
      category: category.title,
      partners: apiData?.filter((data) =>
        category.categories.includes(data.category)
      )
    };
  });
};

// Helper Functions
export const usePartnersGroupedByCategory = (categoriesGroup) => {
  const data = usePartners();
  return regroupPartnerBasedCategory(data, categoriesGroup);
};
