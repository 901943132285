import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { IllustrationHeadline, Spacer } from 'components';
import {
  ProductCategorySkeleton,
  PartnerWithProducts,
  useShopPageData
} from 'sections/shopPage';

// Hooks
import {
  useProductCategories,
  useKisProductViewPageData
} from 'boxenStopHooks';

export const GiftBaskets = () => {
  // Static Data
  const { product } = useShopPageData();
  const { product_card } = useKisProductViewPageData();
  const { partner_prefix, detail_text } = product_card;

  // Dynamic Data from API
  const { data: productCategoriesData, loading } = useProductCategories();

  // Only displays gift category
  function getBaskets() {
    if (productCategoriesData?.productCategories) {
      return productCategoriesData.productCategories.find(
        (basket) => basket.id === 'claqbf69z523892crvnex6mxjt'
      );
    }
  }

  const basketsData = getBaskets();

  return loading ? (
    <ProductCategorySkeleton cardBackground={product.categories[0].bgColor} />
  ) : (
    <>
      {basketsData.subCategories.map((subCategory, index) => (
        <Section key={subCategory.name}>
          <IllustrationHeadline
            categoryName={subCategory.name}
            image={subCategory.coverImage.url} // basketsData.coverImage?.url
            bgColor={product.categories[index].bgColor}
          />
          <PartnerWithProducts
            data={{
              partner: {
                name: 'PUR Manufaktur'
              },
              items: subCategory.items.items
            }}
            itemType={subCategory.itemType}
            partnerPrefix={partner_prefix}
            detailText={detail_text}
          />
          {index !== basketsData.subCategories.length - 1 && (
            <Spacer
              sx={{
                gridColumn: [
                  'span 12',
                  'span 12',
                  'span 24',
                  'span 24',
                  'span 24',
                  'span 24'
                ]
              }}
              height={[
                '18rem',
                '15.7rem',
                '15.8rem',
                '18rem',
                '31.2rem',
                '21.1rem'
              ]}
            />
          )}
        </Section>
      ))}
    </>
  );
};
