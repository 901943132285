import React from 'react';

// External Components
import { Button, Box } from '@thepuzzlers/pieces';

// Animation
import { navButtonLineAnimation, navTextButtonAnimation } from './animation';

export const NavButton = ({ text, onClick, isOnOverlay }) => {
  return (
    <Button
      initial="initial"
      animate="animate"
      exit="initial"
      onClick={onClick}
      variant="clear"
      sx={{
        gridColumn: ['8/13', '10/13', '21/25', null, null, null],

        alignSelf: 'center',
        justifySelf: 'end',
        lineHeight: 1,
        fontSize: ['1.3rem', '1.5rem', '1.5rem', null, null, null],
        letterSpacing: '0.02em',
        display: [
          'inline-block',
          'inline-block',
          'inline-block',
          'none',
          'none',
          'none'
        ]
      }}>
      <NavButtonLine
        variants={isOnOverlay && navButtonLineAnimation}
        custom={'23.8deg'}
      />
      <Box
        as="span"
        variants={isOnOverlay && navTextButtonAnimation}
        sx={{
          py: ['0.8rem', '1rem', '1rem'],
          display: 'block'
        }}>
        {text}
      </Box>
      <NavButtonLine
        variants={isOnOverlay && navButtonLineAnimation}
        custom={'-23.8deg'}
      />
    </Button>
  );
};

const NavButtonLine = ({ ...props }) => {
  return (
    <Box
      as="span"
      sx={{
        borderTop: ({ colors }) => `solid ${colors.primary}`,
        borderWidth: 'clamp(2px, 0.2rem, 3px )',
        width: '100%',
        display: 'block'
      }}
      {...props}
    />
  );
};
