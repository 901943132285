// Framer motion reveal text from hidden

export const revealText = {
  initial: {
    opacity: 0,
    y: 40
  },
  animate: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      ease: 'anticipate',
      delay: custom,
      duration: 1.6
    }
  })
};
