import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query MyQuery {
      data: allKisLandingPagePage {
        nodes {
          entry {
            about {
              description
              headline {
                html
              }
              link_label
              link_to
              menu_link_label
              menu_link_to
            }
            blogs {
              headline {
                html
              }
              lead
            }
            boxes {
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_caption_description
              image_caption_label
              image_link_label
              lead
              image_link_to
              steps {
                description
              }
            }
            header {
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            jordan {
              food_count
              food_count_label
              headline {
                html
              }
              lead
            }
            partner {
              description
              headline {
                html
              }
              lead
              partner_link_label
              partner_link_to
            }
            product {
              description
              headline {
                html
              }
              lead
              link_one_label
              link_one_to
              link_two_label
              link_two_to
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
