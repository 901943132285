import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PulsingSkeletonBox = ({ index, sx, ...props }) => {
  return (
    <Box
      animate={{
        opacity: 0.5
      }}
      transition={{
        duration: 0.6,
        repeat: Infinity,
        ease: 'linear',
        repeatType: 'reverse',
        delay: 0.1 * index
      }}
      sx={sx}
      {...props}
    />
  );
};
