import { graphql, useStaticQuery } from 'gatsby';

export const useFooterData = () => {
  const data = useStaticQuery(graphql`
    query {
      footerDataJson {
        headline
        phone {
          label
          to
        }
        pageLinksTitle
        contact {
          title
          description
        }
        logoLinks {
          purLink
          boxenstopLink
          puzzlersLink
        }
      }
    }
  `);

  return data.footerDataJson;
};
