import { useQuery, gql } from '@apollo/client';

export const PRODUCT_FIELDS = gql`
  fragment boxenstopProductFields on BaseProduct {
    id
    name
    description {
      text
      html
    }
    shortDescription
    tags {
      id
      name
    }
    producer {
      id
      name
    }
    orderTypes
    pricing {
      price
      priceBeforeSale
    }
    sizes {
      sizes {
        id
        name
        pricing {
          price
          priceBeforeSale
        }
      }
    }
    ... on ProductWithVariants {
      variants {
        total
        variants {
          ...boxenstopVariantFields
        }
      }
    }
  }

  fragment boxenstopVariantFields on ProductVariant {
    id
    name
    tags {
      id
      name
    }
    pricing {
      price
      priceBeforeSale
    }
  }
`;

export const BOX_FIELDS = gql`
  fragment boxFields on BaseBox {
    id
    type
    name
    shortDescription
    description {
      text
      html
    }
    createdAt
    boxPricing: pricing {
      price
      priceBeforeSale
    }
    tags {
      id
      name
    }
    orderTypes
    numberOfPersons
    products {
      products {
        id
        name
        description {
          text
        }
      }
    }
  }
`;

export const CATEGORY_FIELDS = gql`
  ${BOX_FIELDS}
  ${PRODUCT_FIELDS}
  fragment categoryFields on ProductCategory {
    id
    name
    coverImage {
      url
    }
    ... on CategoryWithSubCategories {
      subCategories {
        coverImage {
          url
        }
        id
        name
        itemType
        items {
          total
          items {
            ... on BaseProduct {
              ...boxenstopProductFields
            }
            ... on BaseBox {
              ...boxFields
            }
          }
        }
      }
    }
    ... on CategoryWithProducts {
      itemType
      items {
        total
        items {
          ... on BaseProduct {
            ...boxenstopProductFields
          }
          ... on BaseBox {
            ...boxFields
          }
        }
      }
    }
  }
`;
export const GET_PRODUCT_CATEGORIES = gql`
  ${CATEGORY_FIELDS}
  query getProductCategories {
    productCategories {
      ...categoryFields
    }
  }
`;

export function useProductCategories(options) {
  return useQuery(GET_PRODUCT_CATEGORIES, { ...options });
}
