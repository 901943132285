// this helper function remove th p tag and change strong tag to be span tag
export const convertApiHtmlText = (htmlText) => {
  // replace the </p><p> tag with <br/>
  const replacedPasByPTag = htmlText.replace(/\<\/p\>\<p\>/g, '<br/>');

  // remove the p tag
  const removedFrontPTag = replacedPasByPTag.replace(/\<p\>/g, '');
  const removedBackPTag = removedFrontPTag.replace(/\<\/p\>/g, '');

  // change strong tag to be span tag
  const switchedStrongTag = removedBackPTag.replace(/strong/g, 'span');

  // change the b tag to be span tag
  const switchedBTag = switchedStrongTag.replace(/<b>/g, '<span>');
  const switchedEndBTag = switchedBTag.replace(/<\/b>/g, '</span>');

  return switchedEndBTag;
};
