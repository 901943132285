const transition = {
  type: 'tween',
  duration: 0.5
};

export const slideLeftAnimation = {
  initial: {
    x: '100%'
  },
  animate: {
    x: 0,
    transition
  },
  exit: {
    x: '100%',
    transition: {
      delay: 0.2,
      ...transition
    }
  }
};
