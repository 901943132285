import { gsap } from 'gsap';

export const moveOnMouseAnimation = (element) => {
  const handleMouseMove = (e) => {
    gsap.to(element, {
      left: e.clientX - element.offsetWidth / 2 + 'px',
      top: e.clientY - element.offsetHeight / 2 + 'px',
      duration: 0,
      ease: 'linear'
    });
  };
  window.addEventListener('mousemove', handleMouseMove);

  return () => {
    window.removeEventListener('mousemove', handleMouseMove);
  };
};

export const scaleUpAnimation = {
  initial: {
    scale: 0
  },
  animate: {
    scale: 1,
    zIndex: 1
  }
};
