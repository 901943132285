export const fontFamilies = {
  body: {
    normal: 'cera-round-pro-normal-regular, sans-serif',
    medium: 'cera-round-pro-normal-medium, sans-serif',
    bold: 'cera-round-pro-normal-bold, sans-serif'
  },
  primary: {
    normal: 'quincy-cf-normal-regular, sans-serif',
    italic: 'quincy-cf-normal-light-italic, sans-serif',
    mediumStyled: 'quincy_cf_medium_with_stylistic-set',
    bold: 'quincy-cf-normal-bold, sans-serif',
    extraBold: 'quincy-cf-normal-extrabold, sans-serif'
  }
};
