import { graphql, useStaticQuery } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisOpeningHoursData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisOpeningHours {
        nodes {
          entry {
            headlinebistro
            headlineshop
            bistro {
              html
            }
            shop {
              html
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
