export const typographyConfig = {
  // h3, h4 and paragraph are left in for the legal pages
  h3: {
    fontSizes: [33.6, 28.8, 37.6, 37.6, 44],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  h4: {
    fontSizes: [19.5, 24.5, 25.5, 18, 26.5, 28.5],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  paragraph: {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1.75
  }
};

export const checkoutTypography = {
  largeHeading: {
    fontSizes: [24.33, 37.77, 38.29, 22.56, 37.12, 42.51],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  // default heading used for sections, cards,...
  heading: {
    fontSizes: [19.65, 21.58, 22.97, 22.56, 22.27, 23.73],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  smallHeading: {
    fontFamily: 'primary',
    fontSizes: [16.84, 19.78, 17.23, 18.8, 18.56, 19.77],
    lineHeight: 1
  },
  paragraph: {
    fontSizes: [14.04, 13.49, 16.27, 13.16, 15.78, 17],
    fontFamily: 'body',
    lineHeight: 1.35
  },
  label: {
    fontSizes: [13.1, 13.49, 15.31, 12.22, 12.06, 12.85],
    fontFamily: 'body',
    lineHeight: 1.25
  },
  // also used as placeholder
  inputText: {
    fontSizes: [14.04, 13.49, 16.27, 13.16, 15.78, 14.83],
    fontFamily: 'body',
    lineHeight: 1.35
  }
};
