import { useStaticQuery, graphql } from 'gatsby';

export const useNavLinksData = () => {
  const data = useStaticQuery(graphql`
    query {
      navLinksDataJson {
        pageLinks {
          label
          to
        }
        footerOnlyLinks {
          label
          to
        }
      }
    }
  `);
  return data.navLinksDataJson;
};
