import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { Vector, AspectRatioImageContainer } from 'components';

// Assets
import decoration from 'assets/giftPage/svg/yellow-crown.svg';

// Helper function
import { convertApiHtmlText } from 'boxenStopHooks';

export const Header = ({ data: { headline, description, image } }) => {
  return (
    // Markup
    <Section id="gift__header">
      <ImageWrapper data={image} />
      <Box
        sx={{
          gridColumn: [
            '1/span 11',
            '1/13',
            '1/span 17',
            '12/span 12',
            '13/25',
            '13/span 10'
          ],
          gridRow: 2
        }}>
        <Headline data={headline} />
        <Description data={description} />
      </Box>
    </Section>
  );
};

// Elements

const ImageWrapper = ({ data: { image, alt } }) => (
  <Box
    className="gift__header__image-wrapper"
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '5/25',
        '1/span 10',
        '1/span 10',
        '2/span 9'
      ],
      gridRow: [1, 1, 1, '1/span 3', '1/span 3', '1/span 3'],
      position: 'relative'
    }}>
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      aspectRatio={[1 / 1.3, 1 / 0.8, 1 / 0.68, 1 / 1, 1 / 1, 1 / 0.88]}
      sx={{
        width: '100%'
      }}
    />
    <Vector
      src={decoration}
      sx={{
        position: 'absolute',
        top: ['4rem', '-0.5rem', '-0.5rem', '0.5rem', '2.4rem', 0],
        right: ['7.5rem', '16.8rem', '20.3rem', '6.5rem', '11.2rem', '-8rem'],
        width: ['12rem', '11.6rem', '14rem', '9.5rem', '13.4rem', '19rem']
      }}
    />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h1"
    dangerouslySetInnerHTML={{ __html: convertApiHtmlText(data.html) }}
    sx={{
      width: ['100%', '100%', '100%', '100%', '100%', '100%'],
      fontFamily: 'primary.italic',
      fontSize: ['4rem', '4.8rem', '4.8rem', '3.2rem', '4.8rem', '4.8rem'],

      lineHeight: 1.1,
      mt: ['4.7rem', '4rem', '6rem', 0, 0, 0],
      '& > span': {
        fontFamily: 'primary.mediumStyled'
      }
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html:
        'Dieses Jahr wird es einfacher gute Geschenke zu finden. Denn wir nehmen Ihnen die Suche ab!'
    }}
    sx={{
      width: ['100%', '90%', '100%', '100%', '100%', '100%'],
      fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.6rem', '1.8rem'],
      lineHeight: [1.6, 1.6, 1.6, 1.5, 1.5, 1.5],
      mt: ['2rem', '3.3rem', '4rem', '2rem', '2rem', '2rem']
    }}
  />
);
