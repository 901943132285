import React from 'react';

// External Components
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';
import { useShoppingCart } from 'gatsby-theme-thepuzzlers-cart';
import { useLocation } from '@reach/router';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Constants
import { itemTypes } from 'constants/itemTypes';

// Local Components
import {
  HorizontalLine,
  CardButton,
  PointerCircle,
  IllustrationHeadline,
  Vector,
  Spacer
} from 'components';

// Helper function
import { getTags, getPrice } from './helper';
import { useFormatPrice } from 'hooks';

// Assets
import checkIndicator from 'assets/svg/check-indicator.svg';

export const ProductCategory = ({
  data: { name, coverImage, itemType, itemsToDisplay },
  productPageData: { partner_prefix, detail_text },
  categoryColor
}) => {
  return (
    itemsToDisplay.length !== 0 && (
      <>
        <Section>
          <IllustrationHeadline
            categoryName={name}
            image={coverImage?.url}
            bgColor={categoryColor}
          />
          {itemsToDisplay.map((partnerWithItems, index) => (
            <PartnerWithProducts
              key={index}
              data={partnerWithItems}
              itemType={itemType}
              partnerPrefix={partner_prefix}
              detailText={detail_text}
            />
          ))}
        </Section>
        <Spacer
          height={[
            '18rem',
            '15.7rem',
            '15.8rem',
            '18rem',
            '31.2rem',
            '21.1rem'
          ]}
        />
      </>
    )
  );
};

export const PartnerWithProducts = ({
  data: { partner, items },
  itemType,
  partnerPrefix,
  detailText
}) => {
  return (
    <Box
      className="partner-with-products"
      sx={{
        gridColumn: [
          '1/13',
          '2/ span 10',
          '4 / span 18',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        mt: ['3.2rem', '4rem', '4rem', '3.2rem', '4rem', '4rem'],
        '& ~ .partner-with-products': {
          mt: ['9.8rem', '12.2rem', '17.2rem', '9.8rem', '11.6rem', '11.7rem']
        }
      }}>
      <HorizontalLine
        isBold
        sx={{ display: ['none', 'none', 'block', 'none', 'block', 'block'] }}
      />
      <Box
        sx={{
          px: [null, null, null, null, null, '5.4rem'],
          mt: ['3.2rem', '4rem', '4rem', '3.2rem', '3.7rem', '4rem'],
          display: [null, null, null, 'grid', 'grid', 'grid'],
          gridTemplateColumns: [
            null,
            null,
            null,
            '1fr 45.6rem',
            '1fr 58rem',
            '1fr 68.6rem'
          ],
          columnGap: [null, null, null, '4rem', '5rem', '7rem']
        }}>
        <PartnerName name={partner.name} partnerPrefix={partnerPrefix} />
        <PartnerProducts
          items={items}
          itemType={itemType}
          detailText={detailText}
        />
      </Box>
    </Box>
  );
};

const PartnerName = ({ name, partnerPrefix }) => {
  return (
    <Heading
      as="h2"
      sx={{
        fontFamily: 'body.bold',
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.6rem', '1.8rem'],
        lineHeight: 1.1,
        '& > span': {
          fontFamily: 'body.normal'
        }
      }}>
      <Heading as="span">{name && `${partnerPrefix}`} </Heading>
      {name}
    </Heading>
  );
};

const PartnerProducts = ({ items, itemType, detailText }) => {
  return (
    <Box className="products-list">
      {items.map((item, index) => {
        return (
          <ProductCard
            key={index}
            item={item}
            itemType={itemType}
            detailText={detailText}
          />
        );
      })}
    </Box>
  );
};

const ProductCard = ({ item, itemType, detailText }) => {
  const boxItems = item?.products?.products;

  const itemTypeRoutes = itemType === itemTypes.BOX ? 'box' : 'product';

  return (
    <>
      <NavigationLink
        className="product-card"
        to={`/shop/${itemTypeRoutes}/${item.id}`}
        sx={{
          position: 'relative',
          '& a': {
            cursor: [null, null, null, null, null, 'none']
          },
          mt: ['3.2rem', '3.2rem', '4.5rem', 0, 0, 0],
          ':not(:first-of-type)': {
            mt: ['7.2rem', '7.2rem', '8rem', '4.8rem', '4.8rem', '8rem']
          },
          display: 'block'
        }}>
        <Box
          initial="initial"
          whileHover="animate"
          className="product-card-wrapper">
          <ProductHeadline
            item={item}
            itemType={itemType}
            detailText={detailText}
          />
          {item?.variants?.variants && (
            <ProductVariants variants={item.variants.variants} />
          )}
          {itemType === 'BOX' && <BoxIngredients items={boxItems} />}
          <CardButton
            text={detailText}
            sx={{
              mt: ['2.4rem', '2.4rem', '3.2rem', 0, 0, 0],
              display: ['flex', 'flex', 'flex', 'none', 'none', 'none'],
              width: '100%',
              textTransform: 'uppercase'
            }}
          />
          <PointerCircle text={detailText} />
        </Box>
      </NavigationLink>
    </>
  );
};

const ProductHeadline = ({ item, itemType, detailText }) => {
  const { id, name, sizes, tags, boxPricing } = item;
  const formatPrice = useFormatPrice();

  const { items } = useShoppingCart();

  const isInCart = items.find((item) => item.name === name);
  const price = getPrice(item, undefined, itemType, undefined);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          null,
          null,
          null,
          '1fr 13.7rem',
          '1fr 13.7rem',
          '1fr 14.6rem'
        ]
      }}>
      <Box
        sx={{
          width: [null, null, null, '28.2rem', '37rem', '41.6rem']
        }}>
        <ProductName name={name} isInCart={isInCart} />
        {item.shortDescription && <DetailText text={item.shortDescription} />}
        <Paragraph
          sx={{
            fontFamily: 'body.medium',
            fontSize: [
              '1.5rem',
              '1.6rem',
              '1.8rem',
              '1.5rem',
              '1.6rem',
              '1.6rem'
            ],
            lineHeight: 1.25,
            mt: '0.6rem'
          }}>
          {price
            ? formatPrice(price)
            : formatPrice(item.variants?.variants[0].pricing.price)}{' '}
          {getTags(tags)}
        </Paragraph>
      </Box>
      <CardButton
        initial={undefined}
        whileHover={undefined}
        text={detailText}
        sx={{
          alignSelf: 'center',
          // at the last breakpoints the last item is displayed as none, so we have to make the last display to flex
          display: ['none', 'none', 'none', 'flex', 'flex', 'flex'],
          // styles for the text
          '& > span:first-of-type': {
            display: [null, null, null, null, null, 'none']
          }
        }}
      />
    </Box>
  );
};

const ProductName = ({ name, isInCart }) => (
  <Box sx={{ position: 'relative' }}>
    <Heading
      as="h3"
      sx={{
        fontFamily: 'primary.extraBold',
        lineHeight: 1.25,
        fontSize: ['2.2rem', '2.4rem', '2.8rem', '2rem', '2.2rem', '2.4rem'],
        width: ['90%', '100%', '100%', '100%', '100%', '100%']
      }}>
      {name}?!
    </Heading>
    {isInCart && (
      <Vector
        src={checkIndicator}
        // this alt-text should be translated to germany
        alt="added"
        sx={{
          position: 'absolute',
          top: '50%',
          right: [0, 'unset', 'unset', 'unset', 'unset', 'unset'],
          left: ['unset', '-4.4rem', '-5rem', '-2.9rem', '-3.8rem', '-3.8rem'],
          transform: 'translateY(-50%)',
          width: ['2.2rem', '2.8rem', '3rem', '2rem', '2.4rem', '2.4rem']
        }}
      />
    )}
  </Box>
);

const ProductVariants = ({ variants }) => {
  const variantAsString = variants.reduce((acc, cur, index) => {
    if (index === 0) return cur.name;
    return (acc = acc + `, ${cur.name}`);
  }, '');

  return (
    <DetailWrapper>
      <DetailText text={variantAsString} />
    </DetailWrapper>
  );
};

const BoxIngredients = ({ items }) => (
  <DetailWrapper
    sx={{
      columnCount: [null, null, null, 2, 2, 2]
    }}>
    {items.map((item) => (
      <DetailText
        key={item.name}
        text={item.name}
        sx={{
          // the inline block and the width 100% fix the issue with column count to make it fill the first column first
          display: [
            null,
            null,
            null,
            'inline-block',
            'inline-block',
            'inline-block'
          ],
          width: '100%'
        }}
      />
    ))}
  </DetailWrapper>
);

// Reusable Components

const DetailWrapper = ({ children, sx }) => (
  <Box
    sx={{
      mt: ['2.4rem', '2.4rem', '3.2rem', '2.5rem', '1.6rem', '1.6rem'],
      ...sx
    }}>
    {children}
  </Box>
);

const DetailTitle = ({ title }) => (
  <Heading
    as="h3"
    sx={{
      lineHeight: 1.5,
      fontFamily: 'body.bold',
      letterSpacing: '0.02em',
      fontSize: ['1.3rem', '1.4rem', '1.5rem', '1.4rem', '1.4rem', '1.4rem'],
      textTransform: 'uppercase'
    }}>
    {title}
  </Heading>
);

const DetailText = ({ text, sx }) => (
  <Paragraph
    sx={{
      fontFamily: 'body.regular',
      lineHeight: 1.5,
      fontSize: ['1.5rem', '1.5rem', '1.8rem', '1.5rem', '1.6rem', '1.6rem'],
      mt: '0.2rem',
      width: [null, null, '47.5rem', '28.2rem', '37rem', '41.6rem'],
      ...sx
    }}>
    {text}
  </Paragraph>
);
