//https://janessagarrow.com/blog/gatsby-framer-motion-page-transitions/

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps
}) => {
  // 0.3 for waiting for exit animation finished
  // we use this method because
  // gatsby plugin transition link crashed with the animation in the overlay,
  // gatsby plugin transition also use delay to waiting for it's animation,
  const TRANSITION_DELAY = 0.3 * 1000;

  const lastPosition = getSavedScrollPosition(location, location.key);

  // if it's a "normal" route, (click the links)
  if (lastPosition[1] === 0) {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  } else {
    // if we used the browser's forwards or back button
    // const lastPosition = getSavedScrollPosition(location, location.key);

    const isLastPositionShopDetailPage =
      prevRouterProps?.location?.pathname?.includes('/product/') ||
      prevRouterProps?.location?.pathname?.includes('/box/'); // if prevLocation is shop detail page

    const savedPosition = [
      lastPosition[0],
      lastPosition[1] + (isLastPositionShopDetailPage ? window.innerHeight : 0)
    ];

    window.scrollTo(...savedPosition);
  }
  return false;
};
