import React from 'react';
import { NavigationLink, SEO } from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@gatsbyjs/reach-router';

// External Components
import {
  GridWrapper,
  Box,
  Heading,
  Paragraph,
  fullWidthMinusMargins,
  FlexWrapper
} from '@thepuzzlers/pieces';
import {
  useShoppingCart,
  useProductSelection,
  useFormatPrice
} from 'gatsby-theme-thepuzzlers-cart';
import { navigate } from '@reach/router';

// Local Components
import {
  HorizontalLine,
  SecondaryButton,
  Vector,
  VerticalLine,
  WideCloseButton,
  PlusButton,
  MinusButton,
  Spacer,
  PulsingSkeletonBox
} from 'components';
import {
  TransitionCover,
  mainContentAnimation,
  actionBarAnimation
} from 'sections/shopPage/productCategory/components';
import { useGiftPageData } from 'sections/giftPage';

// Hooks
import { useToastContext } from 'components';
import { useNavigation } from 'context/NavigationContext';

// Utils
import {
  getPrice,
  itemExtractor
} from 'sections/shopPage/productCategory/helper';
import { convertApiHtmlText } from 'boxenStopHooks/helper';

// External Data
import { useKisProductViewPageData } from 'boxenStopHooks';

// Assets
import bioTag from 'assets/svg/bioTag.svg';

// Constants
import { itemTypes } from 'constants/itemTypes';
import { orderTypes } from 'constants/orderTypes';

// Information
const allergenTranslations = {
  GLUTEN: 'Glutenhaltiges Getreide',
  CRUSTACEANS: 'Krebstiere',
  EGGS: 'Eier',
  FISH: 'Fisch',
  PEANUTS: 'Erdnüsse',
  SOY: 'Sojabohnen',
  MILK: 'Milch',
  NUTS: 'Nüsse & Schalenfrüchte',
  CELERY: 'Sellerie',
  MUSTARD: 'Senf',
  SESAME_SEEDS: 'Sesamsamen',
  SULPHUR_DIOXIDE_AND_SULPHITES: 'Sulfite',
  LUPIN: 'Lupinen',
  MOLLUSCS: 'Weichtiere'
};

export const ProductDetailView = ({ itemType, box, productData }) => {
  const { pathname, key: locationKey } = useLocation();

  const { handleOpenToast, handleCloseToast } = useToastContext();
  const { handleOpenCart } = useNavigation();

  const { product_detail } = useKisProductViewPageData();
  const { text_button, description } = product_detail.notification[0];

  const product = productData?.product?.product;

  const item = itemType === itemTypes.BOX ? box : product || {};

  const navigateBack = () => {
    // if useLocation key is 'initial' it means that we loaded the web for the first time
    // if it's a number it means we have navigated through the web pages
    return locationKey === 'initial' ? navigate('/shop/') : navigate(-1);
  };

  const handleCloseOverlayAndOpenToast = () => {
    navigateBack();
    handleOpenToast({
      text: `<span>${item?.name}</span> ${description}`,
      textButton: text_button,
      // on button click should open cart overlay
      onButtonClick: handleOpenCart,
      closeDuration: 8000
    });
  };

  React.useEffect(() => {
    // if toast still open, close it first when opening the product detail view
    handleCloseToast();
  }, []);
  return (
    <>
      {item?.name ? (
        <SEO
          title={item?.name}
          description={item?.description?.text}
          shortTitle={item?.name}
          shortDescription={
            item?.shortDescription
              ? item?.shortDescription
              : item?.description?.text
          }
          url={'https://www.einfachpurgeniessen.de' + pathname}
        />
      ) : (
        <SEO
          title={
            itemType === itemTypes.BOX ? 'Kulinarische Box' : 'PUR Produkt'
          }
          description={item?.description?.text}
          shortTitle={
            itemType === itemTypes.BOX ? 'Kulinarische Box' : 'PUR Produkt'
          }
          shortDescription={
            item?.shortDescription
              ? item?.shortDescription
              : item?.description?.text
          }
          url={'https://www.einfachpurgeniessen.de' + pathname}
        />
      )}

      {item && (
        <Content
          item={item}
          handleClose={navigateBack}
          handleCloseOverlayAndOpenToast={handleCloseOverlayAndOpenToast}
          itemType={itemType}
          boxItems={box?.products ? box.products.products : null}
        />
      )}
    </>
  );
};

const Content = ({
  item,
  handleClose,
  handleCloseOverlayAndOpenToast,
  itemType,
  boxItems
}) => {
  return (
    <GridWrapper
      className="product-detail-overlay"
      variants={mainContentAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        bg: 'background',
        // animation values
        minHeight: '100vh',
        overflowY: ['auto', 'auto', 'auto', 'auto', 'hidden', 'hidden'],
        // this overflowX also fixed the issue of fullwidthMinusMargins, which left white space at the end
        overflowX: 'hidden'
      }}>
      <ContentWrapper
        item={item}
        handleCloseOverlay={handleClose}
        handleCloseOverlayAndOpenToast={handleCloseOverlayAndOpenToast}
        itemType={itemType}
        boxItems={boxItems}
      />
    </GridWrapper>
  );
};

const ContentWrapper = ({
  item,
  handleCloseOverlay,
  handleCloseOverlayAndOpenToast,
  itemType,
  boxItems
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
        gridTemplateColumns: [
          null,
          null,
          null,
          null,
          '1fr 37rem',
          '1fr 46.7rem'
        ],
        alignSelf: ['start', 'start', 'start', 'start', 'stretch', 'stretch'],
        position: 'relative',

        // Animation Value that support the TransitionCover
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
        gridTemplateRows: [
          'auto auto 1fr',
          'auto auto 1fr',
          'auto auto 1fr',
          'auto auto 1fr',
          'auto',
          'auto'
        ]
      }}>
      <ProductDescription
        item={item}
        handleCloseOverlay={handleCloseOverlay}
        boxItems={boxItems}
      />
      <CheckoutCard
        item={item}
        handleCloseOverlay={handleCloseOverlay}
        handleCloseOverlayAndOpenToast={handleCloseOverlayAndOpenToast}
        itemType={itemType}
      />
      {/* Spacer */}
      <BottomSpacer />
      {/* The Transition Cover need to be here and it's parent must set as relative, so the transition cover get the corrcet bttom */}
      <TransitionCover />
      <SeoHiddenLink />
    </Box>
  );
};

const SeoHiddenLink = () => (
  <NavigationLink
    to="/shop/"
    sx={{ color: 'transparent', position: 'absolute' }}>
    Shop
  </NavigationLink>
);

const ProductDescription = ({ item, handleCloseOverlay, boxItems }) => {
  const { getDescription, getTagNamesAsArray } = itemExtractor(item);
  const {
    product_detail: { box_items_title }
  } = useKisProductViewPageData();

  const isBio = getTagNamesAsArray().includes('eu-organic');
  return (
    <Box
      className="product-detail-overlay__product-description"
      sx={{
        px: [null, '4.4rem', '6.2rem', '3rem', '4.2rem', '10.9rem'],
        position: 'relative',
        overflowY: [null, null, null, null, 'auto', 'auto'],
        maxHeight: [null, null, null, null, '100vh', '100vh']
      }}>
      {isBio && (
        <Vector
          src={bioTag}
          alt="Eu-Bio Zertifizierung"
          sx={{
            width: ['4.9rem', '5.8rem', '6.4rem', '4.9rem', '5.6rem', '5.6rem'],
            position: 'absolute',
            top: ['2.7rem', '4.8rem', '4.7rem', '1.5rem', '2.9rem', '1.8rem'],
            left: [null, 0, 0, 0, 'auto', 'auto']
          }}
        />
      )}
      <CloseButton
        onClick={handleCloseOverlay}
        sx={{
          display: ['block', 'block', 'block', 'block', 'none', 'none']
        }}
      />
      <Heading
        as="h1"
        sx={{
          mt: ['12.4rem', '13rem', '14rem', '7rem', '14.4rem', '10.9rem'],
          fontFamily: 'primary.bold',
          lineHeight: 1.1,
          fontSize: ['2.8rem', '3.2rem', '3.8rem', '2.8rem', '3.8rem', '4rem']
        }}>
        {item?.name ? (
          item?.name + '?!'
        ) : (
          <PulsingSkeletonBox
            sx={{
              height: ['10rem', '10rem', '10rem', '5rem', '5rem', '5rem'],
              width: ['100%', '100%', '100%', '40rem', '40rem', '40rem'],
              bg: 'lightGray',
              borderRadius: '3px'
            }}
          />
        )}
      </Heading>

      {getDescription()?.map((description) => {
        return (
          <Paragraph
            key={description}
            dangerouslySetInnerHTML={{
              __html: convertApiHtmlText(description)
            }}
            sx={{
              mt: ['1rem', '1rem', '1.2rem', '0.8rem', '1rem', '1.2rem'],
              lineHeight: 1.35,
              fontSize: ['1.8rem', '2rem', '2rem', '1.8rem', '2rem', '2rem'],
              width: [null, null, '52.6rem', '45.5rem', '45.4rem', '52.3rem'],
              fontFamily: 'primary.normal',
              '& > span': {
                fontFamily: 'primary.bold'
              },
              '& > i': {
                fontFamily: 'primary.italic'
              }
            }}
          />
        );
      })}

      {boxItems && <BoxItemsList title={box_items_title} boxItems={boxItems} />}
      <ContentBottomSpacer />
    </Box>
  );
};

const CheckoutCard = ({
  item,
  handleCloseOverlay,
  handleCloseOverlayAndOpenToast,
  itemType
}) => {
  const {
    sku,
    selectedVariant,
    setSelectedVariant,
    selectedSize,
    sizes = {},
    setSelectedSize
  } = useProductSelection(item);

  const { lastSelectedOrderType } = useShoppingCart();

  const {
    product_detail: { ingredient, allergen, price, persons }
  } = useKisProductViewPageData();
  const formatPrice = useFormatPrice();

  const unformattedPrice = getPrice(item, undefined, itemType, undefined);
  const itemPrice =
    unformattedPrice && unformattedPrice !== true
      ? formatPrice(unformattedPrice)
      : '';

  const nutritionDetails = !item?.variants
    ? item?.nutritionDetails
    : selectedVariant?.nutritionDetails;

  // User select order type option
  const [selectedOrderType, setSelectedOrderType] = React.useState(null);
  const [selectOrderMessage, setSelectOrderMessage] = React.useState(false);

  React.useEffect(() => {
    // set the last selected order type to the order options
    if (lastSelectedOrderType) {
      // check if lastSelcted OrderType is Exist in the order options
      if (item.orderTypes?.includes(lastSelectedOrderType)) {
        setSelectedOrderType(lastSelectedOrderType);
      }
    }
  }, [item.orderTypes]);

  return (
    <Box
      className="product-detail-overlay__checkout-card"
      sx={{
        position: 'relative',
        mr: [
          null,
          null,
          null,
          null,
          `calc(${fullWidthMinusMargins[4]} - 1px)`,
          `calc(${fullWidthMinusMargins[5]} - 1px)`
        ],
        px: [null, '4.4rem', '6.2rem', '3rem', '8.3rem', '10.6rem'],
        overflowX: [null, null, null, null, 'hidden', 'hidden'],
        overflowY: [null, null, null, null, 'auto', 'auto'],
        maxHeight: [null, null, null, null, '100vh', '100vh'],
        borderLeft: [null, null, null, null, '2px solid', '2px solid'],
        borderColor: 'primary'
      }}>
      <CloseButton
        onClick={handleCloseOverlay}
        sx={{
          display: ['none', 'none', 'none', 'none', 'block', 'block']
        }}
      />
      <Box
        sx={{
          mt: ['4rem', '4rem', '6rem', '5.6rem', '14.4rem', '11.5rem']
        }}>
        {itemType !== 'BOX' ? (
          // Product Fields
          <>
            {item?.variants ? (
              <VariantOptions
                item={item}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                selectedSize={selectedSize}
                itemType={itemType}
              />
            ) : (
              <MoreInformation
                title={price}
                description={itemPrice}
                sx={{
                  mt: 0
                }}
              />
            )}
            {item?.sizes && (
              <SizeOptions
                item={item}
                selectedSize={selectedSize}
                sizes={sizes}
                setSelectedSize={setSelectedSize}
              />
            )}
            <OrderOptions
              item={item}
              selectedOrderType={selectedOrderType}
              setSelectedOrderType={setSelectedOrderType}
              selectOrderMessage={selectOrderMessage}
              setSelectOrderMessage={setSelectOrderMessage}
            />
            {nutritionDetails && (
              <NutritionDetails
                ingredient={ingredient}
                allergen={allergen}
                nutritionDetails={nutritionDetails}
              />
            )}
          </>
        ) : (
          <>
            <MoreInformation
              title={price}
              description={itemPrice}
              sx={{
                mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4.6rem']
              }}
            />
            {item.numberOfPersons && (
              <BoxNumberOfPersons
                sx={{
                  mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4.6rem']
                }}
                title={persons}
                numberOfPersons={item?.numberOfPersons}
              />
            )}
            <OrderOptions
              item={item}
              selectedOrderType={selectedOrderType}
              setSelectedOrderType={setSelectedOrderType}
              selectOrderMessage={selectOrderMessage}
              setSelectOrderMessage={setSelectOrderMessage}
            />
          </>
        )}
      </Box>
      <ContentBottomSpacer />

      <ActionBar
        handleCloseOverlayAndOpenToast={handleCloseOverlayAndOpenToast}
        orderType={selectedOrderType}
        sku={sku}
        setSelectOrderMessage={setSelectOrderMessage}
      />
    </Box>
  );
};

const NutritionDetails = ({ ingredient, allergen, nutritionDetails }) => {
  return (
    <>
      {nutritionDetails.ingredients && (
        <MoreInformation
          title={ingredient}
          description={convertApiHtmlText(
            nutritionDetails.ingredients.html.join('')
          )}
          sx={{
            mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4rem']
          }}
        />
      )}
      {nutritionDetails.allergens.length !== 0 && (
        <MoreInformation
          title={allergen}
          description={nutritionDetails.allergens
            .map((allergen) => allergenTranslations[allergen])
            .join(', ')}
        />
      )}
    </>
  );
};

const VariantOptions = ({
  item,
  selectedVariant,
  setSelectedVariant,
  selectedSize,
  itemType
}) => {
  const {
    product_detail: { variant }
  } = useKisProductViewPageData();
  const { getVariants } = itemExtractor(item);
  const formatPrice = useFormatPrice();

  const handleChange = (value) => {
    setSelectedVariant(getVariants().find(({ id }) => id === value));
  };

  return (
    <Box className="product-detail-overlay__variant-options">
      <InformationTitle text={variant} />
      <Box>
        {getVariants().map((variant, index) => {
          const price = getPrice(item, variant, itemType, selectedSize); // handle variant price
          const priceToDisplay = price
            ? price
            : item.sizes?.sizes[0].pricing.price || variant.pricing?.price; // if variant price is undefined handle product price

          return (
            <RadioInputWithLabel
              key={index}
              name="variants-select"
              label={`${variant.name} (${formatPrice(priceToDisplay)})`}
              value={variant.id}
              onChange={() => handleChange(variant.id)}
              selected={variant.id == selectedVariant?.id}
            />
          );
        })}
      </Box>
    </Box>
  );
};

// size options
const SizeOptions = ({ item, selectedSize, sizes = {}, setSelectedSize }) => {
  const {
    product_detail: { size }
  } = useKisProductViewPageData();

  const handleChange = (value) => {
    setSelectedSize(sizes.sizes.find(({ id }) => id === value));
  };

  return (
    <Box
      className="product-detail-overlay__size-options"
      sx={{
        // if there is no variant of item, make the mt of size as if its first element
        mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4.6rem']
      }}>
      <InformationTitle text={size} />
      {sizes.sizes?.map((size, index) => (
        <RadioInputWithLabel
          key={index}
          name="sizes-select"
          label={size.name}
          value={size.id}
          selected={size.id == selectedSize?.id}
          onChange={() => handleChange(size.id)}
        />
      ))}
    </Box>
  );
};

const ActionBar = ({
  handleCloseOverlayAndOpenToast,
  orderType,
  sku,
  setSelectOrderMessage
}) => {
  const {
    product_detail: { checkout }
  } = useKisProductViewPageData();
  const { addItem } = useShoppingCart();

  const [quantity, setQuantity] = React.useState(1);
  const maxOrder = 15;

  const add = () => {
    if (quantity < maxOrder) setQuantity(quantity + 1);
  };
  const subtract = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  if (!sku) return null;

  return (
    <ActionBarWrapper>
      <ActionsVerticalLine
        sx={{
          left: 0,
          display: ['none', 'none', 'block', 'block', 'block', 'block']
        }}
      />
      <HorizontalLine
        isBold
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%'
        }}
      />
      {/* Affecting Element  */}
      <MinusButton onClick={subtract} />
      <Quantity value={quantity} />
      <PlusButton onClick={add} />
      <CheckoutButton
        label={checkout}
        handleClick={(e) => {
          if (!orderType) {
            const scrollDistanceToPutOrderSelectionInCenterOfView =
              document
                .getElementById('order-selection-input')
                .getBoundingClientRect().bottom +
              window.scrollY -
              window.innerHeight / 2;

            // scroll order type selector into view
            window.scrollTo({
              top: scrollDistanceToPutOrderSelectionInCenterOfView,
              left: 0,
              behavior: 'smooth'
            });

            setSelectOrderMessage(true);

            return;
          }

          const formattedSku = {
            ...sku
          };

          // !Important
          // we need to add a property "pricing" to the box, otherwise the shopping cart can not calculate the price correctly
          if (sku.box) {
            formattedSku.box = { ...sku.box };
            formattedSku.box.pricing = sku.box.boxPricing;
          }

          addItem(formattedSku, orderType, quantity);
          handleCloseOverlayAndOpenToast();
        }}
      />
      {/* ================== */}
      <ActionsVerticalLine
        sx={{
          right: 0,
          display: ['none', 'none', 'block', 'block', 'none', 'none']
        }}
      />
    </ActionBarWrapper>
  );
};

const ActionBarWrapper = ({ children }) => (
  // Additional container needed to be able to make the element center at tablet portrait
  <FlexWrapper
    className="product-detail__action-bar"
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      position: ['fixed', 'fixed', 'fixed', 'fixed', 'fixed', 'fixed'],
      // Bottom -1px, fix the issue white space appear on some screen at the bottom of Action Bar
      bottom: '-1px',
      left: [0, 0, 0, 0, 'auto', 'auto'],
      right: [null, null, null, null, 0, 0],
      width: [
        '100%',
        '100%',
        '100%',
        '100%',
        `calc(${fullWidthMinusMargins[4]} * -1 + 37rem)`,
        `calc(${fullWidthMinusMargins[5]} * -1 + 46.7rem)`
      ]
    }}
    // Animation Value
    variants={actionBarAnimation}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          '1fr 17.3rem 1fr',
          '1fr 36.5rem 1fr',
          '1fr 36.5rem 1fr',
          '1fr 1fr 1fr 37.7rem',
          '1fr 1fr 1fr',
          '1fr 1fr 1fr'
        ],
        width: ['100%', '100%', '70rem', '68.8rem', '100%', '100%'],
        position: 'relative',
        bg: 'background'
      }}>
      {children}
    </Box>
  </FlexWrapper>
);

const Quantity = ({ value }) => {
  const formattedValue = value.toString().length < 2 ? `0${value}` : value;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        py: ['1.3rem', '1.3rem', '0.8rem', '1rem', '1rem', '1rem']
      }}>
      <ActionsVerticalLine sx={{ left: 0 }} />
      <Paragraph
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.1,
          fontSize: ['2.8rem', '3.2rem', '3.8rem', '2.4rem', '3.8rem', '4rem']
        }}>
        {formattedValue}
      </Paragraph>
      <ActionsVerticalLine sx={{ right: 0 }} />
    </Box>
  );
};

const CheckoutButton = ({ label, handleClick }) => (
  <SecondaryButton
    className="checkout-button"
    label={label}
    onClick={handleClick}
    sx={{
      gridColumn: [
        '1 / span 3',
        '1 / span 3',
        '1 / span 3',
        'auto',
        '1 / span 3',
        '1 / span 3'
      ],
      px: ['2.8rem', '6rem', '9.2rem', '4rem', '6.2rem', '6.7rem']
    }}
  />
);

const ActionsVerticalLine = ({ sx }) => {
  return (
    <VerticalLine
      isBold
      sx={{
        position: 'absolute',
        top: 0,
        height: '100%',
        ...sx
      }}
    />
  );
};

// Box Product Components

const BoxItemsList = ({ title, boxItems }) => (
  <Box
    className="product-detail-overlay__box-items-list"
    sx={{ mt: ['5.6rem', '5.6rem', '8.4rem', '4rem', '7.2rem', '6.4rem'] }}>
    <InformationTitle text={title} />
    <HorizontalLine
      sx={{ my: ['1.2rem', '1.6rem', '2rem', '1.6rem', '2rem', '1.6rem'] }}
    />
    <Box
      sx={{
        display: 'grid',
        gap: ['1.2rem', '1.6rem', '1.6rem', '1.2rem', '1.2rem', '1.6rem'],
        gridTemplateColumns: '1fr'
      }}>
      {boxItems.map((item) => (
        <Box key={item?.name}>
          <Paragraph
            sx={{
              fontFamily: 'body.medium',
              fontSize: [
                '1.5rem',
                '1.6rem',
                '1.6rem',
                '1.5rem',
                '1.5rem',
                '1.6rem'
              ],
              lineHeight: 1.35
            }}>
            {item?.name}
          </Paragraph>
          {item?.description?.text && (
            <Paragraph
              sx={{
                fontFamily: 'body.normal',
                fontSize: [
                  '1.5rem',
                  '1.6rem',
                  '1.6rem',
                  '1.5rem',
                  '1.5rem',
                  '1.6rem'
                ],
                lineHeight: 1.35,
                mt: '0.2rem'
              }}>
              {item?.description?.text}
            </Paragraph>
          )}
        </Box>
      ))}
    </Box>
  </Box>
);

const BoxNumberOfPersons = ({ title, numberOfPersons, sx }) => {
  const label = `Für ${numberOfPersons} ${
    numberOfPersons === 1 ? 'Person' : title
  }`;

  return (
    <Box className="product-detail-overlay__box-number-of-persons" sx={sx}>
      <InformationTitle text={title} />
      <RadioInputWithLabel
        name="persons-select"
        label={label}
        value={numberOfPersons}
        selected={true}
      />
    </Box>
  );
};

// Reusable Components

const CloseButton = ({ onClick, sx }) => {
  return (
    <WideCloseButton
      onClick={onClick}
      sx={{
        width: ['7.6rem', '8.9rem', '10.6rem', '7.6rem', '9rem', '9rem'],
        position: 'absolute',
        top: ['3.2rem', '5.5rem', '5.3rem', '2rem', '3.5rem', '2.4rem'],
        right: [0, 0, 0, null, '7.3rem', '4.6rem'],
        ...sx
      }}
    />
  );
};

const MoreInformation = ({ title, description, sx }) => {
  return (
    <>
      {description && (
        <Box
          className="product-detail-overlay__info-block"
          sx={{
            mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4rem'],

            width: [null, null, '52.8rem', '36.9rem', '100%', '100%'],

            ...sx
          }}>
          <InformationTitle text={title} />
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: description
            }}
            sx={{
              lineHeight: 1.35,
              fontFamily: 'body.medium',
              fontSize: [
                '1.5rem',
                '1.6rem',
                '1.8rem',
                '1.5rem',
                '1.6rem',
                '1.6rem'
              ],
              mt: ['0.4rem', '0.4rem', '0.8rem', '0.4rem', '0.8rem', '0.8rem']
            }}
          />
        </Box>
      )}
    </>
  );
};

const InformationTitle = ({ text }) => (
  <Heading
    as="h3"
    sx={{
      lineHeight: 1.5,
      letterSpacing: '0.05em',
      fontFamily: 'body.bold',
      fontSize: ['1.3rem', '1.4rem', '1.5rem', '1.3rem', '1.4rem', '1.5rem'],
      textTransform: 'uppercase'
    }}>
    {text}
  </Heading>
);

const RadioInputWithLabel = ({
  id,
  htmlId,
  label,
  value,
  name,
  onChange,
  selected
}) => {
  return (
    <Paragraph
      id={htmlId}
      as="label"
      htmlFor={id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '1.6rem',
        fontFamily: 'body.medium',
        lineHeight: 1.35,
        fontSize: ['1.5rem', '1.6rem', '1.6rem', '1.5rem', '1.6rem', '1.6rem'],
        ':first-of-type': {
          mt: ['1.6rem', '1.6rem', '2rem', '1.6rem', '1.6rem', '1.2rem']
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }}>
      <RadioInput
        id={id}
        value={value}
        selected={selected}
        onChange={onChange}
        name={name}
      />
      <Box
        as="span"
        sx={{
          ml: '1.6rem'
        }}>
        {label}
      </Box>
    </Paragraph>
  );
};

const RadioInput = ({ id, value, selected, onChange, name }) => {
  return (
    <Box
      sx={{
        position: 'relative'
      }}>
      <Box
        as="input"
        type="radio"
        id={id}
        value={value}
        selected={selected}
        onChange={onChange}
        name={name}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 0
        }}
      />
      <Box
        sx={{
          borderRadius: '50%',
          aspectRatio: '1/1',
          width: '2rem',
          border: 'clamp(1.5px, 0.15rem, 2px) solid',
          borderColor: 'primary',
          bg: selected ? 'primary' : 'transparent'
        }}
      />
    </Box>
  );
};

const BottomSpacer = () => {
  return (
    <Spacer
      height={['18.4rem', '18rem', '18rem', '11.8rem', null, null]}
      sx={{
        display: ['block', 'block', 'block', 'block', 'none', 'none']
      }}
    />
  );
};

const ContentBottomSpacer = () => {
  return (
    <Spacer
      className="content-bottom-spacer"
      height={[null, null, null, null, '16rem', '16rem']}
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block']
      }}
    />
  );
};

// Gift Baskets Elements

const OrderOptions = ({
  item,
  selectedOrderType,
  setSelectedOrderType,
  selectOrderMessage,
  setSelectOrderMessage
}) => {
  const {
    productDetail: {
      orderTypes: { label, types, message }
    }
  } = useGiftPageData();

  function handleChange(type) {
    setSelectOrderMessage(false);
    setSelectedOrderType(type);
  }

  if (!item || !item.orderTypes) return null;

  return (
    <Box
      id="order-selection-input"
      className="product-detail-overlay__order-options"
      sx={{
        mt: ['4rem', '4rem', '5.4rem', '4rem', '4.6rem', '4.6rem']
      }}>
      <InformationTitle text={label} />
      <Box>
        {item.orderTypes?.map((type) => (
          <RadioInputWithLabel
            htmlId="orderTypeSelector"
            id={type === orderTypes.PICKUP ? types.pickup : types.postal}
            key={type}
            name="order-options-select"
            label={type === orderTypes.PICKUP ? types.pickup : types.postal}
            value={type}
            onChange={() => handleChange(type)}
            selected={selectedOrderType === type}
          />
        ))}
      </Box>
      {selectOrderMessage && (
        <Paragraph
          sx={{
            mt: '1.5rem',
            color: 'text',
            bg: '#F9DADA',
            p: '5px 10px',
            borderRadius: '5px',
            width: 'fit-content',
            fontFamily: 'body.medium',
            fontSize: [
              '1.5rem',
              '1.6rem',
              '1.6rem',
              '1.5rem',
              '1.6rem',
              '1.6rem'
            ],
            lineHeight: 1.35
          }}>
          {message.error}
        </Paragraph>
      )}
    </Box>
  );
};
