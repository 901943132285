export const primaryLineAnimation = {
  initial: {
    x: 0
  },
  animate: {
    x: 'calc(100% + 1rem)'
  }
};

export const growAndShrink = {
  initial: {
    width: '100%'
  },
  animate: {
    width: '75%',
    transition: {
      type: 'tween',
      ease: [0.87, 0, 0.13, 1],
      duration: 0.8,
      repeat: Infinity,
      repeatType: 'reverse'
    }
  }
};
