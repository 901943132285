import React from 'react';

// External components
import { Box } from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';
import { useShoppingCart } from 'gatsby-theme-thepuzzlers-cart';
import { useLocation } from '@gatsbyjs/reach-router';

// Context
import { useNavigation } from 'context/NavigationContext';

// Local Components
import { HorizontalLine, Spacer } from 'components';
import { CartOverlay } from 'sections/cart';

// Self Components
import { NavigationBar } from './NavigationBar';
import { NavigationOverlay } from './NavigationOvelay';

// hooks
import { useNavLinksData } from 'hooks';
import { useFooterData } from 'sections/Footer/useFooterData';
// animation
import {
  revealNavigation,
  quickRevealNavigation,
  navigationShrinkAnimation
} from './animation';

export const Navigation = () => {
  const [isNavOverlayOpen, setIsNavOverlayOpen] = React.useState(false);
  const { isCartOverlayOpen, handleOpenCart } = useNavigation();
  const { pageLinks } = useNavLinksData();
  const { contact } = useFooterData();
  const { key: locationKey } = useLocation();

  const handleOpenOverlay = () => setIsNavOverlayOpen(true);
  const handleCloseOverlay = () => setIsNavOverlayOpen(false);

  React.useEffect(() => {
    const killAnimation = navigationShrinkAnimation();
    return killAnimation;
  }, []);

  return (
    // Markup
    <>
      <Box
        as="nav"
        // Animation values
        initial="initial"
        animate="animate"
        // prevent the navigation overlay animated unless in home page
        variants={
          locationKey === 'initial' ? revealNavigation : quickRevealNavigation
        }
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 2,
          // Animation value
          opacity: 0
        }}>
        <NavigationBar
          pageLinks={pageLinks}
          handleNavButtonClick={handleOpenOverlay}
          handleOpenCart={handleOpenCart}
        />
        <HorizontalLine
          isBold
          className="navigation-line"
          sx={{
            // Animation value
            opacity: 0
          }}
        />
      </Box>
      <NavigationBarSpacer />
      <AnimatePresence>
        {isNavOverlayOpen && (
          <NavigationOverlay
            handleClose={handleCloseOverlay}
            pageLinks={pageLinks}
            contact={contact}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCartOverlayOpen && <CartOverlay handleClose={handleOpenCart} />}
      </AnimatePresence>
    </>
  );
};

const NavigationBarSpacer = () => {
  return (
    <Spacer
      height={['5.5rem', '7.5rem', '7.5rem', '5.1rem', '7.5rem', '8rem']}
    />
  );
};
