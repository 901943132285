import { useQuery, gql } from '@apollo/client';

import { BOX_FIELDS } from './useProductCategories';

const GET_BOX = gql`
  ${BOX_FIELDS}
  query Box($input: BoxInput!) {
    box(input: $input) {
      box {
        ...boxFields

        inventory {
          ... on BoxInventory {
            id
            quantity
            status
            box {
              id
              name
              boxPricing: pricing {
                price
                priceBeforeSale
              }
            }
          }
        }
      }
    }
  }
`;

export function useBox(id, options) {
  return useQuery(GET_BOX, { variables: { input: { id } }, ...options });
}
