import { graphql, useStaticQuery } from 'gatsby';

export const useNavigationData = () => {
  const data = useStaticQuery(graphql`
    query {
      navigationDataJson {
        homeLink
        navButtonText
        cartButtonText
      }
    }
  `);

  return data.navigationDataJson;
};
