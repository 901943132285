import React from 'react';

// External Components
import { Box, Image } from '@thepuzzlers/pieces';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const ImageContainer = ({
  designatedRef,
  className,
  src,
  alt,
  sx,
  imageSx,
  imgProps,
  imgStyle,
  ...props
}) => {
  return (
    <Box
      ref={designatedRef}
      className={`image-container ${className}`}
      sx={{
        borderRadius: 'card',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx
      }}
      {...props}>
      {typeof src === 'string' ? (
        <Image src={src} alt={alt} sx={{ width: '100%' }} />
      ) : (
        <GatsbyImage
          className="gatsby-image"
          {...imgProps}
          image={getImage(src)}
          alt={alt}
          style={{ height: '100%', width: '100%', ...imageSx }}
          imgStyle={imgStyle}
        />
      )}
    </Box>
  );
};
