import { gsap } from 'gsap';
export const scaleImageOnScroll = (scroller) => {
  let wrappers = gsap.utils.toArray('.category-headline-with-illustration');

  wrappers.forEach((wrapper) => {
    let tween = gsap.fromTo(
      wrapper.children[1], // illustration
      { scale: 1.3 },
      {
        scale: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: wrapper,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5,
          scroller: scroller
        }
      }
    );

    return () => {
      tween.scrollTrigger.kill(true);
    };
  });
};
