// Animated Box animation
const transition = {
  duration: 1.5,
  repeat: Infinity
};

export const boxAnimation = {
  initial: {
    rotate: 0,
    y: 0
  },
  animate: {
    rotate: ['0deg', '4deg', '0deg', '0deg'],
    y: ['0%', '-10%', '0%', '0%'],
    transformOrigin: ['100% 80%', '100% 80%', '50% 80%', '50% 80%'],
    transition: {
      ...transition,
      times: [0, 0.4, 0.9, 1]
    }
  }
};

export const jarAnimation = {
  initial: {
    y: 0,
    rotate: 0
  },
  animate: {
    y: ['0%', '-5%', '1%', '0%'],
    rotate: ['0deg', '-3deg', '1deg', '0deg'],
    transformOrigin: ['14% 30%', '14% 30%', '14% 30%', '14% 30%'],
    transition: {
      ...transition,
      times: [0, 0.4, 0.8, 1],
      delay: 0.1
    }
  }
};

export const breadAnimation = {
  intial: {
    y: 0,
    rotate: 0
  },
  animate: {
    y: ['0%', '-7%', '1%', '0%'],
    rotate: ['0deg', '3deg', '-1deg', '0deg'],
    transformOrigin: ['right center', 'right center', 'right center'],
    transition: {
      ...transition,
      times: [0, 0.4, 0.8, 1],
      delay: 0.3
    }
  }
};

export const oatsAnimation = {
  initial: {
    y: 0,
    rotate: 0
  },
  animate: {
    y: ['0%', '-12%', '2%', '0%'],
    rotate: ['0deg', '1deg', '-1deg', '0deg'],
    transformOrigin: ['0% 30%', '0% 30%', '0% 30%'],
    transition: {
      ...transition,
      times: [0, 0.4, 0.8, 1],
      delay: 0.5
    }
  }
};

export const transparentJarAnimation = {
  initial: {
    y: 0,
    rotate: 0
  },
  animate: {
    y: ['0%', '-4%', '1%', '0%'],
    rotate: ['0deg', '1deg', '-1deg', '0deg'],
    transformOrigin: ['right 30%', 'right 30%', 'right 30%', 'right 30%'],
    transition: {
      ...transition,
      times: [0, 0.4, 0.7, 1],
      delay: 0.3
    }
  }
};
