import React from 'react';

// External Components
import { Button, Box } from '@thepuzzlers/pieces';

// Local Components
import { LineWithArrow } from 'components';

export const CardButton = ({ text, sx, ...props }) => {
  const buttonRef = React.useRef(null);
  return (
    <Button
      className="card-button"
      initial="initial"
      whileHover="animate"
      ref={buttonRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        ...sx
      }}
      {...props}>
      <Box
        as="span"
        sx={{
          display: 'block'
        }}>
        {text}
      </Box>
      <LineWithArrow
        sx={{
          flexGrow: 1,
          width: 'auto',
          ml: '1.2rem'
        }}
      />
    </Button>
  );
};
