import React from 'react';

// External Components
import { Button, Box, FlexWrapper } from '@thepuzzlers/pieces';

// Local Components
import { RightArrowIcon } from 'components';

// Animations
import { scaleUpAndDown, growAndShrink } from './animation';

export const PrimaryButton = ({ text, onClick = () => {}, sx, ...props }) => {
  return (
    <Button
      className="primary-button"
      onClick={onClick}
      // Animation values
      initial="initial"
      whileHover="animate"
      sx={{
        ...sx
      }}
      {...props}>
      <Box
        as="span"
        sx={{
          pr: ['2.7rem', '6rem', '8.8rem', '4.2rem', '9.3rem', '9.1rem']
        }}>
        {text}
      </Box>
      <LineWithArrow
        sx={{
          mt: '1.2rem'
        }}
      />
    </Button>
  );
};

export const LineButton = ({ sx, ...props }) => {
  return (
    <Button sx={sx} {...props}>
      <LineWithArrow />
    </Button>
  );
};

// Reusable Components

export const LineWithArrow = ({ sx, ...props }) => {
  return (
    // Additional box is needed for animation purpose
    <Box
      className="line-with-arrow"
      sx={{
        ...sx
      }}>
      <FlexWrapper
        variants={growAndShrink}
        sx={{
          alignItems: 'center',
          position: 'relative'
        }}
        {...props}>
        <Line />
        <RightArrowIcon
          sx={{
            position: 'absolute',
            display: 'block',
            right: '-2px',
            width: '0.81rem',
            zIndex: 1
          }}
        />
      </FlexWrapper>
    </Box>
  );
};

export const Line = ({ children, sx, ...props }) => {
  return (
    <Box
      as="span"
      sx={{
        position: 'relative',
        width: '100%',
        display: 'block',
        overflow: 'visible',
        // use Border instad of height, because border has more stable rendering on browsers
        borderWidth: '2px 0 0 0',
        borderStyle: 'solid',
        borderColor: 'currentColor',
        ...sx
      }}
      {...props}>
      {children}
    </Box>
  );
};
