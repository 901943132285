import { colors } from './colors';
const shadows = {};

const radii = {
  card: '0.4rem'
};

const borders = {
  card: `clamp(2px, 0.2rem, 2.5px) solid`
};

export { shadows, radii, borders };
