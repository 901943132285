import { gsap } from 'gsap';

export const parallaxImage = (target, y) => {
  let tween = gsap.to(target, {
    y: -y,
    ease: 'none',
    scrollTrigger: {
      trigger: target,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 1
    }
  });

  return () => {
    tween.scrollTrigger.kill(true);
  };
};
