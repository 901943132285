import React from 'react';

// External Components

// Local Components
import { ImageContainer, AspectRatioBox } from 'components';

// Animation
import {
  boxAnimation,
  jarAnimation,
  breadAnimation,
  oatsAnimation,
  transparentJarAnimation
} from './animation';

export const AnimatedBox = ({ data }) => {
  const [box, bread, jar, oats, transparentJar] = data;
  return (
    <AspectRatioBox
      aspectRatio={1.2}
      initial="initial"
      animate="animate"
      sx={{
        gridColumn: [
          '1 / span 11',
          '2 / span 10',
          '4 / span 18',
          '15 /25',
          '15 / span 9',
          '15 / span 8'
        ],
        mt: ['5.4rem', '7.2rem', '7.2rem', 0, 0, 0],
        position: 'relative'
      }}>
      <Oats data={oats} />
      <TransparentJar data={transparentJar} />
      <Bread data={bread} />
      <BoxPart data={box} />
      <Jar data={jar} />
    </AspectRatioBox>
  );
};

const BoxPart = ({ data }) => {
  return (
    <BoxPartImage
      data={data}
      sx={{
        aspectRatio: '3/1',
        width: '51%',
        top: '76%',
        left: '24%'
      }}
      // Animation Value
      variants={boxAnimation}
    />
  );
};
const Bread = ({ data }) => {
  return (
    <BoxPartImage
      variants={breadAnimation}
      data={data}
      sx={{
        aspectRatio: '1.06/1',
        width: '31%',
        top: '27%',
        left: '38%'
      }}
    />
  );
};
const Jar = ({ data }) => {
  return (
    <BoxPartImage
      data={data}
      sx={{
        aspectRatio: '1/1.23',
        width: '30%',
        top: '39%',
        left: '23%'
      }}
      variants={jarAnimation}
      // Animation Value
    />
  );
};
const Oats = ({ data }) => {
  return (
    <BoxPartImage
      variants={oatsAnimation}
      data={data}
      sx={{
        aspectRatio: '1/1.1',
        width: '33%',
        top: '2%',
        left: '29%'
      }}
    />
  );
};
const TransparentJar = ({ data }) => {
  return (
    <BoxPartImage
      variants={transparentJarAnimation}
      data={data}
      sx={{
        aspectRatio: '1/1.25',
        width: '26%',
        top: '47%',
        left: '49%'
      }}
    />
  );
};

const BoxPartImage = ({ data: { name, image }, sx, ...props }) => {
  return (
    <ImageContainer
      src={image}
      alt={name}
      sx={{
        position: 'absolute',
        width: '2rem',
        ...sx
      }}
      {...props}
    />
  );
};
