import React from 'react';

// External Components
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { Vector, AspectRatioImageContainer } from 'components';

// Assets
import decoration from 'assets/giftPage/svg/red-waves.svg';

// Animations
import { parallaxImage } from 'sections/animation';

// Helper function
import { convertApiHtmlText } from 'boxenStopHooks';

export const Impressions = ({
  data: { description, headline, image_one, image_three, image_two }
}) => {
  React.useEffect(() => {
    const killOne = parallaxImage('.gift-impressions__image-one', 120);
    const killTwo = parallaxImage('.gift-impressions__image-two', 180);
    const killThree = parallaxImage('.gift-impressions__image-three', 140);

    return () => {
      killOne();
      killTwo();
      killThree();
    };
  }, []);

  return (
    // Markup
    <Section id="gift__impressions">
      <Headline headline={headline} description={description} />
      <ImageOne data={image_one} />
      <ImageTwo data={image_two} />
      <ImageThree data={image_three} />
    </Section>
  );
};

// Elements

const Headline = ({ headline, description }) => (
  <Box
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 20',
        '1/span 16',
        '1/span 12',
        '2/span 10'
      ],
      gridRow: [2, 2, 2, 2, 1, 1],
      mt: ['8rem', '12rem', '12rem', 0, 0, '9rem']
    }}>
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      sx={{
        fontFamily: 'primary.italic',
        fontSize: ['2.4rem', '3.3rem', '3.6rem', '2.8rem', '3.2rem', '3.2rem'],
        lineHeight: 1.2,
        '& > span': {
          fontFamily: 'primary.mediumStyled'
        }
      }}
    />
    <Paragraph
      sx={{
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.6rem', '1.6rem'],
        lineHeight: [1.6, 1.6, 1.6, 1.5, 1.5, 1.5],
        mt: ['2rem', '3.3rem', '3rem', '2rem', '2rem', '2rem'],
        width: ['100%', '100%', '100%', '100%', '100%', '90%']
      }}>
      {description}
    </Paragraph>
  </Box>
);

const ImageOne = ({ data: { image, alt } }) => (
  <Box
    className="gift-impressions__image-one"
    sx={{
      alignSelf: 'start',
      gridColumn: ['1/13', '4/span 11', '8/span 18', '13/25', '15/25', '14/23'],
      gridRow: [1, 1, 1, 1, '1/span 2', '1/span 2'],
      mt: [0, 0, 0, '4.6rem', '8rem', 0],
      position: 'relative',
      width: ['100%', '94%', '100%', '100%', '100%', '100%']
    }}>
    <AspectRatioImageContainer src={image} alt={alt} aspectRatio={1.5} />
    <Vector
      src={decoration}
      sx={{
        position: 'absolute',
        top: ['-5.8rem', '-5.8rem', '-5.8rem', '-4.2rem', '-4.8rem', '-7.8rem'],
        right: [0, 0, '-9.2rem', 'unset', 'unset', 'unset'],
        left: [null, null, null, '-5.8rem', '-4.2rem', '-9rem'],
        width: ['18.4rem', '18.4rem', '18.4rem', '16rem', '16rem', '21.6rem']
      }}
    />
  </Box>
);

const ImageTwo = ({ data: { image, alt } }) => (
  <Box
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '4/13',
        '6/13',
        '12/span 11',
        '1/span 8',
        '1/span 8',
        '5/span 6'
      ],
      gridRow: [3, 3, 3, 3, '2/span 2', '2/span 2'],
      justifySelf: ['end', 'end', 'end', 'end', 'end', 'start'],
      mt: ['8rem', '10rem', '10rem', '16rem', '18rem', '18rem'],
      width: ['100%', '94%', '100%', '100%', '100%', '90%']
    }}>
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      className="gift-impressions__image-two"
      aspectRatio={1}
    />
  </Box>
);

const ImageThree = ({ data: { image, alt } }) => (
  <Box
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/13',
        '1/span 9',
        '3/span 14',
        '15/25',
        '12/span 10',
        '13/span 8'
      ],
      mt: ['3rem', '3rem', '4rem', '4rem', '19.9rem', '25rem'],
      width: ['100%', '94%', '100%', '100%', '100%', '92%']
    }}>
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      className="gift-impressions__image-three"
      aspectRatio={1}
    />
  </Box>
);
