import React from 'react';

// External Components
import { Button, Box } from '@thepuzzlers/pieces';

export const WideCloseButton = ({ sx, ...props }) => {
  return (
    <Button
      variant="clear"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}
      {...props}>
      <Box as="svg" viewBox="0 0 78 22" fill="none" sx={{ width: '100%' }}>
        <rect
          x="0.258789"
          width="80"
          height="1"
          transform="rotate(15 0.258789 0)"
          fill="currentColor"
        />
        <rect
          y="21"
          width="80"
          height="1"
          transform="rotate(-15 0 21)"
          fill="currentColor"
        />
      </Box>
    </Button>
  );
};
