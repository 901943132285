import React from 'react';
import { GridWrapper, Section } from '@thepuzzlers/pieces';
import { VerticalLine } from 'components';

// Local Components

export const SectionWithSideLine = ({ children, sx, ...props }) => {
  const offset = ['-2.1rem', '-2.1rem', 0, 0, 0, 0];
  const lineStyles = {
    display: ['none', 'block', 'block', 'none', 'block', 'block']
  };
  return (
    <Section
      sx={{
        position: 'relative',
        ...sx
      }}
      {...props}>
      <VerticalLine
        sx={{
          position: 'absolute',
          gridColumn: 1,
          top: 0,
          left: offset,
          ...lineStyles
        }}
      />
      <VerticalLine
        sx={{
          gridColumnStart: [12, 12, 24, 24, 24, 24],
          gridColumnEnd: 'span 1',
          position: 'absolute',
          top: 0,
          right: offset,
          ...lineStyles
        }}
      />
      {children}
    </Section>
  );
};
