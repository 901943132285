import React from 'react';

// External Components
import { Line } from 'components';

export const LineIcon = ({ sx }) => (
  <Line
    sx={{
      width: ['2.9rem', '2.9rem', '2.9rem', '2.4rem', '3.2rem', '4.5rem'],
      ...sx
    }}
  />
);
