import React from 'react';

// External components
import {
  Section,
  Heading,
  Paragraph,
  Box,
  FlexWrapper,
  Link
} from '@thepuzzlers/pieces';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  PurLogo,
  PuzzlersLogo,
  BoxenstopLogo,
  GridSpacer,
  SecondaryButton,
  HorizontalLine
} from 'components';

// Data
import { useFooterData } from './useFooterData';
import { useNavLinksData } from 'hooks';
import {
  useKisImportantNoticeData,
  useKisOpeningHoursData
} from 'boxenStopHooks';

// Helper function
import { convertApiHtmlText } from 'boxenStopHooks';

export const Footer = ({ ...props }) => {
  const { headline, phone, pageLinksTitle, contact, logoLinks } =
    useFooterData();
  const { pageLinks, footerOnlyLinks } = useNavLinksData();

  return (
    // Markup
    <Box as="footer" {...props}>
      <HorizontalLine />
      <Section>
        <TopSpacer />
        <Headline headline={headline} />
        <PhoneLink phone={phone} />
        <LinkSpacer />
        <PageLinks
          title={pageLinksTitle}
          pageLinks={[...pageLinks, ...footerOnlyLinks]}
        />
        <ManufactureAndRestaurantInfo />
        <ContactInfo contact={contact} />
        <LogoSpacer />
        <PurLogoLink to={logoLinks.purLink} />
        <BoxenStopAndPuzzlersLogoLink
          boxenstopLink={logoLinks.boxenstopLink}
          puzzlersLink={logoLinks.puzzlersLink}
        />
        <BottomSpacer />
      </Section>
    </Box>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.1,
        fontSize: ['3.6rem', '4.8rem', '4.8rem', '3.6rem', '5rem', '6rem'],
        alignSelf: 'center',
        gridColumn: [
          '1/13',
          '1 / span 10',
          '2 / span 14',
          '1 / span 15',
          '2 / span 15',
          '2 / span 13'
        ],
        mt: ['12.3rem', '8rem', 0, 0, 0, 0]
      }}>
      {headline}
    </Heading>
  );
};

const PhoneLink = ({ phone: { label, to } }) => {
  return (
    <SecondaryButton
      label={label}
      sx={{
        gridColumn: [
          '6/13',
          '7/13',
          '17 / span 7',
          '18/25',
          '18 / span 6',
          '17 / span 5'
        ],
        mt: ['2.8rem', '2.4rem', 0, 0, 0, 0],
        alignSelf: 'center'
      }}
      as="a"
      href={to}
    />
  );
};

const PageLinks = ({ title, pageLinks }) => {
  return (
    <ListWrapper
      title={title}
      sx={{
        gridColumn: [
          '1/13',
          '1 / span 5',
          '2 / span 8',
          '1 / span 6',
          '2 / span 5',
          '2 / span 5'
        ],
        mt: ['4.6rem', 0, 0, 0, 0, 0]
      }}>
      <Box
        sx={{
          mt: '0.8rem'
        }}>
        {pageLinks.map((link, index) => (
          <PageLinkItem key={index} link={link} />
        ))}
      </Box>
    </ListWrapper>
  );
};
const PageLinkItem = ({ link: { label, to } }) => (
  <NavigationLink
    to={to}
    sx={{
      variant: 'links.footerLink'
    }}>
    {label}
  </NavigationLink>
);

const ManufactureAndRestaurantInfo = () => {
  const { footer_note, footer_note_headline } = useKisImportantNoticeData();

  const { bistro, shop, headlinebistro, headlineshop } =
    useKisOpeningHoursData();
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '7/13',
          '12 / span 9',
          '9 / span 8',
          '8 / span 6',
          '9 / span 5'
        ],
        mt: ['4rem', 0, 0, 0, 0, 0]
      }}>
      <InfoListWrapper
        info={{
          title: headlineshop,
          description: convertApiHtmlText(shop.html)
        }}
      />
      <InfoListWrapper
        info={{
          title: headlinebistro,
          description: convertApiHtmlText(bistro.html)
        }}
        sx={{
          mt: ['4rem', '4.8rem', '5.6rem', '4.2rem', '4rem', '2.9rem']
        }}
      />
      {footer_note && (
        <InfoListWrapper
          info={{
            title: footer_note_headline,
            description: convertApiHtmlText(footer_note.html)
          }}
          sx={{
            mt: ['4rem', '4.8rem', '5.6rem', '4.2rem', '4rem', '2.9rem']
          }}
        />
      )}
    </Box>
  );
};

const ContactInfo = ({ contact }) => {
  return (
    <InfoListWrapper
      info={contact}
      sx={{
        mt: ['4rem', '4.8rem', '3.9rem', 0, 0, 0],
        gridColumn: [
          '1/13',
          '1 / span 5',
          '2 / span 8',
          '18/25',
          '16 / span 6',
          '17 / span 5'
        ]
      }}
    />
  );
};

const PurLogoLink = ({ to }) => {
  return (
    <Link
      href={to}
      sx={{
        gridColumn: [
          '1 / span 3',
          '1 / span 3',
          '2 / span 4',
          '1 / span 3',
          '2 / span 3',
          '2 / span 3'
        ],
        width: ['6.6rem', '7.8rem', '7.8rem', '6.1rem', '7rem', '7rem'],
        mt: ['6.4rem', 0, 0, 0, 0, 0],
        alignSelf: 'end'
      }}>
      <PurLogo
        sx={{
          color: 'primary'
        }}
      />
    </Link>
  );
};

const BoxenStopAndPuzzlersLogoLink = ({ boxenstopLink, puzzlersLink }) => {
  return (
    <FlexWrapper
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        alignSelf: 'end',
        gridColumn: [
          '1/13',
          '6/13',
          '12 / span 11',
          '15 / 25',
          '16 / span 8',
          '17 / span 7'
        ],
        mt: ['2.9rem', 0, 0, 0, 0, 0]
      }}>
      <Link
        target="__blank"
        href={boxenstopLink}
        sx={{
          width: [
            '12.2rem',
            '12.7rem',
            '12.7rem',
            '11.3rem',
            '13.6rem',
            '13.6rem'
          ]
        }}>
        <BoxenstopLogo
          sx={{
            color: 'black'
          }}
        />
      </Link>
      <Link
        target="__blank"
        href={puzzlersLink}
        sx={{
          width: [
            '13.3rem',
            '13.9rem',
            '13.9rem',
            '12.3rem',
            '15.3rem',
            '15.3rem'
          ]
        }}>
        <PuzzlersLogo
          sx={{
            color: 'black'
          }}
        />
      </Link>
    </FlexWrapper>
  );
};
// Reusable Components
const InfoListWrapper = ({ info: { title, description }, as = 'p', sx }) => {
  return (
    <ListWrapper title={title} sx={{ ...sx }}>
      <Paragraph
        as={as}
        type="footer/list"
        dangerouslySetInnerHTML={{ __html: description }}
        sx={{
          mt: ['0.8rem']
        }}
      />
    </ListWrapper>
  );
};

const ListWrapper = ({ children, title, sx }) => {
  return (
    <Box
      sx={{
        ...sx
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.bold',
          lineHeight: 1,
          letterSpacing: '0.02em',
          textTransform: 'uppercase',
          fontSize: ['1.2rem', '1.2rem', '1.3rem', '1.2rem', '1.3rem', '1.3rem']
        }}>
        {title}
      </Paragraph>
      {children}
    </Box>
  );
};

// Spacer
const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '11.8rem', '8.1rem', '14rem', '12.7rem']}
      sx={{
        display: ['none', 'none', 'block', 'block', 'block', 'block']
      }}
    />
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer
      height={['6.1rem', '6.5rem', '9.8rem', '4.8rem', '8.5rem', '8.4rem']}
    />
  );
};

const LinkSpacer = () => {
  return (
    <GridSpacer
      height={[null, '7.1rem', '6.8rem', '8rem', '11.3rem', '16rem']}
      sx={{
        display: ['none', 'block', 'block', 'block', 'block', 'block']
      }}
    />
  );
};

const LogoSpacer = () => {
  return (
    <GridSpacer
      height={[null, '6.2rem', '9.4rem', '7.2rem', '12.7rem', '8.6rem']}
      sx={{
        display: ['none', 'block', 'block', 'block', 'block', 'block']
      }}
    />
  );
};
