import React from 'react';
import { Box } from '@thepuzzlers/pieces';

export const HorizontalLine = ({ sx, children, isBold = false, ...props }) => {
  return (
    <Box
      as="hr"
      className="horizontal-line"
      sx={{
        borderTop: ({ colors }) =>
          `${isBold ? '2px' : '1px'} solid ${colors.primary}`,
        borderStyle: 'inset',
        ...sx
      }}
      {...props}>
      {children}
    </Box>
  );
};
