import React from 'react';

import { Box } from '@thepuzzlers/pieces';

export const SmallCloseIcon = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 26 26"
      fill="none"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <rect
        width="35.8753"
        height="1.00876"
        transform="matrix(0.722647 0.691217 -0.0748509 0.997195 0.0756836 0)"
        fill="currentColor"
      />
      <rect
        width="35.8753"
        height="1.00876"
        transform="matrix(0.722647 -0.691217 0.0748509 0.997195 0 24.9941)"
        fill="currentColor"
      />
    </Box>
  );
};
