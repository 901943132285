import { useStaticQuery, graphql } from 'gatsby';

export const useGiftPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      giftPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        productDetail {
          orderTypes {
            label
            types {
              pickup
              postal
            }
            message {
              error
            }
          }
        }
      }
    }
  `);

  return data.giftPageDataJson;
};
