// transition animation variants

const transition = {
  duration: 0.4,
  ease: [0.57, 0, 0.21, 1]
};

export const mainContentAnimation = {
  initial: {
    height: 0,
    transition
  },
  animate: {
    height: '100%',
    transition: {
      ...transition,
      when: 'beforeChildren'
    }
  },
  exit: {
    top: 0,
    height: 0,
    transition: {
      ...transition,
      when: 'afterChildren'
    }
  }
};

export const blueCoverAnimation = {
  initial: {
    height: '100%'
  },
  animate: {
    height: 0,
    transition: {
      ...transition,
      delay: -0.2
    },
    transitionEnd: {
      bottom: 0,
      top: 'auto'
    }
  },
  exit: {
    height: '100%',
    transition: {
      ...transition
    }
  }
};

export const coverContainerAnimation = {
  intial: {
    top: 0,
    height: '100vh'
  },
  animate: {
    top: 0,
    height: 0,
    transition: {
      ...transition,
      delay: 0.2
    },
    transitionEnd: {
      top: 'auto',
      bottom: 0
    }
  },
  exit: {
    height: '100%',
    transition: {
      ...transition,
      delayChildren: 0.3
    }
  }
};

export const actionBarAnimation = {
  initial: {
    y: '100%'
  },
  animate: {
    y: 0,
    transition: {
      ...transition,
      delay: 0.5,
      type: 'tween'
    }
  },
  exit: {
    y: '100%',
    transition
  }
};
