import { getFluidTypographyStyles } from '@thepuzzlers/pieces';
import { merge } from 'theme-ui';
// client imports
import { fontFamilies } from './fonts/fontFamilies';
import { typographyConfig, checkoutTypography } from './typographyConfig';

const fluidTypography = getFluidTypographyStyles({
  typographyConfig,
  fontFamilies
});

const fluidCheckoutTypography = getFluidTypographyStyles({
  typographyConfig: checkoutTypography,
  fontFamilies: {
    body: {
      normal: 'cera-round-pro-normal-regular, sans-serif',
      bold: 'cera-round-pro-normal-bold, sans-serif'
    },
    primary: {
      normal: 'quincy-cf-normal-bold, serif'
    }
  }
});

// Overwrite styles that are specific to bold or normal variants here:
const typography = merge(fluidTypography, {
  // Global Paragraph
  'global/label': {
    normal: {
      fontSize: ['1.4rem', '1.6rem', '1.5rem', '1.3rem', '1.3rem', '1.5rem'],
      fontFamily: 'body.medium',
      lineHeight: 1
    }
  },
  'global/p-500': {
    normal: {
      fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.7rem', '1.8rem'],
      fontFamily: 'body.normal',
      lineHeight: 1.6
    }
  },
  'paragraph/primary': {
    normal: {
      fontFamily: 'body.normal',
      lineHeight: 1.5,
      fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.6rem', '1.7rem', '1.8rem']
    }
  },
  'paragraph/secondary': {
    normal: {
      fontFamily: 'body.normal',
      lineHeight: 1.5,
      fontSize: ['1.5rem', '1.5rem', '1.7rem', '1.5rem', '1.6rem', '1.6rem']
    }
  },
  'footer/list': {
    normal: {
      fontFamily: 'body.normal',
      lineHeight: 2.4,
      fontSize: ['1.4rem', '1.5rem', '1.5rem', '1.4rem', '1.5rem', '1.5rem']
    }
  },
  dropdown: {
    normal: {
      fontFamily: 'body.medium',
      lineHeight: 1.25,
      fontSize: ['1.5rem', '1.7rem', '1.7rem', '1.5rem', '1.7rem', '1.7rem']
    }
  }
});

typography.checkout = fluidCheckoutTypography;

export { typography };
