import React from 'react';

// ExternalComponents
import { AnimatePresence } from 'framer-motion';

// Local Components
import { Toast } from './Toast';

const ToastContext = React.createContext({
  handleOpenToast: ({ text, textButton, onButtonClick, closeDuration }) => {},
  handleCloseToast: () => {},
  toastProps: {
    text: '',
    textButton: '',
    onButtonClick: '',
    closeDuration: undefined,
    isOpen: false,
    hasBeenOpenedOnce: false
  }
});

export const useToastContext = () => React.useContext(ToastContext);

export const ToastContextProvider = ({ children }) => {
  const initialToastPropValue = {
    text: '',
    textButton: '',
    onButtonClick: '',
    closeDuration: undefined,
    isOpen: false,
    hasBeenOpenedOnce: false
  };

  const [toastProps, setToastProps] = React.useState(initialToastPropValue);

  const handleOpenToast = ({
    text,
    textButton,
    onButtonClick,
    closeDuration
  }) =>
    setToastProps((state) => ({
      ...state,
      text,
      textButton,
      onButtonClick,
      closeDuration,
      isOpen: true,
      hasBeenOpenedOnce: true // it give sign to toast if it already called al least one time
    }));

  const handleCloseToast = () =>
    setToastProps((state) => ({
      ...state,
      ...initialToastPropValue
    }));

  return (
    <ToastContext.Provider
      value={{
        handleOpenToast,
        handleCloseToast,
        toastProps
      }}>
      {children}
      <AnimatePresence>
        {toastProps.isOpen && (
          <Toast {...toastProps} handleCloseToast={handleCloseToast} />
        )}
      </AnimatePresence>
    </ToastContext.Provider>
  );
};
