// To overwrite the fontFamily styls of thhe checkout
// the following structure of fonts is required"

// export const fontFamilies = {
//     body: {
//       normal: 'sans-serif',
//       bold:  'sans-serif',
//     },
//     primary: {
//       normal: 'serif'
//     }
//   };

// The simplest way to overwrite the fontFamilies used in the checkout:
// Just export our local fontFamilies object
import { fontFamilies as websiteFontFamilies } from '../gatsby-plugin-theme-ui/fonts/fontFamilies';

export const fontFamilies = {
  body: websiteFontFamilies.body,
  primary: {
    normal: websiteFontFamilies.primary.bold
  }
};
