// TODO:   Move all extractor method to this Method

export const itemExtractor = (item) => {
  const getName = () => item?.name;
  const getDescription = () => {
    return item?.description?.html;
  };

  const getTagNamesAsArray = () => {
    return item?.tags?.length > 0
      ? item?.tags.reduce((acc, cur) => {
          return [...acc, cur.name];
        }, [])
      : [];
  };
  const getVariants = () => {
    return item?.variants?.variants;
  };

  const getSizes = () => {
    return item?.sizes?.sizes;
  };
  return {
    getName,
    getDescription,
    getVariants,
    getSizes,
    getTagNamesAsArray
  };
};
