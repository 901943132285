import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { YellowCircle, GridSpacer } from 'components';

// Self Components
import { AnimatedBox } from './components';

// Animation
import { revealText } from 'sections/animation';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Header = ({
  data: { headline, description },
  staticData: { type, boxParts }
}) => {
  return (
    <Section
      id={`shop-page__header-${type}`}
      sx={{
        position: 'relative'
      }}>
      <TopSpacer />
      <AnimatedBox data={boxParts} />
      <Headline
        headline={convertApiHtmlText(headline.html)}
        description={convertApiHtmlText(description.html)}
      />
      <Decoration />
    </Section>
  );
};

const Headline = ({ headline, description }) => {
  return (
    <Box
      // Animation values
      initial="initial"
      animate="animate"
      sx={{
        gridColumn: [
          '1 / span 13',
          '1 / span 10',
          '2 / span 16',
          '1 / span 11',
          '2 / span 11',
          '3 / span 10'
        ],
        gridRow: [null, null, null, 2, 2, 2],
        alignSelf: 'center',
        mt: ['3.8rem', '7.2rem', '7.4rem', 0, 0, 0]
      }}>
      <Heading
        as="h1"
        dangerouslySetInnerHTML={{ __html: headline }}
        // Animation values
        variants={revealText}
        sx={{
          fontSize: [
            '3.8rem',
            '4.8rem',
            '5.6rem',
            '3.6rem',
            '4.2rem',
            '4.8rem'
          ],
          lineHeight: 1.1,
          fontFamily: 'primary.bold',
          '& > span': {
            fontFamily: 'primary.italic'
          }
        }}
      />
      <Paragraph
        // Animation values
        custom={0.2}
        variants={revealText}
        sx={{
          lineHeight: 1.5,
          fontSize: [
            '1.6rem',
            '1.7rem',
            '1.8rem',
            '1.6rem',
            '1.8rem',
            '1.8rem'
          ],
          width: [null, null, null, null, null, '47rem'],
          mt: ['1.2rem', '1.2rem', '2.4rem', '1.6rem', '2.4rem', '2.4rem']
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const Decoration = () => {
  return (
    <YellowCircle
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
        width: ['4.4rem', '5.6rem', '7.8rem', '4.4rem', '4.5rem', '5.5rem'],
        position: 'absolute',
        top: ['2.3rem', '7.2rem', '7.2rem', '5.4rem', '11rem', '7.2rem'],
        right: ['2.7rem', '4.3rem', '9rem', '0rem', '4.2rem', '10.7rem'],
        color: 'yellow'
      }}
    />
  );
};
// Spacer
const TopSpacer = () => (
  <GridSpacer
    height={[null, null, null, '5.4rem', '11rem', '7.1rem']}
    sx={{
      display: ['none', 'none', 'none', 'block', 'block', 'block']
    }}
  />
);
