// transition animation variants

const transition = {
  duration: 0.4,
  ease: [0.57, 0, 0.21, 1]
};

// =================================
//  Toast Animation Variant

export const toastContainer = {
  initial: {
    x: '-100%'
  },
  animate: {
    x: 0,
    transition: {
      ...transition,
      duration: 0.3,
      delay: 1
    }
  },
  exit: {
    x: '-100%',
    transition
  }
};
