import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const DownArrow = ({ sx, ...props }) => {
  return (
    <Box as="svg" viewBox="0 0 15 51" fill="none" sx={sx} {...props}>
      <rect
        x="7"
        y="49"
        width="49"
        height="1.5"
        transform="rotate(-90 7 49)"
        fill="currentColor"
      />
      <rect
        x="7.67871"
        y="50.1328"
        width="10"
        height="1.5"
        transform="rotate(-135 7.67871 50.1328)"
        fill="currentColor"
      />
      <rect
        x="6.61816"
        y="49.0703"
        width="10"
        height="1.5"
        transform="rotate(-45 6.61816 49.0703)"
        fill="currentColor"
      />
    </Box>
  );
};
