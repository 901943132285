import React from 'react';

// External Components
import { Box, Heading, Paragraph } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ImageContainer, TextAndArrow } from 'components';

// Animation
import { cardImageOnHover } from './animation';

export const BlogCard = ({
  headline,
  briefDescription,
  coverImage = { src: '', alt: '' },
  readLink,
  slug,
  sx,
  ...props
}) => {
  return (
    <NavigationLink
      to={`/blog-post/${slug}`}
      sx={{
        // putting div inside a tag is not valid on html4 but we can set the inline element tag to be displayed as block to make it validated
        // https://stackoverflow.com/questions/1827965/is-putting-a-div-inside-an-anchor-ever-correct
        // this for the span
        display: 'block',
        // this for the a
        '& > a': {
          display: 'block'
        },

        ':not(:first-of-type)': {
          mt: ['12rem', '12rem', '12rem', '7.2rem', '8.8rem', '9.6rem']
        },
        ...sx
      }}
      {...props}>
      <Box
        className="blog-card"
        sx={{
          alignItems: 'center',
          display: 'grid',
          gap: ['3.2rem', '4rem', '3.2rem', '3.7rem', '5rem', '7rem'],
          gridTemplateColumns: [
            '1fr',
            '1fr',
            '1fr',
            '49.5% 1fr',
            '49.5% 1fr',
            '49.2% 1fr'
          ]
        }}
        // Animation Value
        initial="initial"
        whileHover="animate">
        <CardImage {...coverImage} />
        <BlogCardText
          headline={headline}
          briefDescription={briefDescription}
          readLink={readLink}
          bigHeadline={true}
          sx={{ width: ['100%', '100%', '87%', '100%', '100%', '100%'] }}
        />
      </Box>
    </NavigationLink>
  );
};

const CardImage = ({ src, alt, image }) => (
  // We need more box because the image and it's container has different aspect ratio
  <Box
    sx={{
      overflow: 'hidden',
      bg: 'blue',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ['31.9rem', '43rem', '46.8rem', ' 31.1rem', '41.2rem', '47rem'],
      height: ['24.5rem', '33rem', '36rem', '23.9rem', '31.6rem', '36rem']
    }}>
    <ImageContainer
      src={src || image}
      alt={alt}
      sx={{ aspectRatio: '1/1', width: '60%' }}
      // Animtion Values
      variants={cardImageOnHover}
      transition={{
        duration: 0.5
      }}
    />
  </Box>
);

export const BlogCardText = ({
  headline,
  briefDescription,
  readLink,
  sx,
  bigHeadline = false,
  titleWithSpans,
  ...props
}) => (
  <Box className="blog-card__text-block" sx={sx} {...props}>
    <Box>
      <Heading
        as="h3"
        dangerouslySetInnerHTML={{
          __html: headline
        }}
        sx={{
          fontFamily: !titleWithSpans ? 'primary.bold' : 'primary.italic',
          fontSize: bigHeadline
            ? ['2.6rem', '3.2rem', '2.8rem', '2.2rem', '2.8rem', '3.2rem']
            : ['2.4rem', '2.6rem', '2.4rem', '2rem', '2.2rem', '2.6rem'],
          lineHeight: 1.25,
          '& > span': {
            fontFamily: 'primary.bold'
          }
        }}
      />
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: briefDescription
        }}
        sx={{
          fontSize: [
            '1.5rem',
            '1.7rem',
            '1.6rem',
            '1.4rem',
            '1.6rem',
            '1.6rem'
          ],
          fontFeatureSettings: "'pnum' on, 'onum' on",
          lineHeight: 1.5,
          mt: ['0.8rem', '1.6rem', '1.6rem', '1.2rem', '1.2rem', '1.6rem']
        }}
      />
    </Box>
    <TextAndArrow
      text={readLink.label}
      sx={{
        mt: ['5.6rem', '6.4rem', '5.6rem', '4rem', '5.6rem', '4.8rem'],
        width: ['100%', '100%', '85%', '100%', '100%', '100%']
      }}
    />
  </Box>
);
