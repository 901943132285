import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PurLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 66 34"
      fill="none"
      overflow="visible"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28936 0V0.0200671C14.3408 0.0760854 18.4225 4.26343 18.4789 9.44666C18.5361 14.7118 14.4227 19.0271 9.29152 19.0858L2.27481 19.1059L2.25629 32.8162C2.25701 32.84 2.25701 32.8638 2.25629 32.8876C2.23704 33.5214 1.72073 34.0192 1.10312 33.9994C0.485407 33.9797 0.000361204 33.4499 0.0196082 32.8162L0 0.912601C0 0.419608 0.389482 0.0200671 0.869935 0.0200671L9.28936 0ZM2.2726 16.774L9.28936 16.8064C13.194 16.8064 16.3594 13.5585 16.3594 9.55182C16.3594 5.54523 13.194 2.29728 9.28936 2.29728L2.2726 2.32969V16.774Z"
        fill="currentColor"
      />
      <path
        d="M40.4228 0.0726682C41.0364 0.0527691 41.5493 0.552907 41.5685 1.18979L41.5782 17.0861C41.5782 22.3772 37.4458 26.6667 32.3483 26.6667C27.2507 26.6667 23.1183 22.3772 23.1183 17.0861L23.0988 1.18979H23.115C23.1143 1.16574 23.1143 1.14169 23.115 1.11769C23.1342 0.480813 23.6472 -0.0193253 24.2607 0.00057379C24.8742 0.0204729 25.3561 0.552907 25.3369 1.18979H25.3574V12.0485L25.3261 17.085C25.3261 21.1099 28.4695 24.3727 32.3471 24.3727C36.2248 24.3727 39.3682 21.1099 39.3682 17.085L39.3466 12.0485V1.18979C39.365 0.58084 39.8362 0.0917692 40.4228 0.0726682Z"
        fill="currentColor"
      />
      <path
        d="M65.3957 24.5003L57.5944 19.088C62.8205 18.4224 66.5193 13.6315 65.856 8.38705C65.2511 3.60515 61.1979 0.0204684 56.3945 0.0201494V0L47.7507 0.0201494C47.2575 0.0201494 46.8577 0.421383 46.8577 0.916346V25.4581H46.8766C46.8964 26.0944 47.4266 26.5942 48.0607 26.5743C48.6669 26.5553 49.1541 26.0665 49.173 25.4581H49.1919V2.33925L56.3956 2.30671C60.4032 2.30671 63.6517 5.56703 63.6517 9.58878C63.6517 13.6106 60.4032 16.8709 56.3956 16.8709H54.098C53.4671 16.8123 52.9082 17.2784 52.8499 17.9117C52.8097 18.3485 53.02 18.7702 53.3929 18.9995L64.1746 26.4474C64.6879 26.8209 65.4063 26.7056 65.7787 26.1901C66.1506 25.6744 66.0362 24.9537 65.5223 24.5802C65.482 24.5509 65.4397 24.5242 65.3957 24.5003Z"
        fill="currentColor"
      />
      <path d="M66 32.002H5.28178V29.3354H66V32.002Z" fill="currentColor" />
    </Box>
  );
};
