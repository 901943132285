import React from 'react';
import { Box } from '@thepuzzlers/pieces';

export const RightArrowIcon = ({ sx, ...props }) => {
  return (
    <Box as="svg" viewBox="0 0 9 15" fill="none" sx={sx} {...props}>
      <rect
        x="8.13086"
        y="7.07129"
        width="10"
        height="1.5"
        transform="rotate(135 8.13086 7.07129)"
        fill="currentColor"
      />
      <rect
        x="7.07031"
        y="8.13184"
        width="10"
        height="1.5"
        transform="rotate(-135 7.07031 8.13184)"
        fill="currentColor"
      />
    </Box>
  );
};
