import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

// Local Components
import { ImageContainer } from './ImageContainer';

export const CoverImage = ({
  src,
  alt,
  coverColor,
  sx,
  className,
  ...props
}) => {
  React.useEffect(() => {
    let wrapper = document.querySelector(`.${className}`);
    let cover = wrapper.children[1];

    let tween = gsap.to(cover, {
      scaleY: 0,
      transformOrigin: 'bottom center',
      duration: 1.4,
      ease: 'expo.inOut',
      scrollTrigger: {
        trigger: cover,
        start: 'top 70%'
      }
    });

    return () => {
      tween.scrollTrigger.kill(true);
    };
  }, []);

  return (
    <Box className={className} sx={{ position: 'relative', ...sx }} {...props}>
      <ImageContainer
        src={src}
        alt={alt}
        sx={{ height: '100%', width: '100%' }}
      />
      <CoverBox coverColor={coverColor} />
    </Box>
  );
};

export const CoverBox = ({ coverColor = 'background', ...props }) => (
  <Box
    className="image-cover-box"
    sx={{
      bg: coverColor,
      position: 'absolute',
      top: '-0.2rem',
      left: '-0.2rem',
      height: '101%',
      width: '101%',
      zIndex: 2
    }}
    {...props}
  />
);
