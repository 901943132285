const linkDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out',
  fontFamily: 'body.bold',
  lineHeight: 1.35,
  ':hover': {}
};

const buttonDefault = {
  cursor: 'pointer',
  bg: 'transparent',
  color: 'primary',
  transition: '0.2s ease-out',
  fontFamily: 'body.medium',
  textTransform: 'uppercase'
};

const links = {
  hyperLink: {
    ...linkDefault,
    textTransform: 'uppercase',
    fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '2rem'],
    display: 'flex',
    alignItems: 'end'
  },
  navigation: {
    ...linkDefault
  },
  footerLink: {
    ...linkDefault,
    display: 'block',
    variant: 'typography.footer/list.normal'
  },
  noStyle: {
    ':hover': {}
  }
};

const buttons = {
  primary: {
    ...buttonDefault,
    textAlign: 'left',
    lineHeight: 1,
    fontSize: ['1.4rem', '1.5rem', '1.6rem', '1.4rem', '1.4rem', '1.6rem'],
    letterSpacing: '0.05em',
    whiteSpace: 'nowrap',
    p: 0
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropdown: {
    ...buttonDefault,
    variant: 'typography.dropdown.normal',
    border: 'card',
    borderColor: 'primary',
    p: [
      '1rem 2.2rem 1rem 2.2rem',
      '1.4rem 2.2rem 1.4rem 2.2rem',
      '1.4rem 2.2rem 1.4rem 2.2rem',
      '1rem 2.2rem 1rem 2.2rem',
      '1.4rem 2.2rem 1.4rem 2.2rem',
      '1.4rem 2.2rem 1.4rem 2.2rem'
    ]
  },
  quantityChanger: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent',
    width: ['2.4rem', '2.4rem', '3rem', '2.4rem', '3rem', '2.4rem'],
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center'
  },

  // Override Checkout Button
  checkout: {
    primary: {
      ...buttonDefault,
      color: 'background',
      bg: 'primary',
      fontSize: ['1.4rem', '1.5rem', '1.6rem', '1.4rem', '1.4rem', '1.6rem'],
      fontWeight: 600,
      letterSpacing: '0.05em',
      p: [
        '2rem 2.4rem',
        '2rem 2.8rem',
        '2.4rem 3.2rem',
        '1.6rem 2rem',
        '2.4rem 3.2rem',
        '2rem 3.2rem'
      ],
      whiteSpace: 'nowrap'
    }
  }
};

const cards = {
  checkout: {
    default: {
      borderRadius: 0,
      boxShadow: 'none',
      bg: 'transparent',

      '.card-heading': {
        marginTop: '10px',
        marginBottom: '0px'
      }
    },
    cart: { border: ({ colors }) => `1px solid ${colors.primary}` },
    details: {
      marginTop: [20, 24, 20, 20, 24, 24],
      '.details-field': {
        p: {
          fontFamily: 'body.normal'
        }
      }
    },
    dateAndTime: {
      border: '1px solid',
      borderColor: 'primary',
      '&.selected': { bg: 'yellow' },
      '&.disabled:hover': {
        border: '1px solid',
        borderColor: 'primary'
      }
    }
  }
};

export { links, buttons, cards };
