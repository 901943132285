import { CardButton } from 'components';

export const SecondaryButton = ({ label, sx, ...props }) => {
  return (
    <CardButton
      text={label}
      sx={{
        color: 'background',
        bg: 'primary',
        borderRadius: 0,
        px: ['2.4rem', '2.8rem', '3.2rem', '2rem', '3.2rem', '3.2rem'],
        py: ['2rem', '2rem', '2.4rem', '1.6rem', '2.4rem', '2.4rem'],
        ...sx
      }}
      {...props}
    />
  );
};
