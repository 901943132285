import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisRestaurantPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisRestaurantPage {
        nodes {
          entry {
            description {
              description {
                html
              }
            }
            header {
              description {
                html
              }
              headline {
                html
              }
            }
            menu {
              description {
                html
              }
              headline {
                html
              }
              menu_button_label
              menu_button_to
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
