import React from 'react';

// External Components
import { Section, Box } from '@thepuzzlers/pieces';

// Local Components
import { HorizontalLine, PulsingSkeletonBox, GridSpacer } from 'components';

export const ProductCategorySkeleton = ({ cardBackground }) => {
  return (
    <Section>
      <HeadlineWithIllustration cardBackground={cardBackground} />
      <PartnerWithProducts />
      <GridSpacer height="10rem" />
    </Section>
  );
};

const HeadlineWithIllustration = ({ cardBackground }) => {
  return (
    <PulsingSkeletonBox
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: [
          '1/13',
          '1/13',
          '4 / span 18',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        py: ['3.2rem', '4rem', '4rem', '1.6rem', '2.4', '0.6rem'],
        px: ['2.8rem', '8.8rem', '6rem', '2.9rem', '4.2', '5.4rem'],
        flexDirection: ['column', 'column', 'column', 'row', 'row', 'row'],
        bg: cardBackground
      }}>
      <PulsingSkeletonBox
        sx={{
          height: ['10rem', '10rem', '10rem', '5rem', '5rem', '5rem'],
          width: ['100%', '100%', '100%', '40rem', '40rem', '40rem'],
          bg: 'background',
          borderRadius: '3px'
        }}
      />
      <PulsingSkeletonBox
        sx={{
          width: [
            '20.9rem',
            '25.4rem',
            '31.8rem',
            '19.5rem',
            '24.4rem',
            '25.4rem'
          ],
          aspectRatio: '1/1',
          mt: ['2.7rem', '2.7rem', '2.4rem', 0, 0],
          bg: cardBackground,
          borderRadius: '3px'
        }}
      />
    </PulsingSkeletonBox>
  );
};

const PartnerWithProducts = () => {
  const fakeItems = [0, 1, 2, 3, 4, 5, 6];
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '2/ span 10',
          '4 / span 18',
          '1/25',
          '2 / span 22',
          '2 / span 22'
        ],
        mt: ['3.2rem', '4rem', '4rem', '3.2rem', '4rem', '4rem'],
        '& ~ .partner-with-products': {
          mt: ['9.8rem', '12.2rem', '17.2rem', '9.8rem', '11.6rem', '11.7rem']
        }
      }}>
      <HorizontalLine isBold />
      {fakeItems.map((item) => (
        <PulsingSkeletonBox
          key={item}
          index={item}
          sx={{
            height: ['12rem', '20rem', '20rem', '4rem', '4rem', '4rem'],
            mt: '4rem',
            bg: 'lightGray'
          }}
        />
      ))}
    </Box>
  );
};
