import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PuzzlersLogo = ({ sx }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 133 27"
      fill="none"
      sx={{ width: '100%', ...sx }}>
      <path
        d="M33.6114 12.1503V14.2447C33.6114 14.2945 33.6114 14.2945 33.5603 14.2945C32.9982 14.4441 32.7427 14.494 32.0273 14.494C29.5745 14.494 28.0415 13.3471 28.0415 10.804V6.76494C28.0415 6.71507 27.9904 6.71509 27.9904 6.71509H26.5596C26.5085 6.71509 26.5085 6.66517 26.5085 6.66517V4.52101C26.5085 4.47115 26.5596 4.47116 26.5596 4.47116H27.9904C28.0415 4.47116 28.0415 4.42125 28.0415 4.42125V2.52642C28.0415 2.47655 28.0926 2.47656 28.0926 2.47656H30.6987C30.7498 2.47656 30.7498 2.52642 30.7498 2.52642V4.42125C30.7498 4.47111 30.8009 4.47116 30.8009 4.47116H33.5092C33.5603 4.47116 33.5603 4.52101 33.5603 4.52101V6.66517C33.5603 6.71504 33.5092 6.71509 33.5092 6.71509H30.8009C30.7498 6.71509 30.7498 6.76494 30.7498 6.76494V10.5547C30.7498 11.7016 31.363 12.1005 32.5894 12.1005C32.896 12.1005 32.9982 12.1005 33.5092 12.0506C33.5603 12.1005 33.6114 12.1503 33.6114 12.1503Z"
        fill="currentColor"
      />
      <path
        d="M44.3956 8.21046V14.1943C44.3956 14.2441 44.3445 14.294 44.2934 14.294H41.7384C41.6873 14.294 41.6362 14.2441 41.6362 14.1943V8.55949C41.6362 7.4126 40.8697 6.6646 39.7966 6.6646C38.6724 6.6646 37.9059 7.4126 37.9059 8.55949V14.1943C37.9059 14.2441 37.8548 14.294 37.8037 14.294H35.2487C35.1976 14.294 35.1465 14.2441 35.1465 14.1943V0.630956C35.1465 0.581091 35.1976 0.53125 35.2487 0.53125H37.8037C37.8548 0.53125 37.9059 0.581091 37.9059 0.630956V5.16865C37.9059 5.21852 38.0081 5.26843 38.0592 5.21857C38.7235 4.62019 39.6433 4.27112 40.6653 4.27112C42.9648 4.27112 44.3956 5.86681 44.3956 8.21046Z"
        fill="currentColor"
      />
      <path
        d="M70.6601 9.40754C70.6601 12.3496 68.6161 14.5437 65.8056 14.5437C64.6814 14.5437 63.7105 14.1946 62.9951 13.5464C62.944 13.4965 62.7907 13.5463 62.7907 13.5962V17.1865C62.7907 17.2364 62.7396 17.2862 62.6885 17.2862H60.1846C60.1335 17.2862 60.0824 17.2364 60.0824 17.1865V4.62051C60.0824 4.57065 60.1335 4.52081 60.1846 4.52081H62.4841C62.5352 4.52081 62.5863 4.57065 62.5863 4.62051V5.66767C62.5863 5.71753 62.6374 5.71753 62.6374 5.66767C63.3528 4.7701 64.477 4.27148 65.8056 4.27148C68.6161 4.32135 70.6601 6.46551 70.6601 9.40754ZM67.9007 9.40754C67.9007 7.76199 66.7254 6.71488 65.2946 6.71488C64.0171 6.71488 62.7396 7.51267 62.7396 9.40754C62.7396 11.2525 64.0171 12.1003 65.2946 12.1003C66.7254 12.1003 67.9007 11.1029 67.9007 9.40754Z"
        fill="currentColor"
      />
      <path
        d="M71.9376 10.0047V4.61924C71.9376 4.56937 71.9887 4.51953 72.0398 4.51953H74.5948C74.6459 4.51953 74.697 4.56937 74.697 4.61924V10.254C74.697 11.4009 75.5146 12.1488 76.5877 12.1488C77.6097 12.1488 78.4273 11.4009 78.4273 10.254V4.61924C78.4273 4.56937 78.4784 4.51953 78.5295 4.51953H81.0845C81.1356 4.51953 81.1867 4.56937 81.1867 4.61924V10.0047C81.1867 12.7472 79.1938 14.5424 76.5877 14.5424C73.9305 14.5424 71.9376 12.7472 71.9376 10.0047Z"
        fill="currentColor"
      />
      <path
        d="M90.8947 12.0502H86.3979C86.3468 12.0502 86.2957 12.0003 86.3468 11.9504L89.9749 6.76448L91.048 5.26853C91.048 5.21867 91.048 5.21863 91.048 5.16876V4.62026C91.048 4.52053 90.9969 4.4707 90.8947 4.4707H82.872C82.7698 4.4707 82.7187 4.52053 82.7187 4.62026V6.61486C82.7187 6.66472 82.7187 6.66476 82.7698 6.71463C82.8209 6.76449 82.8209 6.76448 82.872 6.76448H86.9089C86.96 6.76448 87.0111 6.81432 86.96 6.86418L82.2588 13.5461C82.2588 13.5461 82.2588 13.5959 82.2077 13.6458V14.1944C82.2077 14.2941 82.2588 14.3439 82.361 14.3439H90.3837H90.8947C90.9969 14.3439 91.048 14.2941 91.048 14.1944V12.2496C91.048 12.1 90.9969 12.0502 90.8947 12.0502Z"
        fill="currentColor"
      />
      <path
        d="M100.703 12.0491H96.2065C96.1554 12.0491 96.1043 11.9993 96.1554 11.9494L99.7324 6.81331L100.805 5.31736C100.805 5.26749 100.805 5.26752 100.805 5.21765V4.66909C100.805 4.56936 100.754 4.51953 100.652 4.51953H92.6295C92.5273 4.51953 92.4762 4.56936 92.4762 4.66909V6.66369C92.4762 6.71355 92.4762 6.71359 92.5273 6.76345C92.5784 6.81332 92.5784 6.81331 92.6295 6.81331H96.6664C96.7175 6.81331 96.7686 6.86315 96.7175 6.91301L92.0163 13.5949C92.0163 13.5949 92.0163 13.6448 91.9652 13.6947V14.2432C91.9652 14.3429 92.0163 14.3928 92.1185 14.3928H100.141H100.652C100.754 14.3928 100.805 14.3429 100.805 14.2432V12.2985C100.857 12.099 100.805 12.0491 100.703 12.0491Z"
        fill="currentColor"
      />
      <path
        d="M102.701 0.53125H105.154C105.256 0.53125 105.307 0.581078 105.307 0.680808V14.1943C105.307 14.294 105.256 14.3438 105.154 14.3438H102.701C102.599 14.3438 102.548 14.294 102.548 14.1943V0.680808C102.548 0.581078 102.599 0.53125 102.701 0.53125Z"
        fill="currentColor"
      />
      <path
        d="M116.29 10.3041H109.596C109.494 10.3041 109.443 10.4039 109.443 10.5036C109.8 11.6505 110.822 12.2489 112.202 12.2489C113.224 12.2489 114.093 11.8998 114.859 11.3513C114.91 11.3014 115.013 11.3014 115.064 11.4011L116.035 12.9968C116.086 13.0467 116.035 13.1464 115.983 13.1963C114.859 14.0939 113.582 14.5925 112.1 14.5925C108.83 14.5925 106.479 12.2489 106.632 9.2071C106.734 6.46453 109.034 4.32031 111.895 4.32031C114.706 4.32031 116.597 6.3149 116.597 8.85801C116.597 9.35666 116.494 9.85535 116.443 10.2044C116.443 10.2543 116.392 10.3041 116.29 10.3041ZM109.596 8.45913H113.786C113.888 8.45913 113.939 8.40924 113.939 8.30951C113.837 7.16262 112.866 6.51438 111.793 6.51438C110.72 6.51438 109.8 7.21248 109.443 8.30951C109.443 8.35937 109.494 8.45913 109.596 8.45913Z"
        fill="currentColor"
      />
      <path
        d="M55.4293 10.3041H48.7352C48.633 10.3041 48.5819 10.4039 48.5819 10.5036C48.9396 11.6505 49.9616 12.2489 51.3413 12.2489C52.3633 12.2489 53.232 11.8998 53.9985 11.3513C54.0496 11.3014 54.1518 11.3014 54.2029 11.4011L55.1738 12.9968C55.2249 13.0467 55.2249 13.1464 55.1227 13.1963C53.9985 14.0939 52.721 14.5925 51.2391 14.5925C47.9687 14.5925 45.6181 12.2489 45.7714 9.2071C45.8736 6.46453 48.1731 4.32031 51.0347 4.32031C53.8452 4.32031 55.7358 6.3149 55.7358 8.85801C55.7358 9.35666 55.6337 9.85535 55.5826 10.2044C55.5315 10.2543 55.4804 10.3041 55.4293 10.3041ZM48.6841 8.45913H52.8743C52.9765 8.45913 53.0276 8.40924 53.0276 8.30951C52.9254 7.16262 51.9545 6.51438 50.8814 6.51438C49.8083 6.51438 48.8885 7.21248 48.5308 8.30951C48.5308 8.35937 48.5819 8.45913 48.6841 8.45913Z"
        fill="currentColor"
      />
      <path
        d="M124.978 12.5491L126.204 11.1528C126.255 11.1029 126.357 11.1029 126.408 11.1528C127.175 11.9008 128.095 12.3496 129.117 12.3496C129.781 12.3496 130.394 12.1003 130.394 11.5518C130.394 11.103 129.985 10.9035 129.372 10.6542L128.299 10.2054C126.613 9.55718 125.591 8.75934 125.591 7.21353C125.591 5.31866 127.175 4.27148 129.27 4.27148C130.701 4.27148 131.876 4.72024 132.796 5.61781C132.847 5.66768 132.847 5.76742 132.796 5.81729L131.569 7.26338C131.518 7.31325 131.416 7.31325 131.365 7.26338C130.752 6.71487 129.985 6.41564 129.219 6.41564C128.555 6.41564 128.146 6.61511 128.146 7.11376C128.146 7.51268 128.452 7.66231 129.219 7.9615L130.19 8.36039C131.978 9.05849 133 9.80647 133 11.3523C133 13.3469 131.263 14.4938 129.117 14.4938C127.379 14.4938 125.949 13.7957 125.029 12.6987C124.927 12.6987 124.926 12.5989 124.978 12.5491Z"
        fill="currentColor"
      />
      <path
        d="M124.265 4.47086C124.06 4.421 123.805 4.37109 123.498 4.37109C122.425 4.37109 121.454 4.81988 120.79 5.61772H120.739V4.72018C120.739 4.62045 120.636 4.57057 120.585 4.57057H118.439C118.337 4.57057 118.286 4.67032 118.286 4.72018V14.1446C118.286 14.2443 118.388 14.2942 118.439 14.2942H120.841C120.943 14.2942 120.994 14.1945 120.994 14.1446V9.45735C120.994 7.71208 122.118 6.91419 123.702 6.91419H124.162H124.265C124.367 6.91419 124.418 6.86436 124.418 6.76463V6.66487V4.72018C124.418 4.57059 124.367 4.52073 124.265 4.47086Z"
        fill="currentColor"
      />
      <path
        d="M16.3768 15.1124C15.2018 15.2624 13.3117 15.4124 12.29 15.4124C11.8302 15.4124 11.5748 15.0624 11.7791 14.7624C13.0051 13.0122 14.7931 10.662 16.1213 9.21185C16.4789 8.86181 17.092 9.01178 17.143 9.41182C17.2452 10.812 17.0409 13.2622 16.8876 14.6623C16.8365 14.9123 16.6322 15.0624 16.3768 15.1124Z"
        fill="currentColor"
      />
      <path
        d="M14.2141 0.381033L4.04369 0.181641C3.58602 0.181641 3.23005 0.580437 3.28091 1.02904C3.38261 1.67703 3.58603 2.32499 3.89114 2.87328C6.33204 2.92313 9.38317 2.97297 10.6545 3.12251C11.0613 3.17235 11.3155 3.4216 11.3155 3.77052C11.3155 3.92005 11.2647 4.66769 11.2138 5.66459C13.1462 4.96676 14.6209 3.27208 14.9769 1.22843C15.0278 0.829674 14.6718 0.430878 14.2141 0.381033Z"
        fill="currentColor"
      />
      <path
        d="M3.8213 2.82157C2.38921 2.82157 1.1617 2.77148 0.701386 2.77148C0.394511 2.77148 0.0364792 2.97187 0.0364792 3.22234C-0.116959 6.07767 0.241066 13.1408 0.599088 14.7939C0.650234 15.1446 0.803678 15.3951 1.21285 15.4452C4.9465 15.6455 6.12286 15.5453 9.70308 15.4952C10.0611 15.4952 10.3168 15.2949 10.4191 15.0444C10.726 13.8922 11.084 8.53221 11.2375 5.62679C10.5726 5.87725 9.80537 6.02757 9.03818 5.97748C6.68546 5.97748 4.74192 4.67502 3.8213 2.82157Z"
        fill="currentColor"
      />
      <path
        d="M27.5272 20.7113H29.2385C29.7931 20.7113 30.2869 20.8121 30.72 21.0139C31.1531 21.2157 31.4885 21.4986 31.7262 21.8628C31.9692 22.227 32.0906 22.6502 32.0906 23.1325C32.0906 23.6049 31.9692 24.0281 31.7262 24.4021C31.4885 24.7712 31.1531 25.0615 30.72 25.2731C30.2869 25.4798 29.7931 25.5832 29.2385 25.5832H27.5272V20.7113ZM29.2306 24.6826C29.5792 24.6826 29.8855 24.6186 30.1496 24.4907C30.419 24.3627 30.625 24.1831 30.7676 23.9518C30.9155 23.7156 30.9894 23.4425 30.9894 23.1325C30.9894 22.66 30.8283 22.2885 30.5061 22.0178C30.1892 21.7472 29.764 21.6118 29.2306 21.6118H28.5968V24.6826H29.2306Z"
        fill="currentColor"
      />
      <path
        d="M36.602 23.5458C36.602 23.723 36.5783 23.9051 36.5307 24.0921H33.7816C33.8449 24.3234 33.9717 24.503 34.1619 24.6309C34.3573 24.754 34.5976 24.8155 34.8828 24.8155C35.2684 24.8155 35.6328 24.6925 35.9761 24.4464L36.4198 25.1255C35.955 25.4897 35.4321 25.6718 34.8511 25.6718C34.4444 25.6718 34.0773 25.5881 33.7499 25.4208C33.4224 25.2535 33.1662 25.0246 32.9814 24.7343C32.7965 24.439 32.7041 24.1142 32.7041 23.7599C32.7041 23.4056 32.7939 23.0832 32.9735 22.7929C33.153 22.4976 33.3986 22.2663 33.7103 22.099C34.0272 21.9317 34.3758 21.848 34.756 21.848C35.1152 21.848 35.4348 21.9243 35.7147 22.0769C35.9946 22.2245 36.2112 22.4287 36.3643 22.6896C36.5228 22.9455 36.602 23.2309 36.602 23.5458ZM34.7164 22.6526C34.4893 22.6526 34.2913 22.7191 34.1222 22.852C33.9532 22.9848 33.8397 23.1644 33.7816 23.3908H35.5642C35.5589 23.1644 35.4744 22.9848 35.3106 22.852C35.1469 22.7191 34.9488 22.6526 34.7164 22.6526Z"
        fill="currentColor"
      />
      <path
        d="M38.7042 25.6718C38.3609 25.6718 38.0492 25.6102 37.7693 25.4872C37.4894 25.3593 37.2596 25.1846 37.08 24.9631L37.6188 24.3578C37.9621 24.6875 38.3292 24.8524 38.72 24.8524C38.8626 24.8524 38.9815 24.8278 39.0765 24.7786C39.1716 24.7244 39.2191 24.6482 39.2191 24.5497C39.2191 24.471 39.1875 24.412 39.1241 24.3726C39.066 24.3283 38.963 24.2766 38.8151 24.2176L38.3952 24.0552C38.036 23.9174 37.7667 23.7648 37.5871 23.5975C37.4128 23.4302 37.3256 23.2112 37.3256 22.9405C37.3256 22.5961 37.4577 22.3279 37.7218 22.1359C37.9911 21.944 38.3397 21.848 38.7676 21.848C39.3538 21.848 39.8239 22.0301 40.1778 22.3943L39.639 23.007C39.375 22.7757 39.0818 22.66 38.7596 22.66C38.6276 22.66 38.5246 22.6822 38.4506 22.7265C38.3767 22.7708 38.3397 22.8347 38.3397 22.9184C38.3397 22.9873 38.3688 23.0439 38.4269 23.0882C38.4903 23.1325 38.5985 23.1841 38.7517 23.2432L39.132 23.3908C39.5123 23.5335 39.7896 23.6885 39.9639 23.8559C40.1434 24.0183 40.2332 24.2348 40.2332 24.5055C40.2332 24.8696 40.088 25.155 39.7975 25.3617C39.5123 25.5684 39.1478 25.6718 38.7042 25.6718Z"
        fill="currentColor"
      />
      <path
        d="M41.042 21.9292H42.1115V25.5832H41.042V21.9292ZM41.5728 21.2132C41.3826 21.2132 41.2215 21.1542 41.0895 21.0361C40.9575 20.9179 40.8914 20.7728 40.8914 20.6005C40.8914 20.4234 40.9575 20.2757 41.0895 20.1576C41.2215 20.0395 41.3826 19.9805 41.5728 19.9805C41.7629 19.9805 41.924 20.0395 42.0561 20.1576C42.1934 20.2757 42.262 20.4234 42.262 20.6005C42.262 20.7728 42.1934 20.9179 42.0561 21.0361C41.924 21.1542 41.7629 21.2132 41.5728 21.2132Z"
        fill="currentColor"
      />
      <path
        d="M47.1072 21.9292V25.1772C47.1072 25.7726 46.9223 26.2254 46.5526 26.5354C46.1829 26.8455 45.6917 27.0005 45.079 27.0005C44.3395 27.0005 43.6978 26.7741 43.1538 26.3214L43.7163 25.6275C43.9328 25.78 44.1388 25.8957 44.3342 25.9744C44.5349 26.0581 44.7541 26.0999 44.9918 26.0999C45.3087 26.0999 45.5622 26.0261 45.7524 25.8784C45.9425 25.7357 46.0376 25.5217 46.0376 25.2362V25.0369C45.895 25.1796 45.7207 25.2879 45.5147 25.3617C45.314 25.4355 45.0895 25.4725 44.8413 25.4725C44.4821 25.4725 44.1599 25.3962 43.8747 25.2436C43.5895 25.0911 43.365 24.877 43.2013 24.6014C43.0429 24.3258 42.9636 24.0109 42.9636 23.6566C42.9636 23.2973 43.0455 22.9824 43.2092 22.7117C43.373 22.4361 43.5974 22.2245 43.8827 22.0769C44.1679 21.9243 44.4874 21.848 44.8413 21.848C45.1212 21.848 45.3695 21.8948 45.586 21.9883C45.8078 22.0818 45.9874 22.2147 46.1248 22.3869V21.9292H47.1072ZM45.0631 24.6309C45.3325 24.6309 45.5649 24.5448 45.7603 24.3726C45.9557 24.2003 46.0534 23.9567 46.0534 23.6418C46.0534 23.3268 45.9557 23.0906 45.7603 22.9331C45.5702 22.7757 45.3378 22.6969 45.0631 22.6969C44.7726 22.6969 44.5297 22.7831 44.3342 22.9553C44.1441 23.1226 44.049 23.3539 44.049 23.6492C44.049 23.9444 44.1467 24.1831 44.3422 24.3652C44.5376 24.5424 44.7779 24.6309 45.0631 24.6309Z"
        fill="currentColor"
      />
      <path
        d="M50.2989 21.848C50.7531 21.848 51.1122 21.9834 51.3763 22.254C51.6404 22.5198 51.7725 22.8741 51.7725 23.317V25.5832H50.7108V23.4499C50.7108 23.2383 50.6422 23.0685 50.5048 22.9405C50.3728 22.8077 50.1985 22.7412 49.982 22.7412C49.7654 22.7412 49.5858 22.8077 49.4432 22.9405C49.3059 23.0685 49.2372 23.2383 49.2372 23.4499V25.5832H48.1677V21.9292H49.1501V22.2614C49.2874 22.1335 49.4538 22.0326 49.6492 21.9588C49.8499 21.8849 50.0665 21.848 50.2989 21.848Z"
        fill="currentColor"
      />
      <path
        d="M59.38 23.6935C59.0526 24.1314 58.8202 24.4292 58.6828 24.5867L59.5622 25.5832H58.3105L57.9936 25.2215C57.571 25.5217 57.0983 25.6718 56.5754 25.6718C56.2374 25.6718 55.939 25.6152 55.6802 25.502C55.4214 25.3888 55.218 25.2289 55.0701 25.0222C54.9275 24.8155 54.8562 24.5793 54.8562 24.3135C54.8562 24.0527 54.9328 23.8165 55.086 23.6049C55.2444 23.3884 55.5191 23.1374 55.9099 22.852C55.7515 22.66 55.6353 22.4804 55.5613 22.3131C55.4874 22.1408 55.4504 21.9661 55.4504 21.789C55.4504 21.4396 55.5745 21.1591 55.8228 20.9475C56.071 20.7309 56.3985 20.6227 56.8052 20.6227C57.1907 20.6227 57.505 20.7236 57.748 20.9253C57.9909 21.1222 58.1124 21.3781 58.1124 21.693C58.1124 21.9243 58.0437 22.1408 57.9064 22.3426C57.7744 22.5395 57.5578 22.7535 57.2568 22.9848L58.0253 23.8485C58.189 23.632 58.3395 23.4302 58.4769 23.2432C58.5719 23.1251 58.667 22.9996 58.7621 22.8667L59.586 23.413L59.38 23.6935ZM56.7893 21.4716C56.6731 21.4716 56.5807 21.5011 56.512 21.5602C56.4434 21.6192 56.409 21.7004 56.409 21.8037C56.409 21.8874 56.4302 21.9711 56.4724 22.0547C56.52 22.1335 56.6018 22.2393 56.718 22.3721L56.7339 22.3648C56.887 22.2516 56.9953 22.1532 57.0587 22.0695C57.1221 21.9809 57.1538 21.8874 57.1538 21.789C57.1538 21.6906 57.1221 21.6143 57.0587 21.5602C56.9953 21.5011 56.9055 21.4716 56.7893 21.4716ZM56.6388 24.7933C56.8923 24.7933 57.1379 24.7023 57.3756 24.5202L56.4724 23.5015C56.2559 23.6689 56.1106 23.8017 56.0367 23.9002C55.9627 23.9937 55.9258 24.0995 55.9258 24.2176C55.9258 24.3898 55.9918 24.5301 56.1238 24.6383C56.2559 24.7417 56.4275 24.7933 56.6388 24.7933Z"
        fill="currentColor"
      />
      <path
        d="M62.6529 20.7113H64.3642C64.9187 20.7113 65.4126 20.8121 65.8457 21.0139C66.2788 21.2157 66.6142 21.4986 66.8519 21.8628C67.0948 22.227 67.2163 22.6502 67.2163 23.1325C67.2163 23.6049 67.0948 24.0281 66.8519 24.4021C66.6142 24.7712 66.2788 25.0615 65.8457 25.2731C65.4126 25.4798 64.9187 25.5832 64.3642 25.5832H62.6529V20.7113ZM64.3562 24.6826C64.7048 24.6826 65.0112 24.6186 65.2753 24.4907C65.5446 24.3627 65.7506 24.1831 65.8932 23.9518C66.0411 23.7156 66.1151 23.4425 66.1151 23.1325C66.1151 22.66 65.954 22.2885 65.6318 22.0178C65.3149 21.7472 64.8897 21.6118 64.3562 21.6118H63.7224V24.6826H64.3562Z"
        fill="currentColor"
      />
      <path
        d="M71.7277 23.5458C71.7277 23.723 71.7039 23.9051 71.6564 24.0921H68.9072C68.9706 24.3234 69.0974 24.503 69.2875 24.6309C69.4829 24.754 69.7232 24.8155 70.0085 24.8155C70.394 24.8155 70.7585 24.6925 71.1018 24.4464L71.5455 25.1255C71.0807 25.4897 70.5578 25.6718 69.9768 25.6718C69.5701 25.6718 69.203 25.5881 68.8755 25.4208C68.5481 25.2535 68.2919 25.0246 68.107 24.7343C67.9222 24.439 67.8297 24.1142 67.8297 23.7599C67.8297 23.4056 67.9195 23.0832 68.0991 22.7929C68.2787 22.4976 68.5243 22.2663 68.8359 22.099C69.1528 21.9317 69.5014 21.848 69.8817 21.848C70.2409 21.848 70.5604 21.9243 70.8403 22.0769C71.1203 22.2245 71.3368 22.4287 71.49 22.6896C71.6484 22.9455 71.7277 23.2309 71.7277 23.5458ZM69.8421 22.6526C69.615 22.6526 69.4169 22.7191 69.2479 22.852C69.0789 22.9848 68.9653 23.1644 68.9072 23.3908H70.6898C70.6845 23.1644 70.6 22.9848 70.4363 22.852C70.2725 22.7191 70.0745 22.6526 69.8421 22.6526Z"
        fill="currentColor"
      />
      <path
        d="M76.2103 21.9292L74.2693 25.6718H73.9128L71.9717 21.9292H73.0967L74.0871 23.9961L75.0774 21.9292H76.2103Z"
        fill="currentColor"
      />
      <path
        d="M80.3543 23.5458C80.3543 23.723 80.3306 23.9051 80.283 24.0921H77.5339C77.5973 24.3234 77.724 24.503 77.9142 24.6309C78.1096 24.754 78.3499 24.8155 78.6351 24.8155C79.0207 24.8155 79.3851 24.6925 79.7285 24.4464L80.1721 25.1255C79.7073 25.4897 79.1844 25.6718 78.6034 25.6718C78.1968 25.6718 77.8297 25.5881 77.5022 25.4208C77.1747 25.2535 76.9186 25.0246 76.7337 24.7343C76.5489 24.439 76.4564 24.1142 76.4564 23.7599C76.4564 23.4056 76.5462 23.0832 76.7258 22.7929C76.9054 22.4976 77.151 22.2663 77.4626 22.099C77.7795 21.9317 78.1281 21.848 78.5084 21.848C78.8675 21.848 79.1871 21.9243 79.467 22.0769C79.7469 22.2245 79.9635 22.4287 80.1167 22.6896C80.2751 22.9455 80.3543 23.2309 80.3543 23.5458ZM78.4688 22.6526C78.2416 22.6526 78.0436 22.7191 77.8746 22.852C77.7056 22.9848 77.592 23.1644 77.5339 23.3908H79.3165C79.3112 23.1644 79.2267 22.9848 79.063 22.852C78.8992 22.7191 78.7012 22.6526 78.4688 22.6526Z"
        fill="currentColor"
      />
      <path
        d="M81.1889 20.0469H82.2584V25.5832H81.1889V20.0469Z"
        fill="currentColor"
      />
      <path
        d="M85.1863 25.6644C84.8007 25.6644 84.4468 25.5807 84.1246 25.4134C83.8077 25.2412 83.5569 25.0099 83.372 24.7195C83.1871 24.4243 83.0947 24.1019 83.0947 23.7525C83.0947 23.4031 83.1871 23.0832 83.372 22.7929C83.5569 22.4976 83.8104 22.2663 84.1326 22.099C84.4547 21.9317 84.8086 21.848 85.1942 21.848C85.5798 21.848 85.9336 21.9342 86.2558 22.1064C86.578 22.2737 86.8315 22.5026 87.0164 22.7929C87.2065 23.0832 87.3016 23.4031 87.3016 23.7525C87.3016 24.1019 87.2065 24.4243 87.0164 24.7195C86.8315 25.0099 86.5754 25.2412 86.2479 25.4134C85.9257 25.5807 85.5718 25.6644 85.1863 25.6644ZM85.1942 24.749C85.4847 24.749 85.725 24.6555 85.9152 24.4685C86.1106 24.2815 86.2083 24.0453 86.2083 23.7599C86.2083 23.4745 86.1106 23.2358 85.9152 23.0439C85.725 22.852 85.4847 22.756 85.1942 22.756C84.8984 22.756 84.6555 22.852 84.4653 23.0439C84.2752 23.2309 84.1801 23.4696 84.1801 23.7599C84.1801 24.0453 84.2752 24.2815 84.4653 24.4685C84.6555 24.6555 84.8984 24.749 85.1942 24.749Z"
        fill="currentColor"
      />
      <path
        d="M90.3787 21.848C90.7432 21.848 91.068 21.9317 91.3532 22.099C91.6437 22.2614 91.8708 22.4878 92.0346 22.7781C92.1983 23.0636 92.2802 23.3884 92.2802 23.7525C92.2802 24.1167 92.1983 24.4439 92.0346 24.7343C91.8708 25.0246 91.6437 25.2535 91.3532 25.4208C91.068 25.5881 90.7432 25.6718 90.3787 25.6718C90.1411 25.6718 89.9219 25.6349 89.7212 25.561C89.5205 25.4823 89.3488 25.3716 89.2062 25.2289V26.9119H88.1366V21.9292H89.119V22.3869C89.2511 22.2147 89.4254 22.0818 89.6419 21.9883C89.8638 21.8948 90.1094 21.848 90.3787 21.848ZM90.1807 24.7638C90.4659 24.7638 90.7062 24.6728 90.9016 24.4907C91.0971 24.3037 91.1948 24.0601 91.1948 23.7599C91.1948 23.4548 91.0971 23.2112 90.9016 23.0291C90.7115 22.8421 90.4712 22.7486 90.1807 22.7486C89.9113 22.7486 89.6789 22.8347 89.4835 23.007C89.2881 23.1743 89.1903 23.4228 89.1903 23.7525C89.1903 24.0773 89.2854 24.3283 89.4756 24.5055C89.671 24.6777 89.906 24.7638 90.1807 24.7638Z"
        fill="currentColor"
      />
      <path
        d="M97.6827 21.848C98.1422 21.848 98.504 21.9834 98.768 22.254C99.0321 22.5198 99.1642 22.8741 99.1642 23.317V25.5832H98.0946V23.4573C98.0946 23.2407 98.0312 23.0685 97.9045 22.9405C97.7777 22.8126 97.6087 22.7486 97.3974 22.7486C97.1862 22.7486 97.0119 22.8126 96.8745 22.9405C96.7425 23.0685 96.6765 23.2333 96.6765 23.4351V25.5832H95.6069V23.4573C95.6069 23.2407 95.5435 23.0685 95.4168 22.9405C95.29 22.8126 95.121 22.7486 94.9097 22.7486C94.6932 22.7486 94.5189 22.8126 94.3868 22.9405C94.2548 23.0685 94.1888 23.2407 94.1888 23.4573V25.5832H93.1192V21.9292H94.1016V22.254C94.2337 22.1261 94.3948 22.0277 94.5849 21.9588C94.7751 21.8849 94.9837 21.848 95.2108 21.848C95.7284 21.848 96.1166 22.0227 96.3754 22.3721C96.5286 22.2097 96.7161 22.0818 96.9379 21.9883C97.165 21.8948 97.4133 21.848 97.6827 21.848Z"
        fill="currentColor"
      />
      <path
        d="M103.859 23.5458C103.859 23.723 103.835 23.9051 103.788 24.0921H101.039C101.102 24.3234 101.229 24.503 101.419 24.6309C101.614 24.754 101.855 24.8155 102.14 24.8155C102.525 24.8155 102.89 24.6925 103.233 24.4464L103.677 25.1255C103.212 25.4897 102.689 25.6718 102.108 25.6718C101.702 25.6718 101.334 25.5881 101.007 25.4208C100.68 25.2535 100.423 25.0246 100.238 24.7343C100.054 24.439 99.9612 24.1142 99.9612 23.7599C99.9612 23.4056 100.051 23.0832 100.231 22.7929C100.41 22.4976 100.656 22.2663 100.967 22.099C101.284 21.9317 101.633 21.848 102.013 21.848C102.372 21.848 102.692 21.9243 102.972 22.0769C103.252 22.2245 103.468 22.4287 103.621 22.6896C103.78 22.9455 103.859 23.2309 103.859 23.5458ZM101.974 22.6526C101.746 22.6526 101.548 22.7191 101.379 22.852C101.21 22.9848 101.097 23.1644 101.039 23.3908H102.821C102.816 23.1644 102.731 22.9848 102.568 22.852C102.404 22.7191 102.206 22.6526 101.974 22.6526Z"
        fill="currentColor"
      />
      <path
        d="M106.825 21.848C107.279 21.848 107.638 21.9834 107.902 22.254C108.166 22.5198 108.298 22.8741 108.298 23.317V25.5832H107.237V23.4499C107.237 23.2383 107.168 23.0685 107.031 22.9405C106.899 22.8077 106.724 22.7412 106.508 22.7412C106.291 22.7412 106.112 22.8077 105.969 22.9405C105.832 23.0685 105.763 23.2383 105.763 23.4499V25.5832H104.694V21.9292H105.676V22.2614C105.813 22.1335 105.98 22.0326 106.175 21.9588C106.376 21.8849 106.592 21.848 106.825 21.848Z"
        fill="currentColor"
      />
      <path
        d="M111.34 24.7786C111.404 24.7786 111.573 24.7687 111.847 24.749V25.5832C111.594 25.6422 111.348 25.6718 111.111 25.6718C110.614 25.6718 110.229 25.5586 109.954 25.3322C109.679 25.1009 109.542 24.7564 109.542 24.2988V22.7708H108.924V21.9292H109.542V21.0065H110.612V21.9292H111.721V22.7708H110.612V24.2102C110.612 24.4169 110.67 24.5645 110.786 24.6531C110.902 24.7367 111.087 24.7786 111.34 24.7786Z"
        fill="currentColor"
      />
    </Box>
  );
};
