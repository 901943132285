import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { LineWithArrow } from 'components';

// TODO: use this element in the card that has naviation button
export const LinkCardButton = ({ text, to, sx, innerWrapperSx, ...props }) => {
  const buttonRef = React.useRef(null);

  return (
    <NavigationLink to={to} sx={{ display: 'block', ...sx }}>
      <TextAndArrow
        text={text}
        initial="initial"
        whileHover="animate"
        ref={buttonRef}
        sx={{ ...innerWrapperSx }}
        {...props}
      />
    </NavigationLink>
  );
};

export const TextAndArrow = ({ text, sx, ...props }) => (
  <Box
    className="text-and-arrow"
    sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      ...sx
    }}
    {...props}>
    <Paragraph variant="buttons.primary">{text}</Paragraph>
    <LineWithArrow
      sx={{
        flexGrow: 1,
        width: 'auto',
        ml: '1.2rem'
      }}
    />
  </Box>
);
