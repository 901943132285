import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisProductViewPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisProductViewPage {
        nodes {
          entry {
            product_card {
              box_title
              detail_text
              partner_prefix
              variant_title
            }
            product_detail {
              allergen
              checkout
              box_items_title
              ingredient
              notification {
                description
                text_button
              }
              persons
              price
              size
              variant
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
